import { Box, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import PointsDetailTable from '../../../components/points-detail-table/points.detail.table';
import { AnswerPointList } from '../../../types/faq.types';
import { calculatePage, calculateRowPerPage } from '../../../utils/common.function';
import { answerPointList } from '../action';

type FaqPointListProps = {
  answerId: number;
};

function FaqPointList({ answerId }: FaqPointListProps) {
  const [faqPointList, setFaqPointList] = useState({
    TotalRecords: 0,
    Page: 1,
    Transactions: [],
    ItemOffset: 5
  } as AnswerPointList);
  const { Page, ItemOffset, Transactions, TotalRecords } = faqPointList;
  const [pageCount, setPageCount] = useState(Page);

  useEffect(() => {
    const getAnswerPointList = async () => {
      const res = await answerPointList(Page, ItemOffset, String(answerId));
      const answerList = res.data?.Data;
      setFaqPointList((preValue) => {
        return {
          ...preValue,
          ...answerList
        };
      });
    };

    if (answerId) {
      getAnswerPointList();
    }
  }, [answerId, Page, ItemOffset]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    setFaqPointList({
      ...faqPointList,
      Page: selectedPage + 1
    });
  };

  const handlePrevious = () => {
    setFaqPointList({
      ...faqPointList,
      Page: 1
    });
  };
  const handleNext = () => {
    setFaqPointList({
      ...faqPointList,
      Page: pageCount
    });
  };

  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    if (itemOffset) {
      setFaqPointList({
        ...faqPointList,
        Page: 1,
        ItemOffset: Number(itemOffset)
      });
    }
  };
  return (
    <Box>
      <PointsDetailTable
        title="Points List"
        data={Transactions}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
        page={calculatePage(Page, ItemOffset)}
        totalRecord={TotalRecords}
        rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        activePage={Page}
        handleChangeShowSort={handleChangeShowSort}
        itemOffset={ItemOffset}
      />
    </Box>
  );
}

export default FaqPointList;
