import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from '../root-reducer/root.reducer';
import { useDispatch } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';

let middleware;
if (process.env.NODE_ENV === 'development') middleware = composeWithDevTools(applyMiddleware(thunk, logger));
else middleware = applyMiddleware(thunk);

const enhancer = compose(middleware);
/**
 * create new store
 */
const store = createStore(rootReducer, enhancer);
export default store;

let appStore: ReturnType<typeof createStore>;

export type AppStore = ReturnType<typeof createStore>;

export type AppState = ReturnType<typeof appStore.getState>;

export type AppDispatch = typeof appStore.dispatch;
export type AppDispatchFun<T> = ({ ...arg0 }: T) => void;
export type AppDispatchFunction = AppDispatchFun<unknown>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
