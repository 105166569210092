import { alpha, Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CONSTANTS from '../../utils/constants';

type TitleNameProps = {
  title: string;
  name: string | number | null;
  img: string | null;
  rating: number;
  view: number;
};

function RatingProfile({ title, name, img, rating, view }: TitleNameProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Typography variant="h1" sx={{ fontSize: (theme) => theme.spacing(1.4) }}>
        {title}
      </Typography>
      <Box
        sx={{
          fontSize: (theme) => theme.spacing(1.6),
          letterSpacing: '.5px',
          color: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
          padding: (theme) => theme.spacing(0.4, 1.5),
          marginTop: (theme) => theme.spacing(0.5),
          borderRadius: (theme) => theme.spacing(0.5),
          display: 'flex',
          alignItems: 'center'
        }}>
        <Avatar
          sx={{
            bgcolor: (theme) => theme.palette.secondary.light,
            width: (theme) => theme.spacing(3.5),
            height: (theme) => theme.spacing(3.5),
            border: (theme) => `1px solid ${theme.palette.primary.main}`
          }}>
          {img ? (
            <img src={CONSTANTS.IMAGE_BASE_URL + img} alt="" width="35" height="35" />
          ) : (
            <PersonIcon color="primary" sx={{ width: 30, height: 30 }} />
          )}
        </Avatar>
        <Box sx={{ ml: 0.5 }}>
          <Typography
            sx={{
              fontSize: (theme) => theme.spacing(1.7),
              letterSpacing: '.5px',
              color: (theme) => theme.palette.primary.main,
              lineHeight: 1,
              wordBreak: 'break-all'
            }}>
            {name ?? 'N/A'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {rating ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <StarIcon fontSize="small" sx={{ color: 'var(--yellow)', width: 18, height: 18 }} />
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.common.black,
                      fontSize: (theme) => theme.spacing(1.2),
                      fontFamily: 'Roboto Sans, sans-serif',
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      ml: 0.5,
                      lineHeight: 1
                    }}>
                    {`${rating}  ||`}
                  </Typography>
                </Box>
              ) : null}
              {view ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.common.black,
                      fontSize: (theme) => theme.spacing(1.2),
                      fontFamily: 'Roboto Sans, sans-serif',
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                      mx: 0.5,
                      lineHeight: 1
                    }}>
                    {view ?? 'N/A'}
                  </Typography>
                  <VisibilityIcon fontSize="small" sx={{ color: 'var(--yellow)', width: 18, height: 18 }} />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RatingProfile;
