import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import TextFieldWrapper from '../../components/form-ui/textfield-wrapper/text.field.wrapper';
import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import { PointFormReuseProps, RuleEnum } from '../../types/mange.point';
import showAlert from '../../utils/alert';
import CONSTANTS from '../../utils/constants';
import FORM_FIELD from '../../validations/form-field';
import SCHEMA from '../../validations/schema';
import { getRule, updateRule } from './action';

function PointForm() {
  const dispatch = useDispatch();
  const [initialValueAnswer, setInitialValueAnswer] = useState(FORM_FIELD.pointAnswer);
  const [initialValueLike, setInitialValueLike] = useState(FORM_FIELD.pointLike);
  const [initialValueDisLike, setInitialValueDisLike] = useState(FORM_FIELD.pointDisLike);

  const getRuleF = () => {
    dispatch(globalLoaderStart());
    getRule()
      .then(({ status, data }) => {
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(globalLoaderEnd());
          const Rules = data?.Data?.Rules;
          if (!Rules) return;
          const findAnsValue = Rules.find((item) => item.RuleEnumId === RuleEnum.POINTS_PER_ANSWER);
          if (findAnsValue)
            setInitialValueAnswer({
              answer: String(findAnsValue.RuleValue)
            });
          const findLikeValue = Rules.find((item) => item.RuleEnumId === RuleEnum.POINTS_PER_LIKE);
          if (findLikeValue)
            setInitialValueLike({
              like: String(findLikeValue.RuleValue)
            });
          const findDislikeValue = Rules.find((item) => item.RuleEnumId === RuleEnum.POINTS_PER_DISLIKE);
          if (findDislikeValue)
            setInitialValueDisLike({
              dislike: String(findDislikeValue.RuleValue)
            });
        }
      })
      .catch(() => {
        dispatch(globalLoaderEnd());
      });
  };

  useEffect(() => {
    getRuleF();
  }, []);

  const handleSubmitAnswer = (
    value: typeof FORM_FIELD.pointAnswer,
    { setSubmitting, resetForm }: FormikHelpers<typeof FORM_FIELD.pointAnswer>
  ) => {
    if (value.answer === initialValueAnswer.answer) {
      setSubmitting(false);
      return;
    }
    const payload = {
      RuleId: 3,
      RuleEnumId: RuleEnum.POINTS_PER_ANSWER,
      RuleValue: value.answer
    };
    updateRule(payload)
      .then((res) => {
        if (CONSTANTS.API_SUCCESS_CODE.SUCCESS === res.status) showAlert(1, 'Answer point updated successfully');
        getRuleF();
        resetForm();
        setSubmitting(false);
      })
      .catch(() => {
        showAlert(2);
        setSubmitting(false);
      });
  };

  const handleSubmitLike = (
    value: typeof FORM_FIELD.pointLike,
    { setSubmitting, resetForm }: FormikHelpers<typeof FORM_FIELD.pointLike>
  ) => {
    if (value.like === initialValueLike.like) {
      setSubmitting(false);
      return;
    }
    const payload = {
      RuleId: 1,
      RuleEnumId: RuleEnum.POINTS_PER_LIKE,
      RuleValue: value.like
    };
    updateRule(payload)
      .then((res) => {
        if (CONSTANTS.API_SUCCESS_CODE.SUCCESS === res.status) showAlert(1, 'Like point updated successfully');
        getRuleF();
        resetForm();
        setSubmitting(false);
      })
      .catch(() => {
        showAlert(2);
        setSubmitting(false);
      });
  };

  const handleSubmitDisLike = (
    value: typeof FORM_FIELD.pointDisLike,
    { setSubmitting, resetForm }: FormikHelpers<typeof FORM_FIELD.pointDisLike>
  ) => {
    if (value.dislike === initialValueDisLike.dislike) {
      setSubmitting(false);
      return;
    }
    const payload = {
      RuleId: 2,
      RuleEnumId: RuleEnum.POINTS_PER_DISLIKE,
      RuleValue: value.dislike
    };
    updateRule(payload)
      .then((res) => {
        if (CONSTANTS.API_SUCCESS_CODE.SUCCESS === res.status) showAlert(1, 'Dislike point updated successfully');
        getRuleF();
        resetForm();
        setSubmitting(false);
      })
      .catch(() => {
        showAlert(2);
        setSubmitting(false);
      });
  };

  const pointFormReuse = ({
    initialValues,
    validationSchema,
    onSubmit,
    heading,
    name,
    title
  }: PointFormReuseProps<any>) => {
    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
        {({ isSubmitting }) => {
          return (
            <Form>
              <Box sx={{ my: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ flexBasis: '200px' }}>
                  <Typography
                    variant="h1"
                    color="primary"
                    sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}>
                    {heading}
                  </Typography>
                </Box>
                <Box sx={{ mr: 1 }}>
                  <TextFieldWrapper type="number" name={name} size="small" placeholder="Enter points" />
                </Box>
                <Typography
                  variant="h3"
                  color="primary"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, flexBasis: '100px' }}>
                  {title}
                </Typography>
                <Box sx={{ ml: 2 }}>
                  <ButtonWrapper variant="contained" type="submit" disabled={isSubmitting} sx={{ ml: 1 }}>
                    {isSubmitting ? 'Loading...' : 'Update'}
                  </ButtonWrapper>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      {pointFormReuse({
        initialValues: initialValueAnswer,
        validationSchema: SCHEMA.pointAnswer,
        onSubmit: handleSubmitAnswer,
        heading: ' For Each Answer',
        name: 'answer',
        title: 'Credit points'
      })}
      {pointFormReuse({
        initialValues: initialValueLike,
        validationSchema: SCHEMA.pointLike,
        onSubmit: handleSubmitLike,
        heading: 'For Each Like',
        name: 'like',
        title: 'Credit points'
      })}
      {pointFormReuse({
        initialValues: initialValueDisLike,
        validationSchema: SCHEMA.pointDisLike,
        onSubmit: handleSubmitDisLike,
        heading: 'For Each Dislike',
        name: 'dislike',
        title: 'Debit points'
      })}
    </>
  );
}

export default PointForm;
