import React from 'react';
import PrivateContainer from '../templates/private.container';

type ProtectedRoutesProps = {
  children: React.ReactNode;
};

function ProtectedRoutes({ children }: ProtectedRoutesProps) {
  return (
    <>
      <PrivateContainer> {children}</PrivateContainer>
    </>
  );
}

export default ProtectedRoutes;
