import { ManageAssignFormularytoSchemeModal, ManageFormularyIcd10DiagnosisBlocksModal, ManageSchemeSpecificFormularyModal, SchemeIcdMedicine } from '../../modal/manage.scheme.specific.formulary';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';
import { SchemeDetailModal } from '../../modal/scheme.formulary';
import { AddFormulary } from '../../modal';
import { AssignSchemeToFormulary, CopySchemeSpecificFormulary, CopySchemeSpecificFormularyBlock } from '../../modal/manage.icd10.diagnosis';

export const manageSchemeSpecificFormularyReducer = (
  state: ManageSchemeSpecificFormularyModal = new ManageSchemeSpecificFormularyModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const schemeDetailReducer = (state: SchemeDetailModal = new SchemeDetailModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.SCHEME_DETAIL:
      return { ...state, ...action.payload };
    case ACTION_NAME.SCHEME_DETAIL_RESET:
      return { ...new SchemeDetailModal() };
    default:
      return state;
  }
};

export const addFormularyReducer = (state: AddFormulary = new AddFormulary(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.ADD_FORMULARY:
      return { ...state, ...action.payload };
    case ACTION_NAME.RESET_ADD_FORMULARY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const schemeIcdMedicineReducer = (state: SchemeIcdMedicine = new SchemeIcdMedicine(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.SCHEME_MEDICINE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const manageFormularyIcd10DiagnosisBlocksReducer = (
  state: ManageFormularyIcd10DiagnosisBlocksModal = new ManageFormularyIcd10DiagnosisBlocksModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.MANAGE_FORMULARY_ICD10_DIAGNOSIS_BLOCKS_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const manageAssignFormularytoSchemeReducer = (
  state: ManageAssignFormularytoSchemeModal = new ManageAssignFormularytoSchemeModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.MANAGE_ASSIGN_FORMULARY_TO_SCHEME_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export const assignFormularytoSchemeReducer = (state: AssignSchemeToFormulary = new AssignSchemeToFormulary(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME:
      return { ...state, ...action.payload };
    case ACTION_NAME.RESET_ASSIGN_FORMULARY_TO_SCHEME:
     return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const copySchemeSpecificFormularyReducer = (state:CopySchemeSpecificFormulary = new CopySchemeSpecificFormulary(), action:ActionType)=>{
  switch (action.type){
    case ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY:
      return {...state, ...action.payload};
    case ACTION_NAME.RESET_COPY_SCHEME_SPECIFIC_FORMULARY:
      return {...state, ...action.payload}
    default:
      return state    
  }
}

export const copySchemeSpecificFormularyBlockReducer = (state: CopySchemeSpecificFormularyBlock= new CopySchemeSpecificFormularyBlock, action:ActionType)=>{
  switch (action.type){
    case ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK:
      return {...state, ...action.payload};
    case ACTION_NAME.RESET_COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK:
      return {...state, ...action.payload}
    default:
      return state    
  }
}