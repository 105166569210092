import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Container, IconButton, Menu, MenuItem, Toolbar, Typography, Theme, Badge } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { Box } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import ROUTES_NAME from '../../utils/routes.name';
import { NavLink, useNavigate } from 'react-router-dom';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import { getNotificationCount, getNotificationListAction } from '../../pages/notification/action';
import { logout } from '../../pages/login/service';
import { PlatformEnum } from '../../types/login';
import showAlert from '../../utils/alert';
import CONSTANTS from '../../utils/constants';
import SESSION_MANAGEMENT from '../../utils/session.management';
import { useDispatch, useSelector } from 'react-redux';
import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import ButtonWrapper from '../button/button.wrapper';
import { ReducersModal } from '../../modal';
import { whichPortalRun } from '../../utils/common.function';
import { Role } from '../../types/global.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      '&.MuiTypography-root': {
        font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(3.4)} Josefin Sans, sans-serif`,
        letterSpacing: theme.spacing(0.5)
      }
    },
    logout: {
      '&.MuiTypography-root': {
        font: `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.6)} 'Helvetica', sans-serif`,
        cursor: 'pointer',
        color: theme.palette.common.black
      }
    },
    link: {
      width: '100%',
      color: theme.palette.primary.main,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
      }
    }
  })
);

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { TotalCount } = useSelector((state: ReducersModal) => state.notificationListReducer);

  const getNotification = () => {
    getNotificationCount().then(({ data }) => {
      const { QuestionCount, RecommendationCount, VlogCount } = data.Data;
      const totalCount = QuestionCount + RecommendationCount + VlogCount;
      dispatch(getNotificationListAction({ TotalCount: totalCount }));
    });
  };

  useEffect(() => {
    getNotification();
    intervalRef.current = setInterval(() => {
      getNotification();
    }, 30000);

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    };
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = () => {
    dispatch(getNotificationListAction({ Page: 1 }));
    navigate(ROUTES_NAME.NOTIFICATION);
  };

  const handleLogout = async () => {
    const userEmail = localStorage.getItem('userEmail');
    if (!userEmail) return;
    dispatch(globalLoaderStart());
    try {
      const { status } = await logout({
        PrincipalIdentifierValue: userEmail,
        PlatformEnumId: PlatformEnum.WEB
      });
      if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
        SESSION_MANAGEMENT.removeSession();
        navigate(ROUTES_NAME.Login);
        showAlert(1, "You've been logged out successfully");
      }
    } catch (err) {
      showAlert(2);
    }
    dispatch(globalLoaderEnd());
  };

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: (theme) => theme.spacing(7),
        position: 'unset',
        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 1),
        justifyContent: 'center'
      }}>
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'end',
            display: { md: 'flex' }
          }}>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography>
                  <NavLink to={ROUTES_NAME.FAQ} className={classes.link}>
                    FAQs Management
                  </NavLink>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography>
                  <NavLink to={ROUTES_NAME.DOCTORS_VLOG} className={classes.link}>
                    Doctor’s Video
                  </NavLink>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography>
                  <NavLink to={ROUTES_NAME.DOCTORS_RECOMMENDATION} className={classes.link}>
                    Doctor’s Recommendation
                  </NavLink>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography>
                  <NavLink to={ROUTES_NAME.MANAGE_CATEGORY} className={classes.link}>
                    Manage Category
                  </NavLink>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography>
                  <NavLink to={ROUTES_NAME.MANAGE_POINT} className={classes.link}>
                    Manage Livia Points
                  </NavLink>
                </Typography>
              </MenuItem>
              {whichPortalRun() !== Role.Blog && (
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography>
                    <NavLink to={ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS} className={classes.link}>
                      ICD10 Code Blocks
                    </NavLink>
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 1.5, cursor: 'pointer' }} onClick={handleNavigate}>
              <Badge
                badgeContent={TotalCount}
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    right: 7,
                    top: 7
                  }
                }}>
                <NotificationsNoneRoundedIcon color="primary" fontSize="large" />
              </Badge>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '2px',
                  backgroundColor: 'var(--table-delete-edit-middle-line)',
                  height: '35px',
                  mr: 1.5
                }}></Box>
              <ButtonWrapper variant="contained" onClick={handleLogout} className={classes.logout}>
                Logout
              </ButtonWrapper>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
