import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function DelButton({ ...otherProps }: IconButtonProps) {
  return (
    <IconButton color="error" sx={{ p: 0.25 }} {...otherProps}>
      <DeleteForeverIcon />
    </IconButton>
  );
}

export default DelButton;
