import { Box, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material';
import TableCellWrapper from '../../../components/table/table.cell.wrapper';
import TableRowWrapper from '../../../components/table/table.row.wrapper';
import LOCAL_IMAGES from '../../../utils/local.images';
import { formatDateAndTime } from '../../../utils/common.function';
import PaginateWrapper from '../../../components/paginate-wrapper/paginate.wrapper';
import ConfirmButton from '../../../components/button/confirm.button';
import { ISMedicine } from '../../../types/manage.scheme.specific.formulary';
import { getSchemeIcdMedicineAction } from '../action';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../../modal';
import DelButton from '../../../components/button/del.button';

type MedicineTableProps = {
  data: ISMedicine[];
  handleOpenInactive: (item: ISMedicine) => void;
  pageCount: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  rowPerPage: number;
  totalRecord: number;
  page: number;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
};

function MedicineTable({
  data,
  handleOpenInactive,
  pageCount,
  handlePageClick,
  totalRecord,
  page,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage
}: MedicineTableProps) {
  const dispatch = useDispatch();
  const { SortOrder } = useSelector((state: ReducersModal) => state.schemeIcdMedicineReducer);
  const handleAscAndDesc = () => {
    dispatch(
      getSchemeIcdMedicineAction({
        ...(SortOrder === 'ASC' ? { SortOrder: 'DESC' } : { SortOrder: 'ASC' })
      })
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>Drug Id</TableCellWrapper>
                <TableCellWrapper>Product Trade Name</TableCellWrapper>
                <TableCellWrapper>Order of tier</TableCellWrapper>
                <TableCellWrapper>API</TableCellWrapper>
                <TableCellWrapper>API Strength</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Created at
                    </Typography>
                    <Box sx={{ ml: 0.5, cursor: 'pointer' }} onClick={handleAscAndDesc}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: ISMedicine, index: number) => {
                  return (
                    <TableRowWrapper hover key={item?.MedicineId}>
                      <TableCellWrapper>{index + page}</TableCellWrapper>
                      <TableCellWrapper>{item.MedicineId}</TableCellWrapper>
                      <TableCellWrapper>{item?.MedicineName ?? 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.OrderOfTier ?? 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.MedicineApi.trim() ? item.MedicineApi : 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.MedicineApiStrength || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item.CreatedAt) ?? 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        {item.IsActive === 1 && (
                          <DelButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenInactive(item);
                            }}
                          />
                        )}
                        {item.IsActive === 0 && (
                          <ConfirmButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenInactive(item);
                            }}
                          />
                        )}
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={9}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            activePage={activePage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={page}
            rowpPerPage={rowPerPage}
            totalRecord={totalRecord}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            // isShowPreAndNext
          />
        )}
      </Box>
    </>
  );
}

export default MedicineTable;
