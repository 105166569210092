import { useCallback, useEffect, useRef, useState } from 'react';
import { FormikHelpers } from 'formik';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import SearchTextfield from '../../components/search-textfield/search.textfield';
import Icd10DiagnosisBlockTable from './icd10.diagnosis.table';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIcd10DiagnosisBlocksList,
  manageIcd10DiagnosisBlockListAction,
  resetAddBlock,
  resetCopyICD10Block,
  updateStatusIcd10DiagnosisBlocksApi
} from './action';
import { ReducersModal } from '../../modal';
import SelectWrapper from '../../components/form-ui/select-wrapper/select.wrapper';
import { SELECT_MENU_ITEM } from '../../utils/static.data';
import FilterButton from '../../components/button/filter.button';
import Icd10DiagnosisBlockFilterModal from '../../components/modal-wrapper/icd10.diagnosis.block.filter.modal';
import FORM_FIELD from '../../validations/form-field';
import useDebounce from '../../hooks/use.debounce';
import {
  calculatePage,
  calculateRowPerPage,
  convertDiagosis,
  formatDateAndTimeForfilter,
  checkIcd10BlockFilterApplyOrNot
} from '../../utils/common.function';
import ButtonWrapper from '../../components/button/button.wrapper';
import AddBlock from './add.block';
import { Block } from '../../types/manage.icd10.diagnosis.block';
import ACTION_NAME from '../../utils/action.name';
import InactiveModal from '../../components/modal-wrapper/inactive.modal';
import CopyBlock from './copy.block';
// import showAlert from '../../utils/alert';
// import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import CopyIcd10Block from './copy.icd10.block';

function ManageIcd10DiagnosisBlocks() {
  const dispatch = useDispatch();
  const filterRef = useRef<HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [openInactive, setOpenInactive] = useState(false);
  const [updateStatusIcd10DiagnosisBlock, setUpdateStatusIcd10DiagnosisBlock] = useState({} as Block);
  const debouncedValue = useDebounce(inputValue.trim(), 1000);
  const [openCopyICD10Block, setOpenCopyICD10Block] = useState(false)

  const { openBlock } = useSelector((state: ReducersModal) => state.addBlockReducer);

  const {
    Blocks,
    Page,
    TotalRecords,
    ItemOffset,
    SortBy,
    SortOrder,
    InsuranceCompanyId,
    StatusId,
    FromDate,
    ToDate,
    initialValueSortDate
  } = useSelector((state: ReducersModal) => state.manageIcd10DiagnosisBlocksReducer);
  const [pageCount, setPageCount] = useState(Page);

  useEffect(() => {
    dispatch(
      getIcd10DiagnosisBlocksList({
        page: Page,
        limit: ItemOffset,
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(debouncedValue && debouncedValue !== '' && { filterValue: debouncedValue }),
        ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
        ...(StatusId && { statusId: StatusId }),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      })
    );
  }, [dispatch, Page, ItemOffset, debouncedValue, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(manageIcd10DiagnosisBlockListAction({ Page: selectedPage + 1 }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };

  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    dispatch(manageIcd10DiagnosisBlockListAction({ Page: 1, ItemOffset: Number(itemOffset) }));
  };

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const handleOpenFilter = () => {
    setInputValue('');
    setOpenFilter(true);
  };

  const handleFilterSubmit = (
    { fromDate, toDate, insuranceCompanyId, statusId }: typeof FORM_FIELD.icd10DiagnosisFilterListing,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.icd10DiagnosisFilterListing>
  ) => {
    let statusVal = statusId;
    if (statusId === 'all') statusVal = '';
    if (fromDate && toDate) {
      dispatch(
        manageIcd10DiagnosisBlockListAction({
          FromDate: formatDateAndTimeForfilter(fromDate),
          ToDate: formatDateAndTimeForfilter(toDate),
          initialValueSortDate: {
            fromDate,
            toDate,
            insuranceCompanyId,
            statusId
          }
        })
      );
    }
    dispatch(
      manageIcd10DiagnosisBlockListAction({
        InsuranceCompanyId: insuranceCompanyId,
        StatusId: statusVal,
        initialValueSortDate: {
          fromDate,
          toDate,
          insuranceCompanyId,
          statusId
        }
      })
    );

    handleCloseFilter();
    setSubmitting(false);
  };

  const handlePrevious = () => {
    dispatch(manageIcd10DiagnosisBlockListAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(manageIcd10DiagnosisBlockListAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };

  const handleFilterReset = () => {
    dispatch(
      manageIcd10DiagnosisBlockListAction({
        InsuranceCompanyId: 0,
        StatusId: '',
        FromDate: null,
        ToDate: null,
        initialValueSortDate: {
          fromDate: null,
          toDate: null,
          insuranceCompanyId: 0,
          statusId: 'all'
        }
      })
    );
  };

  const handleOpenBlock = () => {
    dispatch({
      type: ACTION_NAME.ADD_BLOCK,
      payload: {
        openBlock: true
      }
    });
  };

  const handleCloseBlock = () => {
    dispatch({
      type: ACTION_NAME.ADD_BLOCK,
      payload: {
        openBlock: false
      }
    });
  };

  const handleOpenEditBlock = (
    type: string,
    { BlockId, Name, InsuranceCompanyName, InsuranceCompanyId, Diagnosis }: Block
  ) => {
    if (!BlockId) return;
    const diagnosis = convertDiagosis(Diagnosis);
    dispatch({
      type: ACTION_NAME.ADD_BLOCK,
      payload: {
        page: 'list',
        blockId: BlockId,
        type: type,
        blockName: Name,
        insuranceCompany: {
          InsuranceCompanyId: InsuranceCompanyId,
          Name: InsuranceCompanyName,
          LogoURL: null
        },
        icd10: diagnosis
      }
    });
    handleOpenBlock();
  };
  const handleCloseInactive = useCallback(() => {
    setOpenInactive(false);
  }, []);

  const handleOpenInactive = (item: Block) => {
    setOpenInactive(true);
    setUpdateStatusIcd10DiagnosisBlock(item);
  };

  const handleConfirmInactive = useCallback(() => {
    const payload = {
      BlockId: updateStatusIcd10DiagnosisBlock.BlockId,
      IsActive: updateStatusIcd10DiagnosisBlock.IsActive === 0 ? 1 : 0
    };
    dispatch(updateStatusIcd10DiagnosisBlocksApi(payload, handleCloseInactive, 'list'));
  }, [updateStatusIcd10DiagnosisBlock]);

  // const handleOpenCopyBlock = async (copyBlock: Block) => {
  //   try {
  //     dispatch(globalLoaderStart());
  //     const { data } = await copyGetMedicne({ page: 1, limit: copyBlock.MedicineCount, blockId: copyBlock.BlockId });
  //     dispatch({
  //       type: ACTION_NAME.COPY_BLOCK,
  //       payload: {
  //         blockId: copyBlock.BlockId,
  //         copyBlockName: `${copyBlock.Name}-Copy`,
  //         copyInsuranceCompany: {
  //           InsuranceCompanyId: copyBlock.InsuranceCompanyId,
  //           Name: copyBlock.InsuranceCompanyName,
  //           LogoURL: null
  //         },
  //         copyIcd10: copyBlock.Diagnosis,
  //         copyMedicine: data.Data.Medicines,
  //         orderOfTier: data.Data.Medicines,
  //         isDisabled: false
  //       }
  //     });
  //     dispatch(globalLoaderEnd());
  //     setOpenCopy(true);
  //   } catch (_e) {
  //     dispatch(globalLoaderEnd());
  //     showAlert(2);
  //   }
  // };

  const handleCloseCopyBlock = () => {
    setOpenCopy(false);
  };

  const handleCloseCopyICD10Block = ()=>{
    dispatch(resetCopyICD10Block())
    setOpenCopyICD10Block(false)
  }
  const handleOpenCopyICD10Block = ()=>{
    setOpenCopyICD10Block(true)
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography variant="h1">ICD10 Code Blocks</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <ButtonWrapper
              variant="contained"
              color="primary"
              sx={{ mr: 1, display: 'none' }}
              onClick={() => {
                dispatch(resetAddBlock());
                handleOpenBlock();
              }}>
              Add ICD10 Code Block
            </ButtonWrapper>
            <Box ref={filterRef}>
              <FilterButton
                onClick={handleOpenFilter}
                isFilterApply={checkIcd10BlockFilterApplyOrNot(initialValueSortDate)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: { xs: 'initial', sm: 'center' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1 }
          }}>
          <Box sx={{ flex: 1 }}>
            <SearchTextfield
              placeholder={'Search by Block name, ICD10 Code'}
              inputValue={inputValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              name="searchInput"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <Box sx={{ mr: 2 }}>
              <SelectWrapper value={ItemOffset} onChange={handleChangeShowSort} menuItem={SELECT_MENU_ITEM} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Icd10DiagnosisBlockTable
            data={Blocks}
            handleOpenInactive={handleOpenInactive}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            page={calculatePage(Page, ItemOffset)}
            totalRecord={TotalRecords}
            rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            activePage={Page}
            handleOpenEditBlock={handleOpenEditBlock}
            handleOpenCopyICD10Block={handleOpenCopyICD10Block}
          />
        </Box>
      </Box>
      <AddBlock open={openBlock} handleClose={handleCloseBlock} />
      <CopyBlock open={openCopy} handleClose={handleCloseCopyBlock} />
      <CopyIcd10Block open={openCopyICD10Block} handleClose={handleCloseCopyICD10Block}/>
      <Icd10DiagnosisBlockFilterModal
        open={openFilter}
        handleClose={handleCloseFilter}
        handleFilterSubmit={handleFilterSubmit}
        initialValues={initialValueSortDate}
        handleFilterReset={handleFilterReset}
        filterButtonPosition={filterRef.current?.getBoundingClientRect()}
      />
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusIcd10DiagnosisBlock.IsActive === 0 ? 'Activate' : 'Suspend'}
        paragraph={
          <>
            Are you sure you want to {updateStatusIcd10DiagnosisBlock.IsActive === 0 ? 'activate' : 'suspend'}
            <br /> the selected block?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
}

export default ManageIcd10DiagnosisBlocks;
