import { Autocomplete, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/form-ui/textfield-wrapper/input.field";
import useDebounce from "../../hooks/use.debounce";
import { ReducersModal } from "../../modal";
import { Formulary } from "../../types/manage.scheme.specific.formulary";
import ACTION_NAME from "../../utils/action.name";
import { getSchemeSpecificFormularyList } from "./action";
type AutocomWrapProps = {
    name: string;
    label: string;
    disable:boolean;
  };
function AutocomAssignFormularySearch({name, label, disable}:AutocomWrapProps) {
    const dispatch = useDispatch()
    const [formularyList, setFormularyList] = useState<Formulary[]>([])
    const [value, setValue] = useState({} as Formulary);
    const debouncedValue = useDebounce("".trim(), 1000);
    const {
        Page,
        Formulary,
        ItemOffset,
        SortBy,
        SortOrder,
        } = useSelector((state: ReducersModal) => state.manageSchemeSpecificFormularyReducer);
        const { InsuranceCompanyId, FormularyId } = useSelector((state: ReducersModal) => state.assignFormularytoSchemeReducer);
      useEffect(() => {
        dispatch(
          getSchemeSpecificFormularyList({
            page: Page,
            limit: ItemOffset,
            insuranceCompanyId:InsuranceCompanyId,
            ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
            ...(debouncedValue && debouncedValue !== '' && { filterValue: debouncedValue }),
            })
        );
      }, [dispatch]);
      useEffect(()=>{setFormularyList(Formulary)},[Formulary])
      useEffect(()=>{
        setValue(formularyList?.find((formulary)=> formulary.FormularyId === FormularyId) || {} as Formulary)
      },[ FormularyId, formularyList])
      
      return (
          <Autocomplete
            disabled={disable}
            value={Object.keys(value).length === 0 ? null : value}
            size="small"
            options={formularyList}
            onChange={(_e, newValue) => {
              if (!newValue) return;
              setValue(newValue);
              dispatch({
                type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
                payload: {
                  InsuranceCompanyId: newValue.InsuranceCompanyId,
                  FormularyId:newValue.FormularyId,
                  Scheme:[]
                }
              });
            }}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => option.Name}
            renderOption={(props, option) => {
              return (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.Name}
                </Box>
              );
            }}
            renderInput={(params) => (
              <InputField
                name={name}
                {...params}
                label={label}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off'
                }}
              />
            )}
          />
        );
    }


export default AutocomAssignFormularySearch