import { GlobalLoaderModal } from '../modal';
import { ActionType } from '../types/global.types';
import ACTION_NAME from '../utils/action.name';

export const globalLoaderReducer = (state: GlobalLoaderModal = new GlobalLoaderModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const globalLoaderStart = () => {
  return {
    type: ACTION_NAME.LOADING,
    payload: { isLoading: true }
  };
};

export const globalLoaderEnd = () => {
  return {
    type: ACTION_NAME.LOADING,
    payload: { isLoading: false }
  };
};
