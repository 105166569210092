import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CopyButton({ ...otherProps }: IconButtonProps) {
  return (
    <IconButton color="primary" sx={{ p: 0.25 }} {...otherProps}>
      <ContentCopyIcon />
    </IconButton>
  );
}

export default CopyButton;
