import { Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import PhoneIcon from '@mui/icons-material/Phone';
import CONSTANTS from '../../utils/constants';

type ProfileProps = {
  img: string;
  name: string;
  specialist: string;
  rating: number | null;
  phoneNumber: string;
};

function Profile({ img, name, specialist, rating, phoneNumber }: ProfileProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.secondary.main,
        p: { xs: 1, sm: 2 }
      }}>
      <Box>
        <Avatar
          sx={{
            bgcolor: (theme) => theme.palette.secondary.light,
            width: { xs: 100, sm: 136 },
            height: { xs: 100, sm: 136 },
            border: (theme) => `1px solid ${theme.palette.primary.main}`
          }}>
          {img ? (
            <img src={CONSTANTS.IMAGE_BASE_URL + img} alt="" width="136" height="136" />
          ) : (
            <PersonIcon color="primary" sx={{ width: 90, height: 90 }} />
          )}
        </Avatar>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'stretch',
          justifyContent: 'space-evenly',
          ml: (theme) => theme.spacing(3)
        }}>
        <Typography
          variant="h5"
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: (theme) => theme.spacing(2.4),
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {name ?? 'N/A'}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: 'var(--light-orange)',
            fontSize: (theme) => theme.spacing(1.8),
            textTransform: 'capitalize'
          }}>
          {specialist ?? 'N/A'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: (theme) => theme.spacing(0.3, 1),
              border: '1px solid var(--light-yellow)',
              borderRadius: (theme) => theme.spacing(0.2)
            }}>
            <StarIcon fontSize="small" sx={{ color: 'var(--yellow)' }} />
            <Typography
              variant="h1"
              sx={{
                color: (theme) => theme.palette.common.black,
                fontSize: (theme) => theme.spacing(1.8),
                fontFamily: 'Roboto Sans, sans-serif',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                ml: 0.5
              }}>
              {rating ?? 'N/A'}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: (theme) => theme.spacing(0.3, 1),
              border: '1px solid var(--light-yellow)',
              borderRadius: (theme) => theme.spacing(0.2),
              ml: 1
            }}>
            <PhoneIcon fontSize="small" sx={{ color: 'var(--yellow)' }} />
            <Typography
              variant="h1"
              sx={{
                color: (theme) => theme.palette.common.black,
                fontSize: (theme) => theme.spacing(1.8),
                fontFamily: 'Roboto Sans, sans-serif',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                ml: 0.5
              }}>
              {phoneNumber}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
