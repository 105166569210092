import { alpha, Box, Typography } from '@mui/material';

type TitleNameProps = {
  title: string;
  name: string | number | null;
};

function TitleName({ title, name }: TitleNameProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Typography variant="h1" sx={{ fontSize: (theme) => theme.spacing(1.4) }}>
        {title}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          fontSize: (theme) => theme.spacing(1.6),
          letterSpacing: '.5px',
          color: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
          py: { xs: 0.5, sm: 1 },
          px: { xs: 1, sm: 1.5 },
          marginTop: (theme) => theme.spacing(0.5),
          borderRadius: (theme) => theme.spacing(0.5)
        }}>
        {name ?? 'N/A'}
      </Typography>
    </Box>
  );
}

export default TitleName;
