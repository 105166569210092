import { DOCTORS_VLOG_BREADCRUM_META_DATA } from '../../../utils/static.data';
import { Box } from '@mui/system';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import Profile from '../../../components/profile/profile';
import DetailCard from './detail.card';
import { useParams } from 'react-router-dom';
import { vlogDeatil } from '../action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../../modal';
import FaqReportList from '../../faq/faq-detail/faq.report.list';

function DoctorVlogDetail() {
  const { recommendationId } = useParams();
  const dispatch = useDispatch();
  const { DoctorImage, DoctorName, DoctorSpecializations, DoctorRating, DoctorPhoneCode, DoctorPhoneNumber } =
    useSelector((state: ReducersModal) => state.vlogDetailReducer);

  useEffect(() => {
    if (recommendationId) dispatch(vlogDeatil(recommendationId));
  }, [recommendationId, dispatch]);

  return (
    <Box>
      <Breadcrumb metaData={DOCTORS_VLOG_BREADCRUM_META_DATA} />
      <Box sx={{ my: 2 }}>
        <Profile
          img={DoctorImage}
          name={DoctorName}
          specialist={DoctorSpecializations}
          rating={DoctorRating}
          phoneNumber={`+${DoctorPhoneCode ? DoctorPhoneCode : ''}${DoctorPhoneNumber ? DoctorPhoneNumber : ''}`}
        />
      </Box>
      <Box>
        <DetailCard />
      </Box>
      <Box my={3}>{recommendationId && <FaqReportList answerId={Number(recommendationId)} vlog />}</Box>
    </Box>
  );
}

export default DoctorVlogDetail;
