import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReducersModal } from '../../../modal';
import { icdDetail } from '../action';
import DiabetesBlock from './diabetes.block';
import IcdCode from './icd.code';
import MedicineCard from './medicine.card';

function IcdDetail() {
  const { blockId } = useParams<{ blockId: string }>();
  const dispatch = useDispatch();
  const { Diagnosis } = useSelector((state: ReducersModal) => state.icdDetailReducer);

  useEffect(() => {
    if (blockId) dispatch(icdDetail(blockId));
  }, [blockId]);

  return (
    <Box>
      <Typography variant="h1"> ICD10 Code Block Detail</Typography>
      <DiabetesBlock />
      <IcdCode data={Diagnosis} />
      {blockId && <MedicineCard blockId={blockId} />}
    </Box>
  );
}

export default IcdDetail;
