import ROUTES_NAME from './routes.name';

// select menu data
export const SELECT_MENU_ITEM = [
  { key: 'Show 10', value: 10 },
  { key: 'Show 20', value: 20 },
  { key: 'Show 30', value: 30 },
  { key: 'Show 40', value: 40 },
  { key: 'Show 50', value: 50 },
  { key: 'Show 60', value: 60 },
  { key: 'Show 70', value: 70 },
  { key: 'Show 80', value: 80 },
  { key: 'Show 90', value: 90 },
  { key: 'Show 100', value: 100 },
  
];

export const POINTS_DETAIL_SELECT_MENU_ITEM = [
  { key: 'Show 5', value: '5' },
  { key: 'Show 10', value: '10' },
  { key: 'Show 15', value: '15' }
];

export const REPORT_DETAIL_SELECT_MENU_ITEM = [
  { key: 'Show 5', value: '5' },
  { key: 'Show 10', value: '10' },
  { key: 'Show 15', value: '15' }
];

export const FILTER_SELECT_MENU_ITEM = [{ key: 'Category', value: 'Category' }];

export const RECOMMENDATION_FILTER_BY_SELECT_MENU_ITEM = [
  { key: 'All', value: '3' },
  { key: 'Doctor', value: '2' },
  { key: 'Recommendation', value: '1' }
];

export const VLOG_FILTER_BY_SELECT_MENU_ITEM = [
  { key: 'All', value: '3' },
  { key: 'Doctor', value: '2' },
  { key: 'Vlog', value: '1' }
];

export const QUESTION_LIST_FILTER_BY_SELECT_MENU_ITEM = [
  { key: 'All', value: '3' },
  { key: 'User', value: '2' },
  { key: 'Question', value: '1' }
];

// breadcrum data
export const DOCTORS_RECOMMENDATION_BREADCRUM_META_DATA = [
  {
    name: 'Doctor’s Recommendation',
    link: ROUTES_NAME.DOCTORS_RECOMMENDATION
  },
  {
    name: 'Detail'
  }
];

export const DOCTORS_VLOG_BREADCRUM_META_DATA = [
  {
    name: 'Doctor’s Video',
    link: ROUTES_NAME.DOCTORS_VLOG
  },
  {
    name: 'Detail'
  }
];

export const FAQ_BREADCRUM_META_DATA = [
  {
    name: 'FAQs Management',
    link: ROUTES_NAME.FAQ
  }
];

// csv headers data
export const DOCTOR_RECOMMENDATION_CSV_HEADER = [
  { label: 'Sr No.', key: 'SrNo' },
  { label: 'Recommendation', key: 'Content' },
  { label: 'Doctor Name', key: 'DoctorName' },
  { label: 'Phone Number', key: 'PhoneNumber' },
  { label: 'Category', key: 'Category' },
  { label: 'Report Count', key: 'ReportCount' },
  { label: 'Added On', key: 'AddedOn' }
];

export const QUESTION_CSV_HEADER = [
  { label: 'Sr No.', key: 'SrNo' },
  { label: 'Questions', key: 'Questions' },
  { label: 'Description', key: 'Description' },
  { label: 'Category', key: 'Category' },
  { label: 'Created by', key: 'AddedBy' },
  { label: 'Answers count', key: 'Answers' },
  { label: 'Report Count', key: 'ReportCountOnAnswers' },
  { label: 'Added On', key: 'AddedOn' }
];

export const DOCTOR_VLOG_CSV_HEADER = [
  { label: 'Sr No.', key: 'SrNo' },
  { label: 'Vlog Title', key: 'BlogTitle' },
  { label: 'Doctor Name', key: 'DoctorName' },
  { label: 'Phone Number', key: 'PhoneNumber' },
  { label: 'Category', key: 'Category' },
  { label: 'Report Count', key: 'ReportCount' },
  { label: 'Added On', key: 'AddedOn' }
];

export const STATUSES = [
  { key: 'All', value: 'all' },
  { key: 'Active', value: '1' },
  { key: 'In Active', value: '0' }
];

export const ORDER_OF_TIER_VALUE = [
  { key: 'One', value: '1' },
  { key: 'Two', value: '2' },
  { key: 'Three', value: '3' },
  { key: 'Four', value: '4' },
  { key: 'Five', value: '5' }
];

export const VIDEO_TYPE = 'Video question';
export const AUDIO_TYPE = 'Audio question';
export const TEXT_TYPE = 'Text question';
export const SEARCH_BY = 'Search by';
export const VIEW_NUMBER = 8;
export const ICD10_CODE_VIEW_NUMBER = 5;
export const SCHEME_CODE_VIEW_NUMBER = 8;
