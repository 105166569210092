/* eslint-disable no-console */
import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { InsuranceCompany } from '../../types/insurance.company';
import { useEffect, useState } from 'react';
import { getInsuranceCompany } from '../../global-action/global.action';
import showAlert from '../../utils/alert';
import InputField from '../../components/form-ui/textfield-wrapper/input.field';
import { ReducersModal } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_NAME from '../../utils/action.name';
import { getCopyBlockDataWhenInsurenceCompanyChagne } from './action';
import { addOrderOFTier, convertGetCopyBlockDataWhenInsurenceCompanyChagnePayload } from '../../utils/common.function';
import { IICDDiagnosis } from '../../types/manage.icd10.diagnosis.block';
import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';

type CopyAutoInsuranceProps = {
  label: string;
};

function CopyAutoInsurance({ label }: CopyAutoInsuranceProps) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [insurance, setInsurance] = useState([] as InsuranceCompany[]);
  const { copyInsuranceCompany, copyIcd10, copyMedicine, orderOfTier, isDisabled } = useSelector(
    (state: ReducersModal) => state.copyBlockReducer
  );

  const clearCopyInsuranceCompanyState = () => {
    dispatch({
      type: ACTION_NAME.COPY_BLOCK,
      payload: {
        copyInsuranceCompany: {
          InsuranceCompanyId: 0,
          Name: '',
          LogoURL: null
        }
      }
    });
  };

  useEffect(() => {
    if (isDisabled) return;
    getInsuranceCompany()
      .then(({ data }) => {
        const filterInsuance = data?.Data?.InsuranceCompanies?.filter(
          (item: InsuranceCompany) => item.InsuranceCompanyId !== copyInsuranceCompany.InsuranceCompanyId
        );
        setInsurance(filterInsuance);
        clearCopyInsuranceCompanyState();
      })
      .catch(() => {
        showAlert(2);
      });
  }, [isDisabled]);

  const validateIcd = (icd: IICDDiagnosis) => {
    if (icd.length <= 0) {
      clearCopyInsuranceCompanyState();
      setInputValue('');
      return showAlert(2, "ICD10 code doesn't exist");
    }
    return true;
  };

  const onHandleChangeUpdateCopyBlock = (insuranceCompany: InsuranceCompany) => {
    const payload = convertGetCopyBlockDataWhenInsurenceCompanyChagnePayload({
      insuranceCompanyId: insuranceCompany.InsuranceCompanyId,
      diagnosis: copyIcd10,
      medicine: copyMedicine
    });
    dispatch(globalLoaderStart());
    getCopyBlockDataWhenInsurenceCompanyChagne(payload)
      .then(({ data }) => {
        dispatch(globalLoaderEnd());
        const isIcdCode = validateIcd(data.Data.Diagnosis);
        if (!isIcdCode) return;
        dispatch({
          type: ACTION_NAME.COPY_BLOCK,
          payload: {
            isDisabled: true,
            copyInsuranceCompany: insuranceCompany,
            copyIcd10: data.Data?.Diagnosis,
            copyMedicine: addOrderOFTier(data.Data.Medicines, orderOfTier)
          }
        });
      })
      .catch(() => {
        dispatch(globalLoaderEnd());
        showAlert(2);
      });
  };

  return (
    <Autocomplete
      disabled={isDisabled || copyInsuranceCompany.InsuranceCompanyId !== 0}
      value={copyInsuranceCompany.InsuranceCompanyId === 0 ? null : copyInsuranceCompany}
      onChange={(_e, newValue, reason) => {
        if (reason === 'clear') clearCopyInsuranceCompanyState();
        if (!newValue) return;
        onHandleChangeUpdateCopyBlock(newValue);
      }}
      size="small"
      options={insurance}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.Name === value.Name}
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => {
        return (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.Name}
          </Box>
        );
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
}

export default CopyAutoInsurance;
