import { ManageCategoryModal } from './manage.category';
import {
  ManageIcd10DiagnosisBlocksModal,
  AddBlock,
  IcdDetail,
  IcdMedicine,
  CopyBlock,
  AddFormulary,
  AssignSchemeToFormulary,
  CopySchemeSpecificFormulary,
  CopySchemeSpecificFormularyBlock,
  CopyICD10Block
} from './manage.icd10.diagnosis';
import {
  DoctorRecommendationModal,
  RecommendationReportListModal,
  RecommendationsDetailModal
} from './doctor.recommendation';
import { QuestionDetailModal, QuestionListModal } from './faq';
import { DoctorVlogModal, VlogDetailModal } from './doctor.vlog';
import { NotificationListModal } from './notification';
import { ManageAssignFormularytoSchemeModal, ManageFormularyIcd10DiagnosisBlocksModal, ManageSchemeSpecificFormularyModal, SchemeIcdMedicine } from './manage.scheme.specific.formulary';
import { SchemeDetailModal } from './scheme.formulary';

class GlobalLoaderModal {
  isLoading = false;
}

class ReducersModal {
  globalLoaderReducer: GlobalLoaderModal = new GlobalLoaderModal();
  manageIcd10DiagnosisBlocksReducer: ManageIcd10DiagnosisBlocksModal = new ManageIcd10DiagnosisBlocksModal();
  manageSchemeSpecificFormularyReducer: ManageSchemeSpecificFormularyModal = new ManageSchemeSpecificFormularyModal();
  addBlockReducer: AddBlock = new AddBlock();
  addFormularyReducer: AddFormulary = new AddFormulary();
  icdDetailReducer: IcdDetail = new IcdDetail();
  icdMedicineReducer: IcdMedicine = new IcdMedicine();
  copyBlockReducer: CopyBlock = new CopyBlock();
  manageCategoryReducer: ManageCategoryModal = new ManageCategoryModal();
  doctorRecommendationReducer: DoctorRecommendationModal = new DoctorRecommendationModal();
  recommendationDeatilReducer: RecommendationsDetailModal = new RecommendationsDetailModal();
  recommendationReportListReducer: RecommendationReportListModal = new RecommendationReportListModal();
  questionListReducer: QuestionListModal = new QuestionListModal();
  questionDetailReducer: QuestionDetailModal = new QuestionDetailModal();
  doctorVlogReducer: DoctorVlogModal = new DoctorVlogModal();
  vlogDetailReducer: VlogDetailModal = new VlogDetailModal();
  notificationListReducer: NotificationListModal = new NotificationListModal();
  schemeDetailReducer: SchemeDetailModal = new SchemeDetailModal();
  schemeIcdMedicineReducer: SchemeIcdMedicine = new SchemeIcdMedicine();
  manageFormularyIcd10DiagnosisBlocksReducer: ManageFormularyIcd10DiagnosisBlocksModal = new ManageFormularyIcd10DiagnosisBlocksModal();
  manageAssignFormularytoSchemeReducer: ManageAssignFormularytoSchemeModal = new ManageAssignFormularytoSchemeModal()
  assignFormularytoSchemeReducer:AssignSchemeToFormulary= new AssignSchemeToFormulary();
  copySchemeSpecificFormularyReducer:CopySchemeSpecificFormulary= new CopySchemeSpecificFormulary();
  copySchemeSpecificFormularyBlockReducer:CopySchemeSpecificFormularyBlock = new CopySchemeSpecificFormularyBlock();
  copyICD10BlockReducer:CopyICD10Block = new CopyICD10Block();
}

export {
  ReducersModal,
  GlobalLoaderModal,
  ManageIcd10DiagnosisBlocksModal,
  ManageCategoryModal,
  DoctorRecommendationModal,
  RecommendationsDetailModal,
  RecommendationReportListModal,
  QuestionListModal,
  QuestionDetailModal,
  DoctorVlogModal,
  VlogDetailModal,
  NotificationListModal,
  AddBlock,
  AddFormulary,
  IcdDetail,
  IcdMedicine,
  CopyBlock,
  ManageSchemeSpecificFormularyModal,
  SchemeDetailModal,
  SchemeIcdMedicine,
  ManageFormularyIcd10DiagnosisBlocksModal,
  ManageAssignFormularytoSchemeModal,
  AssignSchemeToFormulary,
  CopySchemeSpecificFormulary,
  CopySchemeSpecificFormularyBlock,
  CopyICD10Block,
};
