const ADMIN = '/admin';

const END_POINTS = {
  ADMIN_ICD10_DIAGNOSIS: `/diagnosis`,
  UPDATE_STATUS_ADMIN_ICD10_DIAGNOSIS: `/block/status/update`,
  UPDATE_ADMIN_ICD10_DIAGNOSIS: `/block/update`,
  ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST: `/block`,
  BLOCK_MEDICINE: `/block/medicine`,
  GET_ALL_MEDICINE: `/medicine`,
  INSURANCE_COMPANY: `/insurance-company`,
  INSURANCE_COMPANY_METADATA: `/block/insurance/metadata`,
  SCHEME: `/scheme`,
  IMPORT_SCHEME_FROM_EXCEL: `/formulary/import/scheme`,
  IMPORT_MEDICINE_FROM_EXCEL: `/formulary/import/medicine`,
  FORMULARY: `/formulary`,
  FORMULARY_SCHEME: `/formulary/scheme`,
  FORMULARY_BLOCK: `/formulary/block`,
  FORMULARY_MEDICINE: `/formulary/medicine`,
  UPDATE_STATUS_ADMIN_FORMULARY: `/formulary/status/update`,
  ADMIN_CATEGORY: `${ADMIN}/category`,
  UPDATE_ADMIN_CATEGORY: `${ADMIN}/category/update`,
  UPDATE_STATUS_ADMIN_CATEGORY: `${ADMIN}/category/status/update`,
  ADMIN_RCOMMENDATION_LIST: `${ADMIN}/recommendation/list`,
  UPDATE_STATUS_RECOMMENDATION: `${ADMIN}/recommendation/status/update`,
  RECOMMENDATION_DETAIL: `${ADMIN}/recommendation`,
  RECOMMENDATION_REPORT_LIST: `${ADMIN}/recommendation/report/list`,
  QUESTION_LIST: `${ADMIN}/question/list`,
  UPDATE_STATUS_QUESTION: `${ADMIN}/question/status/update`,
  QUESTION_DETAIL: `${ADMIN}/question`,
  ANSWER_POINT_LIST: `${ADMIN}/answer/point/list`,
  ANSWER_REPORT_LIST: `${ADMIN}/answer/report/list`,
  UPDATE_STATUS_ANSWER: `${ADMIN}/answer/status/update`,
  RULE: `${ADMIN}/rule`,
  NOTIFICATION_COUNT: `/notification/count`,
  NOTIFICATION_LIST: `/notification/list`,
  LOGIN: `/login`,
  LOGOUT: `/logout`,
  REFRESH_TOKEN: '/refresh/token',
  ADMIN_FORMULARY_ICD10_DIAGNOSIS_BLOCK_LIST:'/block/formulary/block',
  UPDATE_STATUS_ADMIN_FORMULARY_ICD10_DIAGNOSIS: `formulary/block/status/update`,
  COPY_FORMULARY_BLOCK_MEDCINES:`formulary/copyFormularyBlockMedicines`,
  COPY_ICD10BLOCK_MEDCINES:`block/copy-medicine`,
  COPY_EXISTING_FORMULARY:`formulary/copy`,
};

export default END_POINTS;
