import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ChipCus from '../../../components/chip/chip.cus';
import { Diagnosis } from '../../../types/manage.icd10.diagnosis.block';
import { ICD10_CODE_VIEW_NUMBER } from '../../../utils/static.data';
import IcdViewAll from './icd.view.all';

type IcdCodeProps = {
  data: Diagnosis[];
};

function IcdCode({ data }: IcdCodeProps) {
  const [openViewAll, setOpenViewAll] = useState(false);
  const handleCloseViewAll = () => {
    setOpenViewAll(false);
  };
  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ color: 'primary.main', mb: 1 }}>
          ICD10 Code(s)
        </Typography>
        <Box>
          {data.map((icd, index) => {
            if (index >= ICD10_CODE_VIEW_NUMBER) return null;
            return (
              <ChipCus
                sx={{ mt: 0.8, mr: 1, fontSize: 16 }}
                key={icd.DiagnosisCode}
                label={`${icd.DiagnosisCode}-(${icd.DiagnosisDescription})`}
                color="primary"
              />
            );
          })}
          {data.length > ICD10_CODE_VIEW_NUMBER && (
            <Typography
              sx={{
                display: 'inline',
                verticalAlign: 'bottom',
                textDecoration: 'underline',
                color: 'primary.main',
                cursor: 'pointer'
              }}
              onClick={() => setOpenViewAll(true)}>
              View more
            </Typography>
          )}
        </Box>
      </Box>
      <IcdViewAll data={data} open={openViewAll} handleClose={handleCloseViewAll} />
    </>
  );
}

export default IcdCode;
