import { Box, TableContainer, Table, TableHead, TableBody, Typography, SelectChangeEvent } from '@mui/material';
import TableCellWrapper from '../table/table.cell.wrapper';
import TableRowWrapper from '../table/table.row.wrapper';
import PaginateWrapper from '../../components/paginate-wrapper/paginate.wrapper';
import { POINTS_DETAIL_SELECT_MENU_ITEM } from '../../utils/static.data';
import SelectWrapper from '../form-ui/select-wrapper/select.wrapper';
import { AnswerPointItem } from '../../types/faq.types';
import { findlPointsListAction } from '../../utils/common.function';

type PointsDetailTableProps = {
  title: string;
  data: AnswerPointItem[];
  pageCount: number;
  rowPerPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  totalRecord: number;
  page: number;
  handleChangeShowSort: (event: SelectChangeEvent<unknown>) => void;
  itemOffset: number;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
};

function PointsDetailTable({
  title,
  data,
  pageCount,
  handlePageClick,
  page,
  totalRecord,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage,
  handleChangeShowSort,
  itemOffset
}: PointsDetailTableProps) {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1.5 }}>
        <Typography variant="h1" sx={{ fontSize: (theme) => theme.spacing(2) }}>
          {title}
        </Typography>
        <SelectWrapper value={itemOffset} onChange={handleChangeShowSort} menuItem={POINTS_DETAIL_SELECT_MENU_ITEM} />
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            py: 1.5,
            borderRadius: (theme) => theme.spacing(0.8)
          }}>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRowWrapper>
                  <TableCellWrapper>S.No.</TableCellWrapper>
                  <TableCellWrapper>Username</TableCellWrapper>
                  <TableCellWrapper>Type</TableCellWrapper>
                  <TableCellWrapper>Points</TableCellWrapper>
                  <TableCellWrapper>Action</TableCellWrapper>
                </TableRowWrapper>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  data.map((item, index: number) => {
                    return (
                      <TableRowWrapper key={index}>
                        <TableCellWrapper>{index + page}</TableCellWrapper>
                        <TableCellWrapper>{item.DoctorName ?? 'N/A'}</TableCellWrapper>
                        <TableCellWrapper>{item.TransactionType ?? 'N/A'}</TableCellWrapper>
                        <TableCellWrapper>{item.PointValue ?? 'N/A'}</TableCellWrapper>
                        <TableCellWrapper>
                          <Typography
                            color="primary"
                            sx={{
                              fontSize: (theme) => theme.spacing(1.4),
                              fontWeight: (theme) => theme.typography.fontWeightBold,
                              textDecoration: 'underline'
                            }}>
                            {findlPointsListAction(item.ActionTypeEnumId)}
                          </Typography>
                        </TableCellWrapper>
                      </TableRowWrapper>
                    );
                  })
                ) : (
                  <TableRowWrapper>
                    <TableCellWrapper colSpan={5}>No data found</TableCellWrapper>
                  </TableRowWrapper>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ mt: 2 }}>
          {data?.length > 0 && (
            <PaginateWrapper
              activePage={activePage}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              page={page}
              rowpPerPage={rowPerPage}
              totalRecord={totalRecord}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              // isShowPreAndNext
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default PointsDetailTable;
