import { Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteButton from '../../../components/button/delete.button';
import InactiveModal from '../../../components/modal-wrapper/inactive.modal';
import TitleName from '../../../components/title-name/title.name';
import { formatDateAndTime } from '../../../utils/common.function';
import ReactPlayer from 'react-player';
import { createStyles, makeStyles } from '@mui/styles';
import ConfirmButton from '../../../components/button/confirm.button';
import ReadMore from '../../../components/read-more/read.more';
import RatingProfile from '../../../components/rating-profile/rating.profile';
import { Answer } from '../../../types/faq.types';
import CONSTANTS from '../../../utils/constants';
import FaqPointList from './faq.point.list';
import FaqReportList from './faq.report.list';
import { updateStatusAnswer } from '../action';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AnswerCardProps = {
  item: Answer;
};
const useStyles = makeStyles(() =>
  createStyles({
    playerWrapper: {
      position: 'relative',
      width: '500px',
      aspectRatio: '16/9'
    },
    reactPlayer: {
      position: 'absolute',
      top: '0',
      left: '0'
    }
  })
);

function AnswerCard({ item }: AnswerCardProps) {
  const {
    AnswerId,
    DoctorImage,
    DoctorName,
    DoctorRating,
    CreatedAt,
    DoctorProfileViews,
    ReportCount,
    Text,
    IsActive,
    AudioURL,
    VideoURL,
    TotalDislikes,
    TotalLikes,
    DoctorPhoneCode,
    DoctorPhoneNumber
  } = item;
  const classes = useStyles();
  const { faqId } = useParams();
  const [openInactive, setOpenInactive] = useState(false);
  const dispatch = useDispatch();

  const handleCloseInactive = () => {
    setOpenInactive(false);
  };

  const handleOpenInactive = () => {
    setOpenInactive(true);
  };

  const handleConfirmInactive = () => {
    if (AnswerId && faqId) {
      const payload = {
        AnswerId: Number(AnswerId),
        IsActive: IsActive === 0 ? true : false
      };
      dispatch(updateStatusAnswer(payload, faqId, handleCloseInactive));
    }
  };

  return (
    <>
      <Box sx={{ my: 1.5 }}>
        <Box>
          <Accordion>
            <AccordionSummary
              sx={{
                px: { xs: 1, sm: 2 },
                backgroundColor: (theme) => (IsActive === 0 ? 'var(--inactive)' : theme.palette.common.white),
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: { xs: 'flex-start', sm: 'center' }
                },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  alignSelf: 'flex-start',
                  mt: 1
                }
              }}
              expandIcon={<ExpandMoreIcon color="primary" />}>
              <Grid container spacing={0.5}>
                <Grid item xs={12} sm={6} md={4}>
                  <RatingProfile
                    title="Answered by"
                    name={DoctorName}
                    img={DoctorImage}
                    rating={DoctorRating}
                    view={DoctorProfileViews}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TitleName title="Created at" name={formatDateAndTime(CreatedAt)} />
                </Grid>
                <Grid item xs={4} sm={4} md={1}>
                  <TitleName title="Likes" name={TotalLikes} />
                </Grid>
                <Grid item xs={4} sm={4} md={1.5}>
                  <TitleName title="Dislikes" name={TotalDislikes} />
                </Grid>
                <Grid item xs={4} sm={4} md={1.5}>
                  <TitleName title="Report" name={ReportCount} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{ mt: 0.5 }}>
                  <TitleName
                    title="Phone Number"
                    name={`+${DoctorPhoneCode ? DoctorPhoneCode : ''}${DoctorPhoneNumber ? DoctorPhoneNumber : ''}`}
                  />
                </Grid>
              </Grid>
              {IsActive === 1 && (
                <DeleteButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenInactive();
                  }}
                  sx={{
                    p: 1,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.secondary.light
                    }
                  }}
                />
              )}
              {IsActive === 0 && (
                <ConfirmButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenInactive();
                  }}
                  sx={{
                    p: 1,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.secondary.light
                    }
                  }}
                />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: { xs: 1, sm: 2 } }}>
              {Text && (
                <Box mb={1.5}>
                  <Typography variant="h1" sx={{ fontSize: (theme) => theme.spacing(1.6) }}>
                    Answer
                  </Typography>
                  <ReadMore showString={300}>{Text?.length > 0 ? Text : 'N/A'}</ReadMore>
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
                {AudioURL && (
                  <Box className={classes.playerWrapper} sx={{ height: (theme) => AudioURL && theme.spacing(8) }}>
                    <ReactPlayer
                      className={classes.reactPlayer}
                      url={CONSTANTS.AUDIO_BASE_URL + AudioURL}
                      width="100%"
                      height="100%"
                      controls
                    />
                  </Box>
                )}
                {VideoURL && (
                  <Box className={classes.playerWrapper}>
                    <ReactPlayer
                      className={classes.reactPlayer}
                      url={CONSTANTS.VIDEO_BASE_URL + VideoURL}
                      width="100%"
                      height="100%"
                      controls
                    />
                  </Box>
                )}
              </Box>
              <Divider sx={{ mb: 1 }} />
              <Box>
                <FaqPointList answerId={AnswerId} />
              </Box>
              <Divider sx={{ mb: 1 }} />
              <Box>
                <FaqReportList answerId={AnswerId} />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={IsActive === 0 ? 'Activate' : 'Inactivate'}
        paragraph={
          <>
            Are you sure you want to {IsActive === 0 ? 'activate' : 'inactivate'}
            <br /> the selected Answer?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
}

export default AnswerCard;
