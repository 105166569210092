import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { InsuranceCompany } from '../../../types/insurance.company';
import InputField from '../textfield-wrapper/input.field';
import { useEffect, useState } from 'react';
import { getInsuranceCompany } from '../../../global-action/global.action';
import showAlert from '../../../utils/alert';
import { useField, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { ReducersModal } from '../../../modal';
import ACTION_NAME from '../../../utils/action.name';

type AutocomWrapProps = {
  name: string;
  label: string;
};

function AutocomFormularyWrap({ name, label }: AutocomWrapProps) {
  const dispatch = useDispatch();
  const [insurance, setInsurance] = useState([] as InsuranceCompany[]);
  const [value, setValue] = useState({} as InsuranceCompany);
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { type, formularyId, insuranceCompany } = useSelector((state: ReducersModal) => state.addFormularyReducer);

  useEffect(() => {
    if (!(type === 'edit' && formularyId)) return;
    setValue({
      InsuranceCompanyId: insuranceCompany.InsuranceCompanyId,
      Name: insuranceCompany.Name,
      LogoURL: insuranceCompany.LogoURL
    });
  }, [type, formularyId]);

  useEffect(() => {
    getInsuranceCompany()
      .then((res) => {
        setInsurance(res.data?.Data?.InsuranceCompanies);
      })
      .catch(() => {
        showAlert(2);
      });
  }, []);

  return (
    <Autocomplete
      disabled={type === 'edit'}
      value={Object.keys(value).length === 0 ? null : value}
      onChange={(_e, newValue) => {
        if (!newValue) return;
        setValue(newValue);
        setFieldValue(name, newValue);
        dispatch({
          type: ACTION_NAME.ADD_FORMULARY,
          payload: {
            insuranceCompany: newValue
          }
        });
      }}
      size="small"
      options={insurance}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.Name === value.Name}
      getOptionLabel={(option) => option.Name}
      renderOption={(props, option) => {
        return (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.Name}
          </Box>
        );
      }}
      renderInput={(params) => (
        <InputField
          name={field.name}
          error={!!(meta.touched && meta.error)}
          helperText={meta?.touched && meta.error}
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
}

export default AutocomFormularyWrap;
