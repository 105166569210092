import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';

function DeleteButton({ ...otherProps }: IconButtonProps) {
  return (
    <IconButton color="error" sx={{ p: 0.25 }} {...otherProps}>
      <DoDisturbOutlinedIcon sx={{ fontSize: { xs: 'medium', sm: 'large' } }} />
    </IconButton>
  );
}

export default DeleteButton;
