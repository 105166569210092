import { Autocomplete, createFilterOptions } from '@mui/material';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputField from '../../../components/form-ui/textfield-wrapper/input.field';
import useDebounce from '../../../hooks/use.debounce';
import { ReducersModal } from '../../../modal';
import { Block } from '../../../types/manage.scheme.specific.formulary';
import showAlert from '../../../utils/alert';
import { getSchemeIcdList } from '../action';

type AutocomIcdProps<T> = {
  label: string;
  scheme: T;
  setScheme: Dispatch<SetStateAction<T>>;
};

function AutocomIcd({ label, scheme, setScheme }: AutocomIcdProps<Block[]>) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Block[]>([]);
  const [inputValue, setInputValue] = useState('');
  const debounceValue = useDebounce(inputValue, 500);
  const { InsuranceCompanyId, FormularyId } = useSelector((state: ReducersModal) => state.schemeDetailReducer);

  useEffect(() => {
    let active = true;

    if (!debounceValue) {
      return undefined;
    }
    if (InsuranceCompanyId === 0) return;
    (async () => {
      try {
        const res = await getSchemeIcdList({
          page: 1,
          limit: 20,
          search: debounceValue,
          insuranceCompanyId: InsuranceCompanyId,
          formularyId: FormularyId
        });
        if (active) {
          const newBlock: Block[] = [];
          for (const ele of res.data.Data.Blocks) {
            newBlock.push({
              ...ele,
              inputV: debounceValue,
            });
          }
          setOptions(newBlock);
          // setOptions(res.data?.Data.Blocks);
        }
      } catch (_err) {
        showAlert(2);
      }
    })();

    return () => {
      active = false;
    };
  }, [debounceValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filterOptions = createFilterOptions({
    stringify: (option:any) => `${option.Name} ${option?.api} ${option?.inputV}`
  });

  return (
    <Autocomplete
      filterOptions={filterOptions}
      disabled={InsuranceCompanyId === 0}
      size="small"
      onChange={(_e, newValue) => {
        if (newValue) {
          const findScheme = scheme.find((ele) => ele.Name === newValue.Name);
          if (findScheme) return;
          setScheme([...scheme, newValue]);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'reset') setInputValue('');
        else setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.Name}
      options={options}
      renderInput={(params) => (
        <InputField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
}

export default AutocomIcd;
