import { SortByQuesListQueryParam, SortOrderQuesListQueryParam } from '../types/faq.types';
import {
  Block,
  IDiagnosis,
  SortByBlockListQueryParam,
  SortOrderBlockListQueryParam,
  IICDDetails,
  IIcdMedicine,
  IMedicines,
  Diagnosis
} from '../types/manage.icd10.diagnosis.block';
import { Formulary, Scheme } from '../types/manage.scheme.specific.formulary';
import FORM_FIELD from '../validations/form-field';

export class ManageIcd10DiagnosisBlocksModal {
  TotalRecords = 0;
  Page = 1;
  Blocks: Block[] = [];
  ItemOffset = 10;
  SortBy: SortByBlockListQueryParam = undefined;
  SortOrder: SortOrderBlockListQueryParam = undefined;
  InsuranceCompanyId = 0;
  StatusId = '';
  BlockId = 0;
  FromDate: string | null = '';
  ToDate: string | null = '';
  initialValueSortDate = FORM_FIELD.icd10DiagnosisFilterListing;
}

export class AddBlock {
  page: 'list' | 'detail' = 'list';
  openBlock = false;
  blockId: number | null = null;
  type: 'add' | 'edit' = 'add';
  blockName = '';
  insuranceCompany = {
    InsuranceCompanyId: 0,
    Name: '',
    LogoURL: null
  };
  icd10: IDiagnosis[] = [];
}

export class AddFormulary {
  page: 'list' | 'detail' = 'list';
  openFormulary = false;
  formularyId: number | null = null;
  type: 'add' | 'edit' = 'add';
  formularyName = '';
  insuranceCompany = {
    InsuranceCompanyId: 0,
    Name: '',
    LogoURL: null
  };
}

export class IcdDetail implements IICDDetails {
  BlockId = 0;
  InsuranceCompanyId = 0;
  InsuranceCompanyName = '';
  Name = '';
  IsActive = 1;
  CreatedAt = '';
  CreatedBy = 1;
  ModifiedAt = null;
  ModifiedBy = null;
  Diagnosis = [];
  MedicineCount = 0;
  InsuranceCompanyLogoURL = '';
}

// medicine
export class IcdMedicine implements IIcdMedicine {
  TotalRecords = 0;
  Page = 1;
  Medicines :IMedicines[]= [];
  ItemOffset = 10;
  SortBy: SortByQuesListQueryParam = '1';
  SortOrder: SortOrderQuesListQueryParam = 'DESC';
  MedicineId:number[]=[]
}

export class CopyBlock {
  blockId = 0;
  copyBlockName = '';
  copyInsuranceCompany = {
    InsuranceCompanyId: 0,
    Name: '',
    LogoURL: null
  };
  copyIcd10: Diagnosis[] = [];
  copyMedicine: IMedicines[] = [];
  orderOfTier: IMedicines[] = [];
  isDisabled = false;
}

export class AssignSchemeToFormulary {
  FormularyId: number | null = null;
  InsuranceCompanyId = 0;
  Scheme:{Name:string}[]=[];
  isEdit=false;
  ImportFile:File|null=null;
  Type='Add';
  SchemeFromAPI:Scheme[]=[];
  SchemeNameList:string[]=[];

}
export class CopySchemeSpecificFormulary {
  FromNewFormulary=false;
  SourceFormulary=0;
  DestinationFormularyId:number[]=[];
  DestinationInsuranceId:number[]=[];
  DestinationFormulary:Formulary[]=[];
  FormularyToRemove:number[]=[];
  FromAssignScheme=false
}

export class CopySchemeSpecificFormularyBlock{
  FromBlockId:number[]=[];
  ToBlocks:Block[]=[]
  FromBlock=0

}

export class CopyICD10Block{
  FromBlock:Block|null=null
  ToBlock:Block[]=[];
  FromBlockIds:number[]=[]
}