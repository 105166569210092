import { FormikHelpers } from 'formik';

export type PointFormReuseProps<T> = {
  initialValues: T;
  validationSchema: unknown | (() => unknown);
  onSubmit: (arg1: T, arg2: FormikHelpers<T>) => void;
  heading: string;
  name: string;
  title: string;
};

export type UpdateRulePayload = {
  RuleId: number;
  RuleEnumId: RuleEnum.POINTS_PER_LIKE | RuleEnum.POINTS_PER_DISLIKE | RuleEnum.POINTS_PER_ANSWER;
  RuleValue: number | string;
};

export enum RuleEnum {
  POINTS_PER_LIKE = 1,
  POINTS_PER_DISLIKE = 2,
  POINTS_PER_ANSWER = 3
}

export interface UpdateRuleDTO {
  RuleId: number;
  RuleEnumId: number;
  RuleValue: number;
  IsActive: boolean;
  CreatedAt: string;
  CreatedBy: number;
  ModifiedAt: string;
  ModifiedBy: number;
}
