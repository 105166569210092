import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import FilterButton from '../../components/button/filter.button';
import SelectWrapper from '../../components/form-ui/select-wrapper/select.wrapper';
import Icd10DiagnosisBlockFilterModal from '../../components/modal-wrapper/icd10.diagnosis.block.filter.modal';
import InactiveModal from '../../components/modal-wrapper/inactive.modal';
import SearchTextfield from '../../components/search-textfield/search.textfield';
import useDebounce from '../../hooks/use.debounce';
import { ReducersModal } from '../../modal';
import { Formulary } from '../../types/manage.scheme.specific.formulary';
import ACTION_NAME from '../../utils/action.name';
import {
  calculatePage,
  calculateRowPerPage,
  checkSchemeSpecificFilterApplyOrNot,
  formatDateAndTimeForfilter
} from '../../utils/common.function';
import { SELECT_MENU_ITEM } from '../../utils/static.data';
import FORM_FIELD from '../../validations/form-field';
import {
  getSchemeSpecificFormularyList,
  manageSchemeSpecificFormularyListAction,
  resetCopyFromulary,
  resetFormulary,
  updateStatusFormularyApi
} from './action';
import AddFormulary from './add.formaulary';
import CopySchemeSpecificFormulary from './scheme-detail/copy-scheme-specific-formulary/copy-scheme-specific-modal';
import SchemeSpecificFormularyTable from './scheme.specific.formulary.table';

function SchemeSpecificFormulary() {
  const filterRef = useRef<HTMLElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [openFilter, setOpenFilter] = useState(false);
  const debouncedValue = useDebounce(inputValue.trim(), 1000);
  const [openInactive, setOpenInactive] = useState(false);
  const [openCopyScheme, setOpenCopyScheme] = useState(false)
  const [updateStatusSchemeSpecificFormulary, setUpdateStatusSchemeSpecificFormulary] = useState({} as Formulary);
  const dispatch = useDispatch();
  const {
    Page,
    Formulary,
    TotalRecords,
    ItemOffset,
    SortBy,
    SortOrder,
    InsuranceCompanyId,
    StatusId,
    FromDate,
    ToDate,
    initialValueSortDate
  } = useSelector((state: ReducersModal) => state.manageSchemeSpecificFormularyReducer);
  const { openFormulary } = useSelector((state: ReducersModal) => state.addFormularyReducer);
  const [pageCount, setPageCount] = useState(Page);
  useEffect(() => {
    dispatch(
      getSchemeSpecificFormularyList({
        page: Page,
        limit: ItemOffset,
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(debouncedValue && debouncedValue !== '' && { filterValue: debouncedValue }),
        ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
        ...(StatusId && { statusId: StatusId }),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      })
    );
  }, [dispatch, Page, ItemOffset, debouncedValue, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  const handleOpenFilter = () => {
    setInputValue('');
    setOpenFilter(true);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };
  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    dispatch(manageSchemeSpecificFormularyListAction({ Page: 1, ItemOffset: Number(itemOffset) }));
  };
  const handleOpenInactive = (item: Formulary) => {
    setOpenInactive(true);
    setUpdateStatusSchemeSpecificFormulary(item);
  };
  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(manageSchemeSpecificFormularyListAction({ Page: selectedPage + 1 }));
  };
  const handlePrevious = () => {
    dispatch(manageSchemeSpecificFormularyListAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(manageSchemeSpecificFormularyListAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };
  const handleOpenFormulary = () => {
    dispatch({
      type: ACTION_NAME.ADD_FORMULARY,
      payload: {
        openFormulary: true
      }
    });
  };
  const handleCloseFormulary = () => {
    dispatch({
      type: ACTION_NAME.ADD_FORMULARY,
      payload: {
        openFormulary: false
      }
    });
    dispatch(resetFormulary())
  };
  const handleOpenEditBlock = (
    type: string,
    { FormularyId, Name, InsuranceCompanyName, InsuranceCompanyId }: Formulary
  ) => {
    if (!FormularyId) return;
    dispatch({
      type: ACTION_NAME.ADD_FORMULARY,
      payload: {
        page: 'list',
        formularyId: FormularyId,
        type: type,
        formularyName: Name,
        insuranceCompany: {
          InsuranceCompanyId: InsuranceCompanyId,
          Name: InsuranceCompanyName,
          LogoURL: null
        }
      }
    });
    handleOpenFormulary();
  };
  const handleCloseInactive = () => {
    setOpenInactive(false);
  };

  const handleConfirmInactive = useCallback(() => {
    const payload = {
      FormularyId: updateStatusSchemeSpecificFormulary.FormularyId,
      IsActive: updateStatusSchemeSpecificFormulary.IsActive === 0 ? true : false
    };
    dispatch(updateStatusFormularyApi(payload, handleCloseInactive, 'list'));
  }, [updateStatusSchemeSpecificFormulary]);

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const handleCloseCopyScheme = useCallback(()=>{
    dispatch(resetCopyFromulary())
    setOpenCopyScheme(false)},[])

  const handleOpenCopyBlock  = ()=>{
    setOpenCopyScheme(true)
  }

  const handleFilterSubmit = (
    { fromDate, toDate, insuranceCompanyId, statusId }: typeof FORM_FIELD.schemeSpecificFilterListing,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.icd10DiagnosisFilterListing>
  ) => {
    let statusVal = statusId;
    if (statusId === 'all') statusVal = '';
    if (fromDate && toDate) {
      dispatch(
        manageSchemeSpecificFormularyListAction({
          FromDate: formatDateAndTimeForfilter(fromDate),
          ToDate: formatDateAndTimeForfilter(toDate),
          initialValueSortDate: {
            fromDate,
            toDate,
            insuranceCompanyId,
            statusId
          }
        })
      );
    }
    dispatch(
      manageSchemeSpecificFormularyListAction({
        InsuranceCompanyId: insuranceCompanyId,
        StatusId: statusVal,
        initialValueSortDate: {
          fromDate,
          toDate,
          insuranceCompanyId,
          statusId
        }
      })
    );

    handleCloseFilter();
    setSubmitting(false);
  };
  const handleFilterReset = () => {
    dispatch(
      manageSchemeSpecificFormularyListAction({
        InsuranceCompanyId: 0,
        StatusId: '',
        FromDate: null,
        ToDate: null,
        initialValueSortDate: {
          fromDate: null,
          toDate: null,
          insuranceCompanyId: 0,
          statusId: 'all'
        }
      })
    );
  };
  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography variant="h1">Scheme Specific Formulary (SSF)</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <ButtonWrapper
              variant="contained"
              color="primary"
              sx={{ mr: 1 }}
              onClick={() => {
                dispatch(resetFormulary());
                handleOpenFormulary();
              }}>
              Add Formulary
            </ButtonWrapper>
            <Box ref={filterRef}>
              <FilterButton
                onClick={handleOpenFilter}
                isFilterApply={checkSchemeSpecificFilterApplyOrNot(initialValueSortDate)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: { xs: 'initial', sm: 'center' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1 }
          }}>
          <Box sx={{ flex: 1 }}>
            <SearchTextfield
              placeholder={'Search by Formulary name'}
              inputValue={inputValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              name="searchInput"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <Box sx={{ mr: 2 }}>
              <SelectWrapper value={ItemOffset} onChange={handleChangeShowSort} menuItem={SELECT_MENU_ITEM} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <SchemeSpecificFormularyTable
            data={Formulary}
            handleOpenInactive={handleOpenInactive}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            page={calculatePage(Page, ItemOffset)}
            totalRecord={TotalRecords}
            rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            activePage={Page}
            handleOpenEditBlock={handleOpenEditBlock}
            handleOpenCopyBlock={handleOpenCopyBlock}
          />
        </Box>
      </Box>
      <AddFormulary open={openFormulary} handleClose={handleCloseFormulary} />
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusSchemeSpecificFormulary.IsActive === 0 ? 'Activate' : 'Suspend'}
        paragraph={
          <>
            Are you sure you want to {updateStatusSchemeSpecificFormulary.IsActive === 0 ? 'activate' : 'suspend'}
            <br /> the Formulary ?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
      <Icd10DiagnosisBlockFilterModal
        open={openFilter}
        handleClose={handleCloseFilter}
        handleFilterSubmit={handleFilterSubmit}
        initialValues={initialValueSortDate}
        handleFilterReset={handleFilterReset}
        filterButtonPosition={filterRef.current?.getBoundingClientRect()}
      />
      <CopySchemeSpecificFormulary
        open={openCopyScheme}
        handleClose={handleCloseCopyScheme}
      />
    </>
  );
}

export default SchemeSpecificFormulary;
