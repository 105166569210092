import ROUTES_NAME from "./routes.name"

export const makeBreadcrumb = (pathname:string, blockName:string, medicineName="")=>{
    const breadcrumbArray:string[]= []
    // eslint-disable-next-line no-console
    console.log(pathname.includes("detail"), blockName)
    if(pathname.includes(ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL)) {
        breadcrumbArray.push('Scheme Specific Formulary (SSF)')
        if(blockName) breadcrumbArray.push(blockName)
        if(medicineName) breadcrumbArray.push(medicineName)
    }
    return breadcrumbArray
}
