import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { makeStyles, createStyles } from '@mui/styles';
import { IconButton, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

type PaginateWrapperProps = {
  page: number;
  rowpPerPage: number;
  totalRecord: number;
  isShowPreAndNext?: boolean;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
} & ReactPaginateProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerClassName: () => {
      return {
        display: 'flex',
        listStyle: 'none',
        alignItems: 'center',
        justifyContent: 'center'
        // [`& li:nth-child(${Number(active) + 1})`]: {
        //   "& a": {
        //     color: `${theme.palette.common.black} !important`
        //   }
        // }
      };
    },
    previousLinkClassName: {},
    pageLinkClassName: {
      font: `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.4)} Roboto Sans, sans-serif`,
      color: 'var(--pagenation-color)',
      padding: theme.spacing(0.5, 1),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    nextLinkClassName: {},
    activeLinkClassName: {
      color: theme.palette.common.black
    }
  })
);

function PaginateWrapper({
  page,
  rowpPerPage,
  totalRecord,
  isShowPreAndNext,
  handlePrevious,
  handleNext,
  activePage,
  ...otherProps
}: PaginateWrapperProps) {
  const classes = useStyles(activePage);
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: { xs: 1 } }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        {isShowPreAndNext && (
          <IconButton onClick={handlePrevious}>
            <KeyboardDoubleArrowLeftOutlinedIcon />
          </IconButton>
        )}
        <ReactPaginate
          {...otherProps}
          previousLabel={
            <IconButton>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
          }
          nextLabel={
            <IconButton>
              <KeyboardArrowRightOutlinedIcon color="primary" />
            </IconButton>
          }
          containerClassName={classes.containerClassName}
          previousLinkClassName={classes.previousLinkClassName}
          nextLinkClassName={classes.nextLinkClassName}
          pageLinkClassName={classes.pageLinkClassName}
          activeLinkClassName={classes.activeLinkClassName}
        />
        {isShowPreAndNext && (
          <IconButton onClick={handleNext}>
            <KeyboardDoubleArrowRightOutlinedIcon color="primary" />
          </IconButton>
        )}
      </Box>
      <Box>
        <Typography sx={{ fontSize: (theme) => theme.spacing(1.4), color: 'var(--table-head)', ml: 1.5 }}>
          Showing {page} - {rowpPerPage} of {totalRecord}
        </Typography>
      </Box>
    </Box>
  );
}

export default PaginateWrapper;
