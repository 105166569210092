import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../../components/button/button.wrapper';
import ChipCus from '../../../components/chip/chip.cus';
import ModalWrapper from '../../../components/modal-wrapper/modal.wrapper';
import { ReducersModal } from '../../../modal';
import { globalLoaderEnd, globalLoaderStart } from '../../../reducer/global.reducer';
import { Block } from '../../../types/manage.scheme.specific.formulary';
import showAlert from '../../../utils/alert';
import { addIcdBlock, schemeDetail } from '../action';
import AutocomIcd from './autocom.icd';

type AddIcdBlockProps = {
  open: boolean;
  handleClose: () => void;
};

function AddIcdBlock({ open, handleClose }: AddIcdBlockProps) {
  const [scheme, setScheme] = useState<Block[]>([]);
  const dispatch = useDispatch();
  const { FormularyId } = useSelector((state: ReducersModal) => state.schemeDetailReducer);
  const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);

  const validateSchemeFn = (value: Block[]): boolean => {
    if (value.length > 0) return false;
    else return true;
  };

  const handleAddIcdBlock = async () => {
    const validateScheme = validateSchemeFn(scheme);
    if (validateScheme) {
      showAlert(2, 'Please select minimum one Block');
      return;
    }
    const payload = {
      FormularyId,
      Blocks: scheme.map((item) => item.BlockId)
    };
    try {
      dispatch(globalLoaderStart());
      await addIcdBlock(payload);
      batch(() => {
        dispatch(schemeDetail(String(FormularyId)));
        dispatch(globalLoaderEnd());
      });

      handleCloseModal();
    } catch (_err) {
      showAlert(2);
      dispatch(globalLoaderEnd());
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setScheme([]);
  };

  const handleDeleteScheme = (sch: Block) => {
    const filterSch = scheme.filter((item) => item.BlockId !== sch.BlockId);
    setScheme(filterSch);
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleCloseModal}
      sx={{
        width: { xs: 300, sm: 650 },
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
        <Typography variant="h1" sx={{ color: 'common.white' }}>
          Add ICD10 Code Blocks
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: 6 }}>
          <AutocomIcd label="Select ICD10  Blocks" scheme={scheme} setScheme={setScheme} />
          {scheme?.length > 0 && (
            <Box>
              {scheme.map((item) => {
                return (
                  <ChipCus
                    sx={{ mt: 0.8, mr: 0.5 }}
                    key={item.Name}
                    label={item.Name}
                    color="primary"
                    onDelete={() => handleDeleteScheme(item)}
                  />
                );
              })}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <ButtonWrapper
            disabled={isLoading}
            variant="outlined"
            sx={{
              color: (theme) => theme.palette.error.light,
              flexBasis: '48%',
              borderColor: (theme) => theme.palette.error.light,
              '&:hover': {
                borderColor: (theme) => theme.palette.error.light
              }
            }}
            onClick={handleClose}>
            Cancel
          </ButtonWrapper>
          <ButtonWrapper
            disabled={isLoading}
            onClick={handleAddIcdBlock}
            variant="contained"
            color="primary"
            sx={{ flexBasis: '48%' }}>
            {isLoading ? 'Loading...' : 'Add'}
          </ButtonWrapper>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

export default AddIcdBlock;
