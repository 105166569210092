import { DoctorRecommendationPayload } from './doctor.recommendation.types';
import { CategoriesTypeApiResponse } from './manage.category.types';
import { AxiosResponse } from 'axios';

export interface ActionName {
  LOADING?: string;
  GET_MANAGE_CATEGORY?: string;
  GET_DOCTOR_RECOMMENDATION_LIST?: string;
}

export interface ActionType {
  type: ActionName;
  payload: GlobalPayload;
}

export type GlobalPayload = DoctorRecommendationPayload | CategoriesTypeApiResponse;

// api interface
export interface GlobalGetApiResponse<T> {
  Data: T;
  Errors: unknown[];
  IsSuccess: boolean;
  Message: string;
}

export type AxiosResponseGlobal<T> = AxiosResponse<GlobalGetApiResponse<T>>;

export type GlobalApiResponseSuccessFunction = (response: AxiosResponse) => void;
export type GlobalApiResponseErrorFunction = (response: AxiosResponse<GlobalGetApiResponse<unknown>>) => void;

export type ReportListType = 'answersReport' | 'recommendationReport';

// enum value
export enum DoctorPointLogActionTypeEnum {
  CREATE_ANSWER = 1,
  LIKE_ANSWER = 2,
  DISLIKE_ANSWER = 3
}

export enum RecommendationReportReasonEnum {
  INCORRECT_RECOMMENDATION = 1,
  BAD_EXPERIENCE_WHEN_FOLLOWED = 2,
  RECOMMENDATION_IS_NOT_SUITABLE = 3,
  OTHER = 4
}

export enum AnswerReportReasonEnum {
  INCORRECT_ANSWER = 1,
  BAD_EXPERIENCE_WHEN_FOLLOWED = 2,
  ANSWER_IS_NOT_SUITABLE = 3,
  OTHER = 4
}

export type CheckReportReasone<Y, X, Z> = (r: Y) => X | Z;
export type SingleGenerics<T> = (arg: T) => T;

export type JwtDecodeT = {
  exp: number;
  iat: number;
  userId: string;
  userRoleId: string;
};

export enum Role {
  Blog = '25',
  SuperAdmin = '1',
  Admin = '2',
  CustomerCare = '7'
}

export type TRoleId = `${Role}`;

export enum TableSort {
  CreatedAt = '1',
  UpdatedAt = '2',
  BlockName = '3'
}
export type TSort = `${TableSort}`;
