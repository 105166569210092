import { AddFormulary, ReducersModal } from '../../modal';
import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import { AppDispatch, AppDispatchFunction } from '../../store/store';
import {
  AddFormularyPayload,
  AssignFormularytoSchemeListPayload,
  AssignFormularytoSchemePayloadRedux,
  CoopyFormularyBlockPayload,
  CopyExistingFormularyPayload,
  Formulary,
  FormularyIcd10DiagnosisBlocksListPayload,
  IIcdSchemeList,
  ISchemeIcdMedicineApiPayload,
  PaloadFormularySchemeList,
  SchemeSpecificFormularyICD10PayloadRedux,
  SchemeSpecificFormularyListPayload,
  SchemeSpecificFormularyPayloadRedux,
  UpdateFormularyPayload,
  UpdateStatusFormulary,
  UpdateStatusFormularyICD10Block
} from '../../types/manage.scheme.specific.formulary';

import ACTION_NAME from '../../utils/action.name';
import showAlert from '../../utils/alert';
import { checkOnline } from '../../utils/common.function';
import CONSTANTS from '../../utils/constants';
import END_POINTS from '../../utils/end.points';
import { AxiosResponseGlobal } from '../../types/global.types';
import { Block, IcdMedicineAllListResponse } from '../../types/manage.icd10.diagnosis.block';
import { AssignSchemeToFormulary, CopySchemeSpecificFormulary, CopySchemeSpecificFormularyBlock } from '../../modal/manage.icd10.diagnosis';

export const getSchemeSpecificFormularyList = ({
  page,
  limit,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive,
  insuranceCompanyId,
  statusId,
  fromDate,
  toDate
}: SchemeSpecificFormularyListPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.FORMULARY}?Page=${page}&Limit=${limit}${filterValue ? `&Search=${filterValue}` : ''}${
      sortBy ? `&SortBy=${sortBy}` : ''
    }${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      insuranceCompanyId ? `&InsuranceCompanyId=${insuranceCompanyId}` : ''
    }${statusId ? `&IsActive=${statusId === '1' ? true : false}` : ''}${fromDate ? `&FromDate=${fromDate}` : ''}${
      toDate ? `&ToDate=${toDate}` : ''
    }`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(manageSchemeSpecificFormularyListAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const updateStatusFormularyApi = (
  payload: UpdateStatusFormulary,
  handleCloseInactive: () => void,
  type: 'detail' | 'list'
) => {
  return (dispatch: AppDispatchFunction, getState: () => ReducersModal) => {
    const { Page, ItemOffset, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate } =
      getState().manageSchemeSpecificFormularyReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.UPDATE_STATUS_ADMIN_FORMULARY, params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          if (type === 'list') {
            dispatch(
              getSchemeSpecificFormularyList({
                page: Page,
                limit: ItemOffset,
                sortBy: SortBy,
                sortOrder: SortOrder,
                ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
                ...(StatusId && { statusId: StatusId }),
                ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate }),
                handleCloseInactive
              })
            );
          }
          if (type === 'detail') {
            dispatch(schemeDetail(String(payload.FormularyId), handleCloseInactive));
          }
          showAlert(1, data?.Message);
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const schemeDetail = (schemeId: string, handleCloseInactive?: () => void) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.FORMULARY}/${schemeId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(schemeDetailAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const getFormularySchemeList = ({ page, limit, search, formularyId, type }: PaloadFormularySchemeList) => {
  return (dispatch: AppDispatch, getState: () => ReducersModal) => {
    const formularyDetail = getState().schemeDetailReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.FORMULARY_SCHEME}?Page=${page}&Limit=${limit}${
      search ? `&Search=${search}` : ''
    }&FormularyId=${formularyId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(
            schemeDetailAction({ ...formularyDetail, Scheme: { ...data?.Data, Limit: formularyDetail.Scheme.Limit } })
          );
          if (type === 'delete') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(schemeDetail(String(formularyId)));
          }
        }
        if (type !== 'delete') dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const getSchemeList = ({
  page,
  limit,
  search,
  insuranceCompanyId,
  formularyId
}: {
  page: number;
  limit: number;
  search: string;
  insuranceCompanyId: number;
  formularyId: number;
}) => {
  return new Promise<AxiosResponseGlobal<{ Schemes: { Name: string }[] }>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.SCHEME}?Page=${page}&Limit=${limit}&Search=${search}&InsuranceCompanyId=${insuranceCompanyId}&FormularyId=${formularyId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addScheme = (payload: { FormularyId: number; Schemes: string[] }) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.FORMULARY_SCHEME, payload)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const deleteScheme = (formularyId: number, schemeName: string) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.FORMULARY_SCHEME}/${formularyId}/${schemeName}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.delete(params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const getSchemeIcdList = ({
  page,
  limit,
  search,
  insuranceCompanyId,
  formularyId
}: {
  page: number;
  limit: number;
  search: string;
  insuranceCompanyId: number;
  formularyId: number;
}) => {
  return new Promise<AxiosResponseGlobal<IIcdSchemeList>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST}?Page=${page}&Limit=${limit}&Search=${search}&InsuranceCompanyId=${insuranceCompanyId}&FormularyId=${formularyId}&IsActive=true
`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addIcdBlock = (payload: { FormularyId: number; Blocks: number[] }) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.FORMULARY_BLOCK, payload)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const deleteIcdBlock = (formularyId: number, schemeName: number) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.FORMULARY_BLOCK}/${formularyId}/${schemeName}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.delete(params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const getSchemeIcdMedicineList = ({
  blockId,
  formularyId,
  page,
  limit,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive
}: ISchemeIcdMedicineApiPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.FORMULARY_MEDICINE}?BlockId=${blockId}&Page=${page}&Limit=${limit}&FormularyId=${formularyId}${
      filterValue ? `&Search=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(getSchemeIcdMedicineAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const updateSchemeIcdMedicine = ({ formulary, medicineId, blockId }: { formulary: number; medicineId: number, blockId?: number }) => {
  return new Promise<AxiosResponseGlobal<null>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.FORMULARY_MEDICINE}/${formulary}/${blockId}/${medicineId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.delete(params)
      .then((res: AxiosResponseGlobal<null>) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add medicine api
export const addScheneIcdMedicineApi = (payload: {
  FormularyId: number;
  Medicines: {
    MedicineId: number;
    OrderOfTier: number;
  }[];
}) => {
  return new Promise<AxiosResponseGlobal<null>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.FORMULARY_MEDICINE}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(params, payload)
      .then((res: AxiosResponseGlobal<null>) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllSchemeIcdMedicine = ({
  page,
  limit,
  search,
  insuranceCompanyId,
  formulayId
}: {
  page: number;
  limit: number;
  search: string;
  insuranceCompanyId: number;
  formulayId: number;
}) => {
  return new Promise<AxiosResponseGlobal<IcdMedicineAllListResponse>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.GET_ALL_MEDICINE}?Page=${page}&Limit=${limit}&Search=${search}&InsuranceCompanyId=${insuranceCompanyId}&FormularyId=${formulayId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res: AxiosResponseGlobal<IcdMedicineAllListResponse>) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addFormulary = (payload: AddFormularyPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.FORMULARY, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((error:any) => {
        if (error && error?.response?.status === 400) showAlert(2, error?.response?.data?.Message);
          else showAlert(2);
          reject(error)
         });
  });
};

export const updateFormulary = (payload: UpdateFormularyPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = { FormularyId: payload.FormularyId, Name: payload.Name, InsuranceCompanyId: payload.InsuranceCompanyId };
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.FORMULARY, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((error:any) => {
        if (error && error?.response?.status === 400) showAlert(2, error?.response?.data?.Message);
          else showAlert(2);
          reject(error)
         });
        
  });
};

// import scheme from excel api
export const importSchemeFromExcelApi = (payload: object, type: 'scheme' | 'medicine') => {
  return new Promise<
    AxiosResponseGlobal<{
      TotalRecords: number;
      RecordProcessed: number;
    }>
  >((resolve, reject) => {
    checkOnline();
    const params = `${type === 'scheme' ? END_POINTS.IMPORT_SCHEME_FROM_EXCEL : END_POINTS.IMPORT_MEDICINE_FROM_EXCEL}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(params, payload)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        const {data} = err.response
        showAlert(2, data?.Message);
        reject("Can't import scheme from excel");
        
      });
  });
};

// action
// detail
export const schemeDetailAction = (payload: any) => {
  return {
    type: ACTION_NAME.SCHEME_DETAIL,
    payload
  };
};

// listing
export const manageSchemeSpecificFormularyListAction = (payload: SchemeSpecificFormularyPayloadRedux) => {
  return {
    type: ACTION_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_LIST,
    payload
  };
};

export const manageFormularyIcd10DiagnosisBlockListAction = (payload: SchemeSpecificFormularyICD10PayloadRedux) => {
  return {
    type: ACTION_NAME.MANAGE_FORMULARY_ICD10_DIAGNOSIS_BLOCKS_LIST,
    payload
  };
};
export const resetFormulary = () => {
  return {
    type: ACTION_NAME.RESET_ADD_FORMULARY,
    payload: {
      ...new AddFormulary()
    }
  };
};
// scheme medicine
export const getSchemeIcdMedicineAction = (payload: any) => {
  return {
    type: ACTION_NAME.SCHEME_MEDICINE,
    payload
  };
};

export const getFormularyIcd10DiagnosisBlocksList = ({
  page,
  limit,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive,
  insuranceCompanyId,
  formularyId,
  statusId,
  fromDate,
  toDate
}: FormularyIcd10DiagnosisBlocksListPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.ADMIN_FORMULARY_ICD10_DIAGNOSIS_BLOCK_LIST}?FormularyId=${formularyId}&Page=${page}&Limit=${limit}${
      filterValue ? `&Search=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      insuranceCompanyId ? `&InsuranceCompanyId=${insuranceCompanyId}` : ''
    }${statusId ? `&IsActive=${statusId === '1' ? true : false}` : ''}${fromDate ? `&FromDate=${fromDate}` : ''}${
      toDate ? `&ToDate=${toDate}` : ''
    }`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(manageFormularyIcd10DiagnosisBlockListAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const updateStatusFormularyIcd10DiagnosisBlocksApi = (
  payload: UpdateStatusFormularyICD10Block,
  handleCloseInactive: () => void 
) => {
  return (dispatch: AppDispatchFunction, getState: () => ReducersModal) => {
    const { Page, ItemOffset, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate } =
      getState().manageIcd10DiagnosisBlocksReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.UPDATE_STATUS_ADMIN_FORMULARY_ICD10_DIAGNOSIS, params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(
              getFormularyIcd10DiagnosisBlocksList({
                formularyId:payload.FormularyId,
                page: Page,
                limit: ItemOffset,
                sortBy: SortBy,
                sortOrder: SortOrder,
                ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
                ...(StatusId && { statusId: StatusId }),
                ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate }),
                handleCloseInactive
              })
            );
          dispatch(schemeDetail(payload.FormularyId.toString()))
          showAlert(1, data?.Message);
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const getAssignFormularytoSchemeList = ({
  page,
  limit,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive,
  insuranceCompanyId,
  statusId,
  fromDate,
  toDate
}: AssignFormularytoSchemeListPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.FORMULARY}?IsSchemeAssignList=true&Page=${page}&Limit=${limit}${filterValue ? `&Search=${filterValue}` : ''}${
      sortBy ? `&SortBy=${sortBy}` : ''
    }${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      insuranceCompanyId ? `&InsuranceCompanyId=${insuranceCompanyId}` : ''
    }${statusId ? `&IsActive=${statusId === '1' ? true : false}` : ''}${fromDate ? `&FromDate=${fromDate}` : ''}${
      toDate ? `&ToDate=${toDate}` : ''
    }`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(manageAssignFormularytoSchemeListAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};
// listing
export const manageAssignFormularytoSchemeListAction = (payload: AssignFormularytoSchemePayloadRedux) => {
  return {
    type: ACTION_NAME.MANAGE_ASSIGN_FORMULARY_TO_SCHEME_LIST,
    payload
  };
};

export const updateStatusAssignFormularyApi = (
  payload: UpdateStatusFormulary,
  handleCloseInactive: () => void,
  type: 'detail' | 'list'
) => {
  return (dispatch: AppDispatchFunction, getState: () => ReducersModal) => {
    const { Page, ItemOffset, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate } =
      getState().manageSchemeSpecificFormularyReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.UPDATE_STATUS_ADMIN_FORMULARY, params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          if (type === 'list') {
            dispatch(
              getAssignFormularytoSchemeList({
                page: Page,
                limit: ItemOffset,
                sortBy: SortBy,
                sortOrder: SortOrder,
                ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
                ...(StatusId && { statusId: StatusId }),
                ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate }),
                handleCloseInactive
              })
            );
          }
          if (type === 'detail') {
            dispatch(schemeDetail(String(payload.FormularyId), handleCloseInactive));
          }
          showAlert(1, data?.Message);
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const resetAssignFormularyToScheme = () => {
  return {
    type: ACTION_NAME.RESET_ASSIGN_FORMULARY_TO_SCHEME,
    payload: {
      ...new AssignSchemeToFormulary()
    }
  };
};

export const getAssignFormularySchemeList = ({ page, limit, formularyId, type }: PaloadFormularySchemeList) => {
  return (dispatch: AppDispatch) => {
    // const formularyDetail = getState().schemeDetailReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.FORMULARY_SCHEME}?Page=${page}&Limit=${limit}&FormularyId=${formularyId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch({
            type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
            payload: {
              SchemeFromAPI:data?.Data?.Schemes
            }
          });
          // dispatch(
          //   schemeDetailAction({ ...formularyDetail, Scheme: { ...data?.Data, Limit: formularyDetail.Scheme.Limit } })
          // );

          if (type === 'delete') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(schemeDetail(String(formularyId)));
          }
        }
        if (type !== 'delete') dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};


export const getFormularyBlockList = ({
  page,
  limit,
  search,
  insuranceCompanyId,
  formularyId
}: {
  page: number;
  limit: number;
  search: string;
  insuranceCompanyId: number;
  formularyId: number;
}) => {
  return new Promise<AxiosResponseGlobal<{ Blocks: Block[] }>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.ADMIN_FORMULARY_ICD10_DIAGNOSIS_BLOCK_LIST}?Page=${page}&Limit=${limit}&Search=${search}&InsuranceCompanyId=${insuranceCompanyId}&FormularyId=${formularyId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSchemeSpecificFormularyListForAutoCom = ({
  page,
  limit,
  filterValue,
  forScheme
  }: SchemeSpecificFormularyListPayload) => {
    return new Promise<AxiosResponseGlobal<{ Formulary: Formulary[] }>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.FORMULARY}?Page=${page}&Limit=${limit}${filterValue ? `&Search=${filterValue}` : ''}${forScheme ? `&IsSchemeAssignList=true` : ''}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
    .then((res) => {
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    });
  });
};

export const resetCopyFromularyBlock = () => {
  return {
    type: ACTION_NAME.RESET_COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK,
    payload: {
      ...new CopySchemeSpecificFormularyBlock()
    }
  };
};

export const copyFormularyBlock = (payload: CoopyFormularyBlockPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    // const params = { FormularyId: payload.FormularyId, Name: payload.Name, InsuranceCompanyId: payload.InsuranceCompanyId };
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.COPY_FORMULARY_BLOCK_MEDCINES, payload)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((error:any) => {
        if (error && error?.response?.status === 400) showAlert(2, error?.response?.data?.Message);
          else showAlert(2);
          reject(error)
         });
        
  });
};

export const resetCopyFromulary = () => {
  return {
    type: ACTION_NAME.RESET_COPY_SCHEME_SPECIFIC_FORMULARY,
    payload: {
      ...new CopySchemeSpecificFormulary()
    }
  };
};

export const copyExistingFormulary = (payload: CopyExistingFormularyPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.COPY_EXISTING_FORMULARY, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((error:any) => {
        if (error && error?.response?.status === 400) showAlert(2, error?.response?.data?.Message);
          else showAlert(2);
          reject(error)
         });
  });
};