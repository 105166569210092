import { SortByQuesListQueryParam, SortOrderQuesListQueryParam } from '../types/faq.types';
import { Block, SortByBlockListQueryParam, SortOrderBlockListQueryParam } from '../types/manage.icd10.diagnosis.block';
import { Formulary, ISMedicine } from '../types/manage.scheme.specific.formulary';
import FORM_FIELD from '../validations/form-field';

export class ManageSchemeSpecificFormularyModal {
  TotalRecords = 0;
  Page = 1;
  Formulary: Formulary[] = [];
  ItemOffset = 10;
  SortBy: SortByBlockListQueryParam = '1';
  SortOrder: SortOrderBlockListQueryParam = 'DESC';
  InsuranceCompanyId = 0;
  StatusId = '';
  BlockId = 0;
  FromDate: string | null = '';
  ToDate: string | null = '';
  initialValueSortDate = FORM_FIELD.schemeSpecificFilterListing;
}

export class SchemeIcdMedicine {
  TotalRecords = 0;
  Page = 1;
  Medicines: ISMedicine[] = [];
  ItemOffset = 10;
  SortBy: SortByQuesListQueryParam = '1';
  SortOrder: SortOrderQuesListQueryParam = 'DESC';
  MedicineId:number[]=[]
}

export class ManageFormularyIcd10DiagnosisBlocksModal {
  TotalRecords = 0;
  Page = 1;
  Blocks: Block[] = [];
  ItemOffset = 10;
  SortBy: SortByBlockListQueryParam = '1';
  SortOrder: SortOrderBlockListQueryParam = 'DESC';
  InsuranceCompanyId = 0;
  StatusId = '';
  BlockId = 0;
  FromDate: string | null = '';
  ToDate: string | null = '';
  initialValueSortDate = FORM_FIELD.icd10DiagnosisFilterListing;
}

export class ManageAssignFormularytoSchemeModal {
  TotalRecords = 0;
  Page = 1;
  Formulary: Formulary[] = [];
  ItemOffset = 10;
  SortBy: SortByBlockListQueryParam = '1';
  SortOrder: SortOrderBlockListQueryParam = 'DESC';
  InsuranceCompanyId = 0;
  StatusId = '';
  BlockId = 0;
  FromDate: string | null = '';
  ToDate: string | null = '';
  initialValueSortDate = FORM_FIELD.schemeSpecificFilterListing;
}