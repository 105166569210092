import { DoctorVlogModal, VlogDetailModal } from '../../modal';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';

export const doctorVlogReducer = (state: DoctorVlogModal = new DoctorVlogModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.GET_DOCTOR_VLOG_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const vlogDetailReducer = (state: VlogDetailModal = new VlogDetailModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.VLOG_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
