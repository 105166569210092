import { Box, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReducersModal } from "../../../../modal"
import { Formulary } from "../../../../types/manage.scheme.specific.formulary"
import ACTION_NAME from "../../../../utils/action.name"
import { TIndex } from "../../../manage-icd10-diagnosis/copy.block"
import SchemeViewAll from "../../scheme.view.all"
import ExistingFormulary from "./existing-formulary"
import NewFormulary from "./new-formulary"

type CopySchemeDialogProps={
  open:boolean;
  handleClose:()=>void;

}

function CopySchemeSpecificFormulary({open, handleClose}:CopySchemeDialogProps) {
    const {FromNewFormulary, DestinationFormulary, DestinationFormularyId, FormularyToRemove} = useSelector((state:ReducersModal)=> state.copySchemeSpecificFormularyReducer);
    const dispatch = useDispatch()
    const [value, setValue] = useState<TIndex>(0);
    const handleChangeIndex=(index:TIndex)=>{
      setValue(index)
    }
    const handleDeleteFormulary = (item:Formulary)=>{
      const restFormulary = DestinationFormulary.filter((formulary)=> formulary.FormularyId !== item.FormularyId)
      const restFormularyId = DestinationFormularyId.filter((id) => id !== item.FormularyId )
      const restFormularyToRemove = FormularyToRemove.filter((id) => id !== item.FormularyId)
      dispatch({
        type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY,
        payload:{
          DestinationFormulary: restFormulary,
          DestinationFormularyId: restFormularyId,
          FormularyToRemove: restFormularyToRemove
        }
      })
    }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}>
        {value===0 && <>
          <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
              <Typography variant="h1" sx={{ color: 'common.white' }}>
                  Copy Scheme Specific Formulary
              </Typography>
          </Box>
          <Box sx={{p: 2}}>
          <FormControl>
              <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={FromNewFormulary}
                  onChange={()=>{
                    dispatch({
                      type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY,
                      payload:{
                        FromNewFormulary: !FromNewFormulary
                      }
                    })
                  }}
                  sx={{ display:"flex", flexDirection:"row", justifyContent:"space-between"}}
              >
                  <FormControlLabel value={false} control={<Radio />} label="Existing Formulary" sx={{mr:"100px"}} />
                  <FormControlLabel value={true} control={<Radio />} label="New Formulary" />
              </RadioGroup>
          </FormControl>
          {!FromNewFormulary ? 
          <ExistingFormulary
          handleClose={handleClose} handleChangeIndex={handleChangeIndex}/> :
          <NewFormulary
          handleClose={handleClose}/>}
          </Box>
        </>} 
        {value === 1 &&  (
          <>
            <SchemeViewAll
              type="formulary"
              index={value}
              heading="All Scheme Specific Formulary"
              handleChangeIndex={handleChangeIndex}
              bradcrumb={{
                link: 'Medicines'
              }}
              placeholder="Search Scheme"
              inputValue={""}
              handleSearchChange={()=>{return false}}
              handleSearchSubmit={()=>{return false}}
              handleDeleteFormulary={handleDeleteFormulary}
              formulary={DestinationFormulary}
            />
          </>
        )}
    </Dialog>
  )
}

export default CopySchemeSpecificFormulary