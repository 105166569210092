import axios, { AxiosError, AxiosResponse } from 'axios';
import { AxiosResponseGlobal } from '../types/global.types';
import showAlert from './alert';
import END_POINTS from './end.points';
import SESSION_MANAGEMENT from './session.management';

const AXIOS = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VERSON}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

const AUTHENTICATION_AXIOS = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_AUTHENTICATE_BASE_URL}${process.env.REACT_APP_VERSON}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

const LOGOUT_AXIOS = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_AUTHENTICATE_BASE_URL}${process.env.REACT_APP_VERSON}`,
  headers: {
    'Content-Type': 'application/json',
    }
});
// refresh token
LOGOUT_AXIOS.interceptors.request.use(
  function (config) {
    if (config?.headers) config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
  },
  (error) => responseError(error)
);

LOGOUT_AXIOS.interceptors.response.use(
  (config) => responseConfig(config),
  (error) => responseError(error)
);

const ICD10_DIAGNOSIS_BLOCK_AXIOS = axios.create({
  timeout: 100000,
  baseURL: `${process.env.REACT_APP_ICD10_DIAGNOSIS_BLOCK_BASE_URL}${process.env.REACT_APP_VERSON}`,
  headers: {
    'Content-Type': 'application/json',
    
  }
});

// refresh token
ICD10_DIAGNOSIS_BLOCK_AXIOS.interceptors.request.use(
  function (config) {
    if (config?.headers) config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    return config;
  },
  (error) => responseError(error)
);

ICD10_DIAGNOSIS_BLOCK_AXIOS.interceptors.response.use(
  (config) => responseConfig(config),
  (error) => responseError(error)
);

// response interceptor
const responseConfig = (config: AxiosResponse) => {
  return config;
};

const refreshTokenHandler = async () => {
  const token = localStorage.getItem('accessToken') || '';
  const refreshToken = localStorage.getItem('refreshToken') || '';
  const payload = {
    AccessToken: token,
    RefreshToken: refreshToken,
    TokenExpiryTime: '2d'
  };

  return AUTHENTICATION_AXIOS.post(END_POINTS.REFRESH_TOKEN, payload);
};

const responseError = async (error: AxiosError) => {
  const originalRequest = error.config;
  if (error.response?.status === 401) {
    try {
      const refreshToken: AxiosResponseGlobal<{
        AccessToken: string;
        RefreshToken: string;
      }> = await refreshTokenHandler();
      const { status, data } = refreshToken;
      if (status === 201) {
        const token = data?.Data?.AccessToken;
        SESSION_MANAGEMENT.setAccessToken(token);
        SESSION_MANAGEMENT.setLocalStorageKeyData('accessToken', token);
        SESSION_MANAGEMENT.setLocalStorageKeyData('refreshToken', data?.Data?.RefreshToken);
        if (originalRequest.headers) originalRequest.headers['Authorization'] = `Bearer ${token}`;
        return axios(originalRequest);
      }
    } catch (_error) {
      SESSION_MANAGEMENT.removeSession();
      window.location.href = '/';
      showAlert(2);
    }
  } else {
    return Promise.reject(error);
  }
};

AXIOS.interceptors.response.use(
  (config) => responseConfig(config),
  (error) => responseError(error)
);

const getEnvValue = (value: string | undefined): string => {
  return value ? value : '';
};

const BASE_URL: string = getEnvValue(process.env.REACT_APP_BASE_URL) + getEnvValue(process.env.REACT_APP_VERSON);
const AUDIO_BASE_URL: string = getEnvValue(process.env.REACT_APP_AUDIO_BASE_URL);
const VIDEO_BASE_URL: string = getEnvValue(process.env.REACT_APP_VIDEO_BASE_URL);
const THUMBNAIL_BASE_URL: string = getEnvValue(process.env.REACT_APP_THUMBNAIL_BASE_URL);
const IMAGE_BASE_URL: string = getEnvValue(process.env.REACT_APP_IMAGE_BASE_URL);
const REACT_APP_ICD10_DIAGNOSIS_BLOCK_BASE_URL: string = getEnvValue(
  process.env.REACT_APP_ICD10_DIAGNOSIS_BLOCK_BASE_URL
);

const API_ERROR_CODE = {
  UNAUTHORIZED: 401,
  ACCESS_DENIED: 430,
  SESSION_EXPIRED: 440,
  VALIDATION_ERROR: 400,
  EMAIL_NOT_VERIFIED: 403
};

const API_SUCCESS_CODE = {
  POST_SUCCESS: 201,
  SUCCESS: 200
};

const CONSTANTS = {
  AXIOS,
  AUTHENTICATION_AXIOS,
  ICD10_DIAGNOSIS_BLOCK_AXIOS,
  API_ERROR_CODE,
  API_SUCCESS_CODE,
  API_URL: '',
  BASE_URL,
  AUDIO_BASE_URL,
  VIDEO_BASE_URL,
  THUMBNAIL_BASE_URL,
  IMAGE_BASE_URL,
  REACT_APP_ICD10_DIAGNOSIS_BLOCK_BASE_URL,
  LOGOUT_AXIOS
};
export default CONSTANTS;
