import { AxiosResponseGlobal } from '../../types/global.types';
import { UpdateRuleDTO, UpdateRulePayload } from '../../types/mange.point';
import API from '../../utils/api';
import { checkOnline } from '../../utils/common.function';
import END_POINTS from '../../utils/end.points';

export const updateRule = (payload: UpdateRulePayload) => {
  return new Promise<AxiosResponseGlobal<UpdateRuleDTO>>((resolve, reject) => {
    checkOnline();
    const params = payload;
    API.patchApiCall(
      END_POINTS.RULE,
      params,
      (res: AxiosResponseGlobal<UpdateRuleDTO>) => {
        resolve(res);
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
      }
    );
  });
};

export const getRule = () => {
  return new Promise<AxiosResponseGlobal<{ Rules: UpdateRuleDTO[] }>>((resolve, reject) => {
    checkOnline();
    const params = '';
    API.getApiCall(
      END_POINTS.RULE,
      params,
      (res: AxiosResponseGlobal<{ Rules: UpdateRuleDTO[] }>) => {
        resolve(res);
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
      }
    );
  });
};
