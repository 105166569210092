import { combineReducers } from 'redux';
import { globalLoaderReducer } from '../reducer/global.reducer';
import { manageCategoryReducer } from '../pages/manage-category/reducer';
import {
  manageIcd10DiagnosisBlocksReducer,
  addBlockReducer,
  icdDetailReducer,
  icdMedicineReducer,
  copyBlockReducer,
  copyICD10BlockReducer
} from '../pages/manage-icd10-diagnosis/reducer';
import {
  doctorRecommendationReducer,
  recommendationDeatilReducer,
  recommendationReportListReducer
} from '../pages/doctors-recommendation/reducer';
import { questionListReducer, questionDetailReducer } from '../pages/faq/reducer';
import { doctorVlogReducer, vlogDetailReducer } from '../pages/doctors-vlog/reducer';
import { notificationListReducer } from '../pages/notification/reducer';
import {
  addFormularyReducer,
  assignFormularytoSchemeReducer,
  copySchemeSpecificFormularyBlockReducer,
  copySchemeSpecificFormularyReducer,
  manageAssignFormularytoSchemeReducer,
  manageFormularyIcd10DiagnosisBlocksReducer,
  manageSchemeSpecificFormularyReducer,
  schemeDetailReducer,
  schemeIcdMedicineReducer
} from '../pages/scheme-specific-formulary/reducer';

const rootReducer = combineReducers({
  globalLoaderReducer,
  manageIcd10DiagnosisBlocksReducer,
  manageCategoryReducer,
  doctorRecommendationReducer,
  recommendationDeatilReducer,
  recommendationReportListReducer,
  questionListReducer,
  questionDetailReducer,
  doctorVlogReducer,
  vlogDetailReducer,
  notificationListReducer,
  addBlockReducer,
  icdDetailReducer,
  icdMedicineReducer,
  copyBlockReducer,
  manageSchemeSpecificFormularyReducer,
  schemeDetailReducer,
  addFormularyReducer,
  schemeIcdMedicineReducer,
  manageFormularyIcd10DiagnosisBlocksReducer,
  manageAssignFormularytoSchemeReducer,
  assignFormularytoSchemeReducer,
  copySchemeSpecificFormularyReducer,
  copySchemeSpecificFormularyBlockReducer,
  copyICD10BlockReducer
});

export default rootReducer;
