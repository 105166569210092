import { QuestionListModal, QuestionDetailModal } from '../../modal';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';

export const questionListReducer = (state: QuestionListModal = new QuestionListModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.QUESTION_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const questionDetailReducer = (state: QuestionDetailModal = new QuestionDetailModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.QUESTION_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
