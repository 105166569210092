import { Box, DialogContent, Typography, Dialog } from '@mui/material';
import ChipCus from '../../../components/chip/chip.cus';
import { Diagnosis } from '../../../types/manage.icd10.diagnosis.block';

type IcdViewAllProps = {
  data: Diagnosis[];
  open: boolean;
  handleClose: () => void;
};

function IcdViewAll({ data, open, handleClose }: IcdViewAllProps) {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} scroll={'paper'}>
      <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
        <Typography variant="h1" sx={{ color: 'common.white' }}>
          ICD10 Code(s)
        </Typography>
      </Box>
      <DialogContent>
        <Box>
          {data.map((icd) => {
            return (
              <ChipCus
                sx={{ mt: 0.8, mr: 1, fontSize: 16 }}
                key={icd.DiagnosisCode}
                label={`${icd.DiagnosisCode}-(${icd.DiagnosisDescription})`}
                color="primary"
              />
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default IcdViewAll;
