import { Box } from '@mui/material';
import ModalWrapper from './modal.wrapper';

type ImportDialogProps = {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  filterButtonPosition?: DOMRect;
};

function ImportDialog({ children, open, handleClose, filterButtonPosition }: ImportDialogProps) {
  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        maxWidth: 250,
        top: {
          xs: filterButtonPosition?.y ? filterButtonPosition.y + 100 : 455,
          sm: filterButtonPosition?.y ? filterButtonPosition.y + 110 : 455
        },
        left: {
          xs: filterButtonPosition?.x ? filterButtonPosition.x : '81%',
          sm: filterButtonPosition?.x ? filterButtonPosition.x+ 250 : '85%'
        },
        position: 'relative',
        p: 1,
        borderRadius: '5px !important'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '-14px',
          right: '10px',
          width: 0,
          height: 0,
          borderLeft: (theme) => `${theme.spacing(0.8)} solid transparent`,
          borderRight: (theme) => `${theme.spacing(0.8)} solid transparent`,
          borderBottom: (theme) => `${theme.spacing(1.6)} solid var(--white)`
        }}></Box>
      {children}
    </ModalWrapper>
  );
}

export default ImportDialog;
