import { AxiosResponseGlobal } from '../types/global.types';
import { CategoriesTypeApiResponse } from '../types/manage.category.types';
import { InsuranceCompanyApiResponse } from '../types/insurance.company';
import API from '../utils/api';
import { checkOnline } from '../utils/common.function';
import END_POINTS from '../utils/end.points';
import CONSTANTS from '../utils/constants';

export const getCategory = () => {
  return new Promise<AxiosResponseGlobal<CategoriesTypeApiResponse>>((resolve, reject) => {
    checkOnline();
    const params = '';
    API.getApiCall(
      END_POINTS.ADMIN_CATEGORY,
      params,
      (res: AxiosResponseGlobal<CategoriesTypeApiResponse>) => {
        resolve(res);
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
      }
    );
  });
};

export const getInsuranceCompany = () => {
  return new Promise<AxiosResponseGlobal<InsuranceCompanyApiResponse>>((resolve, reject) => {
    checkOnline();
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(END_POINTS.INSURANCE_COMPANY)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
