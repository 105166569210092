import { Box, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfirmButton from '../../../components/button/confirm.button';
import DeleteButton from '../../../components/button/delete.button';
import InactiveModal from '../../../components/modal-wrapper/inactive.modal';
import ReadMore from '../../../components/read-more/read.more';
import TitleName from '../../../components/title-name/title.name';
import { ReducersModal } from '../../../modal';
import { formatDateAndTime } from '../../../utils/common.function';
import CONSTANTS from '../../../utils/constants';
import { updateStatusVlog } from '../action';

const useStyles = makeStyles(() =>
  createStyles({
    playerWrapper: {
      position: 'relative',
      width: '500px',
      aspectRatio: '16/9'
    },
    reactPlayer: {
      position: 'absolute',
      top: '0',
      left: '0'
    }
  })
);

function DetailCard() {
  const classes = useStyles();
  const [openInactive, setOpenInactive] = useState(false);
  const dispatch = useDispatch();
  const { recommendationId } = useParams();
  const { CategoryName, CreatedAt, TotalLikes, TotalDislikes, IsActive, VideoURL, ReportCount, Description } =
    useSelector((state: ReducersModal) => state.vlogDetailReducer);

  const handleCloseInactive = () => {
    setOpenInactive(false);
  };

  const handleOpenInactive = () => {
    setOpenInactive(true);
  };

  const handleConfirmInactive = () => {
    if (recommendationId) {
      const payload = {
        RecommendationId: Number(recommendationId),
        IsActive: IsActive === 0 ? true : false
      };
      dispatch(updateStatusVlog(payload, handleCloseInactive, 'detail'));
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => (IsActive === 0 ? 'var(--inactive)' : theme.palette.common.white),
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <Box sx={{ p: { xs: 1, sm: 2 } }}>
          <Grid container>
            <Grid item xs={11} sm={11.5}>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TitleName title="Category" name={CategoryName} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TitleName title="Added on" name={formatDateAndTime(CreatedAt)} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                      <TitleName title="Likes" name={TotalLikes} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={1.5}>
                      <TitleName title="Dislikes" name={TotalDislikes} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2}>
                      <TitleName title="Report" name={ReportCount} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={0.5}>
              {IsActive === 1 && (
                <DeleteButton
                  onClick={handleOpenInactive}
                  sx={{
                    p: 1,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.secondary.light
                    }
                  }}
                />
              )}
              {IsActive === 0 && (
                <ConfirmButton
                  onClick={handleOpenInactive}
                  sx={{
                    p: 1,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.secondary.light
                    }
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Box my={1}>
            <Typography variant="h1" sx={{ fontSize: (theme) => theme.spacing(1.6) }}>
              Description
            </Typography>
            <ReadMore showString={300}>{Description?.length > 0 ? Description : 'N/A'}</ReadMore>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
            {VideoURL ? (
              <Box className={classes.playerWrapper}>
                <ReactPlayer
                  className={classes.reactPlayer}
                  url={CONSTANTS.VIDEO_BASE_URL + VideoURL}
                  width="100%"
                  height="100%"
                  controls
                />
              </Box>
            ) : (
              <Typography color="primary">Media not available</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={IsActive === 0 ? 'Active?' : 'Inactive?'}
        paragraph={
          <>
            Are you sure you want to {IsActive === 0 ? 'activate' : 'inactivate'}
            <br /> the selected Video?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
}

export default DetailCard;
