import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import ButtonWrapper from "../../../../components/button/button.wrapper"
import ChipCus from "../../../../components/chip/chip.cus"
import { ReducersModal } from "../../../../modal"
import { Formulary } from "../../../../types/manage.scheme.specific.formulary"
import ACTION_NAME from "../../../../utils/action.name"
import showAlert from "../../../../utils/alert"
import { VIEW_NUMBER } from "../../../../utils/static.data"
import { copyExistingFormulary, getAssignFormularytoSchemeList, getSchemeSpecificFormularyList } from "../../action"
import AutocomFormularySearch from "./autocom.formulary.search";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TIndex } from "../../../manage-icd10-diagnosis/copy.block"

type ExistingFormularyProps={
  handleClose:()=>void;
  handleChangeIndex:(i:TIndex)=>void;
}

function ExistingFormulary({handleClose, handleChangeIndex}:ExistingFormularyProps) {
  const {DestinationFormulary, DestinationFormularyId, FormularyToRemove, SourceFormulary, DestinationInsuranceId, FromAssignScheme } = useSelector((state:ReducersModal)=> state.copySchemeSpecificFormularyReducer);
  const { Page, ItemOffset } = useSelector((state: ReducersModal) => state.manageSchemeSpecificFormularyReducer);
  const dispatch = useDispatch()
  const deleteFormulary = (item:Formulary)=>{
    const restFormulary = DestinationFormulary.filter((formulary)=> formulary.FormularyId !== item.FormularyId)
    const restFormularyId = DestinationFormularyId.filter((id) => id !== item.FormularyId )
    const restFormularyToRemove = FormularyToRemove.filter((id) => id !== item.FormularyId)
    dispatch({
      type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY,
      payload:{
        DestinationFormulary: restFormulary,
        DestinationFormularyId: restFormularyId,
        FormularyToRemove: restFormularyToRemove
      }
    })
  } 
  const onProceed = ()=>{
    if(!DestinationFormularyId.length) {
      showAlert(2, "Please select formulary from dropdown")
      return false
    }
    const payload ={
      SourceFormularyId:SourceFormulary,
      DestinationFormularyIds: DestinationFormularyId,
      InsuranceCompanyIds: [...new Set(DestinationInsuranceId)]
    }
    // eslint-disable-next-line no-console
    console.log(payload)
    copyExistingFormulary(payload)
    .then(()=>{
      showAlert(1, 'Success');
      if(FromAssignScheme){
        dispatch(
          getAssignFormularytoSchemeList({
            page: Page,
            limit: ItemOffset
          })
        );
      }
      else{dispatch(
        getSchemeSpecificFormularyList({
          page: Page,
          limit: ItemOffset
        })
      );}
      handleClose()
    })
    .catch(()=>{showAlert(2)})
  }
  return (
    <Box>
      <Box sx={{pt:1}}>
        <AutocomFormularySearch name="schemeSpecificFormularySearch" label="Search formulary"/>
        <Typography variant="h3" sx={{py:2, color: 'primary.main'}}> <b>Please Note:</b> It will replace the existing formulary with the formulary details you are copying [only medicines will be replaced]</Typography>
        {DestinationFormulary?.length > 0 && (
            <Box>
            {DestinationFormulary.map((item, index) => {
                if (index > VIEW_NUMBER) return null;
                return (
                <ChipCus
                    sx={{ mt: 0.8, mr: 0.5 }}
                    key={item.FormularyId}
                    label={`${item.Name}-${item.InsuranceCompanyName}`}
                    color="primary"
                    onDelete={()=>{deleteFormulary(item)}}
                />
                );
            })}
            </Box>
        )}
        {DestinationFormulary.length> VIEW_NUMBER && 
        <ButtonWrapper
          onClick={() => handleChangeIndex(1)}
          endIcon={<ArrowForwardIosIcon />}
          variant="contained"
          color="primary"
          sx={{mt:2}}
          >
          View All
        </ButtonWrapper>}
      </Box>
        <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
              py:"10px"
            }}>
          <ButtonWrapper
              variant="outlined"
              sx={{
              color: (theme) => theme.palette.error.light,
              px: { xs: 2, sm: 5 },
              mr: 1,
              py: 1,
              borderColor: (theme) => theme.palette.error.light,
              '&:hover': {
                  borderColor: (theme) => theme.palette.error.light
              }
              }}
              onClick={handleClose}>
              Cancel
          </ButtonWrapper>
          <ButtonWrapper
              variant="contained"
              color="primary"
              sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
              onClick={onProceed}>
              Proceed
          </ButtonWrapper>
        </Box>
    </Box>    
  )
}

export default ExistingFormulary