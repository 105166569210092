/* eslint-disable react/jsx-key */
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputField from '../../../components/form-ui/textfield-wrapper/input.field';
import { getAllSchemeIcdMedicine } from '../action';
import { IcdMedicineList } from '../../../types/manage.icd10.diagnosis.block';
import showAlert from '../../../utils/alert';
import { Children, Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import useDebounce from '../../../hooks/use.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../../modal';
import { Box, Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import ChipCus from '../../../components/chip/chip.cus';
import ButtonWrapper from '../../../components/button/button.wrapper';
import SelectWrapper from '../../../components/form-ui/select-wrapper/select.wrapper';
import { ORDER_OF_TIER_VALUE } from '../../../utils/static.data';
import ACTION_NAME from '../../../utils/action.name';

type AutocomICDProps = {
  label: string;
  setAddMedicine: (medicine: IcdMedicineList[]) => void;
  medicineListClose: boolean;
  setMedicineListClose: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: theme.spacing(0.6),
      borderColor: theme.palette.primary.main,
      font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(1.6)} Josefine Sans, sans-serif`,
      color: theme.palette.primary.main,
      outline: 'transparent',
      width: theme.spacing(9),
      marginLeft: 'auto',
      '&:focus': {
        borderColor: theme.palette.primary.main
      }
    },
    paper: {
      '& .MuiAutocomplete-option': {
        display: 'flex',
        alignItems: 'center !important',
        justifyContent: 'space-between !important',
        '& .MuiTypography-body1': {
          flexBasis: '65%'
        }
      }
    }
  })
);

export default function AutocomAddMedicine({
  label,
  setAddMedicine,
  medicineListClose,
  setMedicineListClose
}: AutocomICDProps) {
  const { FormularyId, InsuranceCompanyId } = useSelector((state: ReducersModal) => state.schemeDetailReducer);
  const { Medicines, MedicineId } = useSelector(
    (state: ReducersModal) => state.schemeIcdMedicineReducer
  );
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly IcdMedicineList[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [medicine, setMedicine] = useState<IcdMedicineList[]>([]);
  const [isSelectOption, setIsSelectOption] = useState(true);
  const [reRender, setReRender] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch()
  const debounceValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    if (medicineListClose) {
      setOpen(false);
    }
  }, [medicineListClose]);
  useEffect(()=>{dispatch({
    type:ACTION_NAME.SCHEME_MEDICINE,
    payload:{
      MedicineId:Medicines.map((med)=> {return med.MedicineId})
    }
  })},[Medicines])

  useEffect(() => {
    let active = true;

    if (debounceValue.length < 3) {
      return undefined;
    }

    (async () => {
      try {
        const res = await getAllSchemeIcdMedicine({
          page: 1,
          limit: 100,
          search: debounceValue,
          insuranceCompanyId: InsuranceCompanyId,
          formulayId: FormularyId
        });
        if (active) {
          const newMed: IcdMedicineList[] = [];
          for (const ele of res.data.Data.Medicines) {
            newMed.push({
              ...ele,
              inputV: debounceValue,
              orderOfTier: '1'
            });
          }
          
          setOptions(newMed.filter((med)=> !MedicineId.includes(med.MedicineId)));
        }
      } catch (_err) {
        showAlert(2);
      }
    })();

    return () => {
      active = false;
    };
  }, [debounceValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (medicine.length > 0) {
      setAddMedicine(medicine);
    }
  }, [medicine]);

  const handleDeleteMedicint = (icd: IcdMedicineList) => {
    const filterIcd = medicine.filter((item) => item.MedicineId !== icd.MedicineId);
    setMedicine(filterIcd);
    const findInOption = options.find((item) => item.MedicineId === icd.MedicineId);
    if (findInOption) return;
    const updateOption = medicine.filter((item) => item.MedicineId === icd.MedicineId);
    setOptions([...updateOption, ...options]);
  };

  const handleInputChange = ({ target }: React.ChangeEvent<{ value: string }>, option: IcdMedicineList) => {
    const { value } = target;
    if (value === '') {
      option.orderOfTier = value;
      setReRender(!reRender);
      return;
    }
    if (Number(value) <= 0) return showAlert(2, 'Please enter a valid order of tier');
    option.orderOfTier = value;
    setReRender(!reRender);
  };

  const handleDone = (option: IcdMedicineList) => {
    const findMedicine = medicine.find((item) => item.MedicineId === option.MedicineId);
    if (findMedicine) return showAlert(2, 'Medicine already added');
    else {
      const updateOption = options.filter((item) => item.MedicineId !== option.MedicineId);
      setMedicine([...medicine, option]);
      setOptions([...updateOption]);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option:any) => `${option.Name} ${option?.Api} ${option?.inputV}`
  });

  return (
    <>
      {medicine?.length > 0 && (
        <Box sx={{ mb: 2 }} onClick={() => setMedicineListClose(true)}>
          {Children.toArray(
            medicine.map((med) => {
              return (
                <ChipCus
                  sx={{ mt: 0.8, mr: 0.5 }}
                  label={
                    <Typography>
                      {med.Name}, <Box component="span">Tier: {med.orderOfTier}</Box>
                    </Typography>
                  }
                  color="primary"
                  onDelete={() => handleDeleteMedicint(med)}
                />
              );
            })
          )}
        </Box>
      )}
      <Autocomplete
        filterOptions={filterOptions}
        classes={{ paper: classes.paper }}
        disableCloseOnSelect={isSelectOption}
        noOptionsText={debounceValue.length >= 3 ? 'No medicine found' : 'Type at least 3 characters'}
        size="small"
        inputValue={inputValue}
        onInputChange={(_event, newInputValue, reason) => {
          if (reason === 'reset') setInputValue('');
          else setInputValue(newInputValue);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
          setMedicineListClose(false);
          setIsSelectOption(true);
        }}
        onClose={(_e, reason) => {
          if (reason === 'selectOption') setIsSelectOption(false);
          if (reason === 'blur') return;
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.inputV === value.inputV || option.Name === value.Name}
        getOptionLabel={(option) => `${option.Name}-(${option.Api})`}
        options={options}
        renderOption={(props, option) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
          const { onClick, ...otherProps } = props;
          return (
            <Box component="li" {...otherProps}>
              <Typography sx={{ maxWidth: '75%' }}>
                {option.Name}
                {option.Api.trim() ? `-(${option.Api})` : ''}
              </Typography>
              <Box>
                <SelectWrapper
                  className={classes.input}
                  value={option.orderOfTier}
                  onChange={(e: any) => {
                    handleInputChange(e, option);
                  }}
                  menuItem={ORDER_OF_TIER_VALUE}
                />
                <ButtonWrapper
                  onClick={() => handleDone(option)}
                  variant="contained"
                  color="primary"
                  sx={{ ml: 1, px: 0.5 }}>
                  Add
                </ButtonWrapper>
              </Box>
            </Box>
          );
        }}
        renderInput={(params) => (
          <InputField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
              autoComplete: 'off'
            }}
          />
        )}
      />
    </>
  );
}
