import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/theme';

type ProviderProps = {
  children: React.ReactNode;
};

export const Providers = ({ children }: ProviderProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Provider>
  );
};
