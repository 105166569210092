import { Box, Typography } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import InactiveModal from '../../../components/modal-wrapper/inactive.modal';
import SearchTextfield from '../../../components/search-textfield/search.textfield';
// import ButtonWrapper from '../../../components/button/button.wrapper';
// import ChipCus from '../../../components/chip/chip.cus';
import useDebounce from '../../../hooks/use.debounce';
// import SearchTextfield from '../../../components/search-textfield/search.textfield';
import { ReducersModal } from '../../../modal';
import { Block } from '../../../types/manage.icd10.diagnosis.block';
import { calculatePage, calculateRowPerPage } from '../../../utils/common.function';
// import { globalLoaderEnd, globalLoaderStart } from '../../../reducer/global.reducer';
// import { IBlocks } from '../../../types/manage.scheme.specific.formulary';
// import showAlert from '../../../utils/alert';
// import { SCHEME_CODE_VIEW_NUMBER } from '../../../utils/static.data';
import { getFormularyIcd10DiagnosisBlocksList, manageFormularyIcd10DiagnosisBlockListAction, resetCopyFromularyBlock, updateStatusFormularyIcd10DiagnosisBlocksApi } from '../action';
import AddIcdBlock from './add.icd.block';
import CopyFormularyBlockMedicines from './copy.formulary.block.medicines';
import SICD10Table from './icd10.code.table';
// import IcdViewAll from './icd.view.all';


function IcdCode() {
  const [inputValue, setInputValue] = useState('');
  const [openBlock, setOpenBlock] = useState(false);
  // const [openViewAll, setOpenViewAll] = useState(false);
  const dispatch = useDispatch();
  const debouncedValue = useDebounce(inputValue.trim(), 1000);
  const [openInactive, setOpenInactive] = useState(false);
  const [openCopyBlockDialog, setOpenCopyBlockDialog] = useState(false);
  // const { Blocks } = useSelector((state: ReducersModal) => state.schemeDetailReducer);
  // const [localBlock, setLocalBlock] = useState<IBlocks[]>([]);
 
  // useEffect(() => {
  //   setLocalBlock(Blocks);
  // }, [Blocks]);
  const {
    FormularyId,
    InsuranceCompanyId,
    } = useSelector((state: ReducersModal) => state.schemeDetailReducer);
  const {
    Blocks,
    Page,
    ItemOffset,
    StatusId,
    FromDate,
    ToDate,
    TotalRecords
    } = useSelector((state: ReducersModal) => state.manageFormularyIcd10DiagnosisBlocksReducer);

    const [pageCount, setPageCount] = useState(Page);
    const [updateStatusFormularyIcd10DiagnosisBlock, setUpdateStatusFormularyIcd10DiagnosisBlock] = useState({} as Block);

  useEffect(() => {
    dispatch(
      getFormularyIcd10DiagnosisBlocksList({
        page: Page,
        limit: ItemOffset,
        // ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(debouncedValue && debouncedValue !== '' && { filterValue: debouncedValue }),
        ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
        ...({formularyId:FormularyId}),
        ...(StatusId && { statusId: StatusId }),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      })
    );
  }, [dispatch, Page, ItemOffset, debouncedValue, InsuranceCompanyId, StatusId, FromDate, ToDate]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);
  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const keyword = e.target.value;
  //   const filterBlock = Blocks.filter((item) => item.BlockName.toLowerCase().includes(keyword.toLowerCase().trim()));
  //   setLocalBlock(filterBlock);
  //   setInputValue(keyword);
  // };

  // const handleSearchSubmit = (e: React.SyntheticEvent) => {
  //   e.preventDefault();
  //   const target = e.target as typeof e.target & {
  //     searchInput: { value: string };
  //   };
  //   const keyword = target.searchInput.value;
  //   setInputValue(keyword);
  // };

  // const handleOpenBlock = () => {
  //   setOpenBlock(true);
  // };

  const handleCloseBlock = () => {
    setOpenBlock(false);
  };
  const handlePrevious = () => {
    dispatch(manageFormularyIcd10DiagnosisBlockListAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(manageFormularyIcd10DiagnosisBlockListAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };
  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(manageFormularyIcd10DiagnosisBlockListAction({ Page: selectedPage + 1 }));
  };
  const handleOpenInactive=(item:Block)=>{
    setOpenInactive(true)
    setUpdateStatusFormularyIcd10DiagnosisBlock(item);
  }
  const handleCloseInactive = useCallback(() => {
    setOpenInactive(false);
  }, []);
  const handleConfirmInactive = useCallback(() => {
    const payload = {
      BlockId: updateStatusFormularyIcd10DiagnosisBlock.BlockId,
      IsActive: updateStatusFormularyIcd10DiagnosisBlock.IsActive === 0 ? true : false,
      FormularyId:FormularyId,

    };
    dispatch(updateStatusFormularyIcd10DiagnosisBlocksApi(payload, handleCloseInactive));
  }, [updateStatusFormularyIcd10DiagnosisBlock]);

  // const handleDeleteScheme = async (sch: IBlocks) => {
  //   const { FormularyId, BlockId } = sch;
  //   try {
  //     dispatch(globalLoaderStart());
  //     await deleteIcdBlock(FormularyId, BlockId);
  //     // setInputValue('');
  //     batch(() => {
  //       dispatch(schemeDetail(String(FormularyId)));
  //     });
  //   } catch (err) {
  //     showAlert(2);
  //     dispatch(globalLoaderEnd());
  //   }
  // };

  // const handleOpenViewAll = () => {
  //   setOpenViewAll(true);
  // };

  // const handleCloseViewAll = () => {
  //   setOpenViewAll(false);
  // };
  // eslint-disable-next-line no-console
  useEffect(()=>{console.log("blocks from details",Blocks)},[Blocks])

  const handleOpenCopyDialog=()=>{
    setOpenCopyBlockDialog(true)
  }
  const handleCloseCopyDialog=()=>{
    setOpenCopyBlockDialog(false)
    dispatch(resetCopyFromularyBlock())
  }
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };
  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };
  return (
    <>
      <Box sx={{ my: 4 }}>
      <Box style={{ display:"flex", justifyContent:"space-between" }}>
        <Typography variant="h2" sx={{ color: 'primary.main', mb: 1, textAlign:"center" }}>
          ICD10 Code Blocks
        </Typography>
          <SearchTextfield
            placeholder={'Search by Block name, ICD10 Code'}
            inputValue={inputValue}
            handleSearchChange={handleSearchChange}
            handleSearchSubmit={handleSearchSubmit}
            name="searchInput"
          />
        </Box>
        {/* <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: { xs: 'initial', sm: 'center' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1 }
          }}> */}
          {/* <Box sx={{ flex: 1 }}> */}
            {/* <SearchTextfield
              placeholder={'Search by ICD10 Blocks name'}
              inputValue={inputValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              name="searchInput"
            /> */}
          {/* </Box> */}
          {/* <ButtonWrapper onClick={handleOpenBlock} variant="contained" color="primary" sx={{ textTransform: 'none' }}>
            Add ICD10 Code Blocks
          </ButtonWrapper> */}
        {/* </Box> */}
        {/* <Box>
          {localBlock?.length > 0 ? (
            <Box>
              {localBlock?.map((item, index) => {
                if (index >= SCHEME_CODE_VIEW_NUMBER) return null;
                return (
                  <ChipCus
                    sx={{ mt: 0.8, mr: 0.5 }}
                    key={item.BlockId}
                    label={item.BlockName}
                    color="primary"
                    onDelete={() => handleDeleteScheme(item)}
                  />
                );
              })}
              {localBlock.length > SCHEME_CODE_VIEW_NUMBER && (
                <Typography
                  sx={{
                    display: 'inline',
                    verticalAlign: 'bottom',
                    textDecoration: 'underline',
                    color: 'primary.main',
                    cursor: 'pointer'
                  }}
                  onClick={handleOpenViewAll}>
                  View more
                </Typography>
              )}
            </Box>
          ) : (
            <Typography variant="h3" sx={{ mt: 1, textAlign: 'center' }}>
              No data found
            </Typography>
          )}
        </Box> */}
        <Box sx={{ mt: 2 }}>
          <SICD10Table
            data={Blocks}
            handleOpenInactive={handleOpenInactive}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            page={calculatePage(Page, ItemOffset)}
            totalRecord={TotalRecords}
            rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            activePage={Page}
            handleOpenCopyDialog={handleOpenCopyDialog}
              />
        </Box>
      </Box>
      <AddIcdBlock open={openBlock} handleClose={handleCloseBlock} />
      {/* <IcdViewAll
        open={openViewAll}
        handleClose={handleCloseViewAll}
        data={localBlock}
        handleDeleteScheme={handleDeleteScheme}
      /> */}
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusFormularyIcd10DiagnosisBlock.IsActive === 0 ? 'Activate' : 'Suspend'}
        paragraph={
          <>
            Are you sure you want to {updateStatusFormularyIcd10DiagnosisBlock.IsActive === 0 ? 'activate' : 'suspend'}
            <br /> the selected block  ?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
      <CopyFormularyBlockMedicines
        open={openCopyBlockDialog}
        handleClose={handleCloseCopyDialog}
        FormularyId={FormularyId}
        InsuranceCompanyId={InsuranceCompanyId}
        />
      
    </>
  );
}

export default IcdCode;
