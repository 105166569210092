import { NotificationListModal } from '../../modal';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';

export const notificationListReducer = (
  state: NotificationListModal = new NotificationListModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.GET_NOTIFICATION_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
