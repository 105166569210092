import { NotificationItem, NotificationPayloadRedux } from '../types/notification.types';

export class NotificationItemModal implements NotificationItem {
  NotificationId = '';
  SenderId = 0;
  ReceiverId = 0;
  EventClass = 0;
  EventName = 0;
  EntityId = 0;
  Title = '';
  Description = '';
  IsRead = false;
  IsActive = false;
  CreatedAt = '';
  CreatedBy = 0;
  ModifiedAt = null;
  ModifiedBy = '';
  SenderName = '';
  SenderImage = '';
}

export class NotificationListModal implements NotificationPayloadRedux {
  TotalRecords = 0;
  Page = 1;
  Notifications: NotificationItem[] = [];
  NextPage = false;
  Limit = 10;
  TotalCount = 0;
  PrevEntityId = 0;
}
