import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import AutocomFormularyWrap from '../../components/form-ui/autocom.wrap/autocom.formularywrap';
import TextFieldWrapper from '../../components/form-ui/textfield-wrapper/text.field.wrapper';
import ModalWrapper from '../../components/modal-wrapper/modal.wrapper';
import { ReducersModal } from '../../modal';
import showAlert from '../../utils/alert';
import { convertAddFormularyPayload } from '../../utils/common.function';
import FORM_FIELD from '../../validations/form-field';
import SCHEMA from '../../validations/schema';
import { addFormulary, getSchemeSpecificFormularyList, updateFormulary } from './action';

type AddFormularyProps = {
  open: boolean;
  handleClose: () => void;
};

function AddFormulary({ open, handleClose }: AddFormularyProps) {
  const [initialValue, setInitialValue] = useState(FORM_FIELD.addFormulary);
  const dispatch = useDispatch();
  const { type, formularyId, formularyName, insuranceCompany } = useSelector(
    (state: ReducersModal) => state.addFormularyReducer
  );
  const { Page, ItemOffset } = useSelector((state: ReducersModal) => state.manageSchemeSpecificFormularyReducer);

  useEffect(() => {
    if (formularyId || formularyName)
      setInitialValue({
        formularyName: formularyName,
        insuranceCompany: {
          InsuranceCompanyId: insuranceCompany.InsuranceCompanyId,
          Name: insuranceCompany.Name,
          LogoURL: insuranceCompany.LogoURL
        }
      });
    else
      setInitialValue({
        formularyName: '',
        insuranceCompany: {
          InsuranceCompanyId: 0,
          Name: '',
          LogoURL: null
        }
      });
  }, [formularyId, formularyName]);
  const handleSubmitBlock = (
    value: typeof FORM_FIELD.addFormulary,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.addFormulary>
  ) => {
    if (value.insuranceCompany.InsuranceCompanyId === 0) {
      showAlert(2, 'Please select Insurance Company');
      setSubmitting(false);
      return;
    }
    const payload = convertAddFormularyPayload(value, type, formularyId);
    if (type === 'edit') {
      updateFormulary({ FormularyId: formularyId as number, Name: value.formularyName, InsuranceCompanyId: value.insuranceCompany.InsuranceCompanyId  })
        .then(() => {
          showAlert(1, 'You have successfully edited a Formulary');
          setSubmitting(false);
          dispatch(
            getSchemeSpecificFormularyList({
              page: Page,
              limit: ItemOffset
            })
          );
          handleClose();
        })
        .catch(() => {
          setSubmitting(false);
        });
    } else {
      addFormulary(payload)
        .then(() => {
          showAlert(1, 'You have successfully added a new Formulary');
          setSubmitting(false);
          dispatch(
            getSchemeSpecificFormularyList({
              page: Page,
              limit: ItemOffset
            })
          );
          handleClose();
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: { xs: 300, sm: 850 },
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <Box>
        <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
          <Typography variant="h1" sx={{ color: 'common.white' }}>
            {type === 'add' ? 'Add ' : 'Edit'} Formulary
          </Typography>
        </Box>
        <Formik
          initialValues={initialValue}
          validationSchema={SCHEMA.addFormulary}
          onSubmit={handleSubmitBlock}
          enableReinitialize>
          {({ isSubmitting }) => {
            return (
              <Form>
                <Grid container sx={{ p: 2 }} spacing={1.5}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <TextFieldWrapper
                        sx={{ mb: 2 }}
                        fullWidth
                        name="formularyName"
                        label="Add Formulary Name*"
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <AutocomFormularyWrap name="insuranceCompany" label="Select Insurance Company" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <ButtonWrapper
                        variant="outlined"
                        sx={{
                          color: (theme) => theme.palette.error.light,
                          flexBasis: '48%',
                          borderColor: (theme) => theme.palette.error.light,
                          '&:hover': {
                            borderColor: (theme) => theme.palette.error.light
                          }
                        }}
                        onClick={handleClose}
                        disabled={isSubmitting}>
                        Cancel
                      </ButtonWrapper>
                      <ButtonWrapper
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ flexBasis: '48%' }}
                        disabled={isSubmitting}>
                        {isSubmitting ? 'Loading...' : type === 'add' ? 'Add ' : 'Update'}
                      </ButtonWrapper>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalWrapper>
  );
}

export default AddFormulary;
