import { IBlocks, IScheme } from '../types/manage.scheme.specific.formulary';

class SchemeDetailModal {
  InsuranceCompanyName = '';
  InsuranceCompanyLogoURL = '';
  MedicineCount = 0;
  SchemeCount = 0;
  BlockCount = 0;
  FormularyId = 0;
  InsuranceCompanyId = 0;
  Name = '';
  IsActive: 1 | 0 = 1;
  CreatedAt = '';
  CreatedBy = 1;
  ModifiedAt: null | string = null;
  ModifiedBy: null | string = null;
  Blocks: IBlocks[] = [];
  Scheme: IScheme = {
    TotalRecords: 0,
    Limit: 10000,
    Page: 1,
    Schemes: []
  };
}

export { SchemeDetailModal };
