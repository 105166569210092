import { Box, Typography, Paper } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import ButtonWrapper from '../../components/button/button.wrapper';
import TextFieldWrapper from '../../components/form-ui/textfield-wrapper/text.field.wrapper';
import showAlert from '../../utils/alert';
import FORM_FIELD from '../../validations/form-field';
import SCHEMA from '../../validations/schema';
import { login } from './service';
import { LoginResDTO, PlatformEnum } from '../../types/login';
import { AxiosResponseGlobal, Role } from '../../types/global.types';
import CONSTANTS from '../../utils/constants';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ROUTES_NAME from '../../utils/routes.name';
import SESSION_MANAGEMENT, { checkAccessAndRefresh } from '../../utils/session.management';
import { decodeJwtToken } from '../../utils/common.function';
import { useEffect } from 'react';

function Login() {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!search) return;
    if (checkAccessAndRefresh(search)) navigate(ROUTES_NAME.FAQ);
  }, [search]);

  if (SESSION_MANAGEMENT.getAccessToken()) {
    return <Navigate to={ROUTES_NAME.FAQ} />;
  }

  const handleSubmit = async (
    { email, password }: typeof FORM_FIELD.login,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.login>
  ) => {
    try {
      const response: AxiosResponseGlobal<LoginResDTO> = await login({
        PrincipalIdentifierValue: email,
        Password: password,
        PlatformEnumId: PlatformEnum.WEB,
        TokenExpiryTime: '2d'
      });
      const { status, data } = response;
      if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
        const token = data.Data.PrincipalIdentifier.Device.AccessToken;
        const refreshToken = data.Data.PrincipalIdentifier.Device.RefreshToken;
        const userEmail = data.Data.PrincipalIdentifier.PrincipalIdentifierValue;
        const decode = decodeJwtToken(token);
        if (decode?.userRoleId === Role.Blog || decode?.userRoleId === Role.SuperAdmin) {
          SESSION_MANAGEMENT.setLocalStorageKeyData('userEmail', userEmail);
          SESSION_MANAGEMENT.setLocalStorageKeyData('accessToken', token);
          SESSION_MANAGEMENT.setLocalStorageKeyData('refreshToken', refreshToken);
          SESSION_MANAGEMENT.setLocalStorageKeyData('userRoleId', decode.userRoleId);
          showAlert(1, "You're logged in successfully");
          navigate(ROUTES_NAME.FAQ);
        } else {
          showAlert(2, 'User not valid');
        }
      }
      setSubmitting(false);
    } catch (err) {
      showAlert(2, 'Invalid credentials. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        bgcolor: (theme) => theme.palette.secondary.light
      }}>
      <Box component={Paper} sx={{ p: 2, maxWidth: (theme) => theme.spacing(38) }}>
        <Typography variant="h2">Welcome to Livia Blog Admin</Typography>
        <Typography variant="h1" color="primary" sx={{ my: 1, fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Login
        </Typography>
        <Formik initialValues={FORM_FIELD.login} validationSchema={SCHEMA.login} onSubmit={handleSubmit}>
          {({ isSubmitting }) => {
            return (
              <Form>
                <Box sx={{ my: 2 }}>
                  <TextFieldWrapper name="email" label="Email" size="small" fullWidth type="email" />
                </Box>
                <Box sx={{ my: 2 }}>
                  <TextFieldWrapper name="password" label="Password" size="small" fullWidth type="password" />
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <ButtonWrapper type="submit" disabled={isSubmitting} variant="contained">
                    {isSubmitting ? 'Loading...' : 'Login'}
                  </ButtonWrapper>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
}

export default Login;
