import { Box, DialogContent, Typography } from '@mui/material';
import ButtonWrapper from '../../components/button/button.wrapper';
import ChipCus from '../../components/chip/chip.cus';
import SearchTextfield from '../../components/search-textfield/search.textfield';
import { Block} from '../../types/manage.icd10.diagnosis.block';
import { Formulary } from '../../types/manage.scheme.specific.formulary';
import { TIndex } from '../manage-icd10-diagnosis/copy.block';

type ViewAllProps = {
  type: 'scheme' | 'block'| 'formulary';
  index: TIndex;
  heading: string;
  handleChangeIndex: (index: TIndex) => void;
  bradcrumb: {
    link: string;
  };
  placeholder: string;
  inputValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (event: React.SyntheticEvent) => void;
  handleDelete?: (scheme:{ Name: string })=>void;
  handleDeleteBlock?: (block:Block)=> void;
  handleDeleteFormulary?: (formulary:Formulary)=> void;
  blocks?: Block[];
  schemes?:{Name:string}[];
  formulary?:Formulary[]
};

function SchemeViewAll(props: ViewAllProps) {
  const {
    type,
    heading,
    handleChangeIndex,
    placeholder,
    inputValue,
    handleSearchChange,
    handleSearchSubmit,
    handleDelete,
    handleDeleteBlock,
    handleDeleteFormulary,
    schemes,
    blocks,
    formulary
  } = props;
  return (
    <Box>
      <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
        <Typography variant="h1" sx={{ color: 'common.white' }}>
          {heading}
        </Typography>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
            mb: 1
          }}>
          <ButtonWrapper onClick={() => handleChangeIndex(0)} variant="contained" color="primary">
            Back
          </ButtonWrapper>
          {type === 'scheme' && <SearchTextfield
            sx={{ border: '2px solid', borderColor: 'primary.main', width: '50%' }}
            placeholder={placeholder}
            inputValue={inputValue}
            handleSearchChange={handleSearchChange}
            handleSearchSubmit={handleSearchSubmit}
            name="searchInput"
          />}
        </Box>

        {type === 'block' && blocks && blocks?.length > 0 && (
          <Box>
            {blocks.map((block) => {
              return (
                <ChipCus
                  sx={{ mt: 0.8, mr: 0.5 }}
                  key={block.BlockId}
                  label={`${block.Name}-(${block.InsuranceCompanyName})`}
                  color="primary"
                  onDelete={()=>{handleDeleteBlock?.(block)}}
                />
              );
            })}
          </Box>
        )}
        {type === 'scheme' && schemes && schemes?.length > 0 && (
          <Box>
            {schemes.map((scheme) => {
              return (
                <ChipCus
                  sx={{ mt: 0.8, mr: 0.5 }}
                  key={scheme?.Name}
                  label={
                    <Typography>
                      {scheme?.Name}
                    </Typography>
                  }
                  color="primary"
                  onDelete={() => {
                    handleDelete?.(scheme)
                  }}
                />
              );
            })}
          </Box>
          )}
          {type === 'formulary' && formulary && formulary?.length > 0 && (
            <Box>
              {formulary.map((item) => {
                return (
                  <ChipCus
                    sx={{ mt: 0.8, mr: 0.5 }}
                    key={item.FormularyId}
                    label={`${item.Name}`}
                    color="primary"
                    onDelete={()=>{handleDeleteFormulary?.(item)}}
                  />
                );
              })}
            </Box>
          )}
        
      </DialogContent>
    </Box>
  );
}

export default SchemeViewAll;
