import moment from 'moment';
import { CsvQuestionData, Question } from '../types/faq.types';
import {
  AnswerReportReasonEnum,
  CheckReportReasone,
  DoctorPointLogActionTypeEnum,
  JwtDecodeT,
  RecommendationReportReasonEnum,
  ReportListType,
  SingleGenerics
} from '../types/global.types';
import FORM_FIELD from '../validations/form-field';
import showAlert from './alert';
import { SEARCH_BY } from './static.data';
import { CsvDoctorVlogData } from '../types/doctor.volg.types';
import { CsvDoctorRecommendationData, RecommendationItem } from '../types/doctor.recommendation.types';
import { CategoriesCvs, CategoriesType } from '../types/manage.category.types';
import jwtDecode from 'jwt-decode';
import SESSION_MANAGEMENT from './session.management';
import {
  Diagnosis,
  IAddOrderOFTier,
  IcdMedicineList,
  IDiagnosis,
  IMedicines,
  TCopyBlockPayload
} from '../types/manage.icd10.diagnosis.block';

export const checkOnline = () => {
  if (!navigator.onLine) return showAlert(3, 'PLEASE CHECK INTERNET');
};

export const formatDateAndTime: SingleGenerics<string> = (date) => {
  return moment(date).format('DD MMMM YYYY');
};

export const formatDateAndTimeForfilter: SingleGenerics<string> = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const trimString = (str: string, sortNumber: number): string => {
  return str.length > sortNumber ? `${str.substring(0, sortNumber)}` : str;
};

export const trimStringICD = (str: string, sortNumber: number): string => {
  return str.length > sortNumber ? `${str.substring(0, sortNumber)}+` : str;
};

export const calculatePage = (Page: number, ItemOffset: number): number => {
  return (Page - 1) * ItemOffset + 1;
};

export const calculateRowPerPage = (Page: number, ItemOffset: number, TotalRecords: number): number => {
  return (Page - 1) * ItemOffset + ItemOffset > TotalRecords
    ? TotalRecords % ((Page - 1) * ItemOffset + ItemOffset)
    : (Page - 1) * ItemOffset + ItemOffset;
};

export const findlPointsListAction = (action: number): string => {
  if (DoctorPointLogActionTypeEnum.LIKE_ANSWER === action) return 'Like';
  else if (DoctorPointLogActionTypeEnum.DISLIKE_ANSWER === action) return 'Dislike';
  else if (DoctorPointLogActionTypeEnum.CREATE_ANSWER === action) return 'Answer Posted';
  else return 'N/A';
};

export const getCurrentDate = () => {
  return new Date();
};

export const checkRecommendationReportReasone: CheckReportReasone<number, string, boolean> = (report) => {
  let reason: string | boolean;
  if (RecommendationReportReasonEnum.INCORRECT_RECOMMENDATION === report) reason = 'Incorrect recommendation';
  else if (RecommendationReportReasonEnum.BAD_EXPERIENCE_WHEN_FOLLOWED === report)
    reason = 'Bad experience when followed';
  else if (RecommendationReportReasonEnum.RECOMMENDATION_IS_NOT_SUITABLE === report)
    reason = 'Recommendaton is not sutable';
  else if (RecommendationReportReasonEnum.OTHER) reason = false;
  else reason = false;
  return reason;
};

export const checkAnswersReportReasone: CheckReportReasone<number, string, boolean> = (report) => {
  let reason: string | boolean;
  if (AnswerReportReasonEnum.INCORRECT_ANSWER === report) reason = 'Incorrect answer';
  else if (AnswerReportReasonEnum.BAD_EXPERIENCE_WHEN_FOLLOWED === report) reason = 'Bad experience when followed';
  else if (AnswerReportReasonEnum.ANSWER_IS_NOT_SUITABLE === report) reason = 'Answer is not sutable';
  else if (AnswerReportReasonEnum.OTHER === report) reason = false;
  else reason = false;
  return reason;
};

export const getReportReason = (report: number, type: ReportListType): string | boolean => {
  if (type === 'recommendationReport') return checkRecommendationReportReasone(report);
  else if (type === 'answersReport') return checkAnswersReportReasone(report);
  return false;
};

export const getSearchPlaceHolderFaq: SingleGenerics<string> = (value) => {
  let newValue = SEARCH_BY;
  if (value === '1') newValue = `${newValue} Question, Description`;
  if (value === '2') newValue = `${newValue} User`;
  if (value === '3') newValue = `${newValue} Question, User, Description`;
  return newValue;
};

export const getSearchPlaceHolderRecommendation: SingleGenerics<string> = (value) => {
  let newValue = SEARCH_BY;
  if (value === '1') newValue = `${newValue} Recommendation`;
  if (value === '2') newValue = `${newValue} User`;
  if (value === '3') newValue = `${newValue} Recommendation, Doctor`;
  return newValue;
};

export const getSearchPlaceHolderVlog: SingleGenerics<string> = (value) => {
  let newValue = SEARCH_BY;
  if (value === '1') newValue = `${newValue} Vlog`;
  if (value === '2') newValue = `${newValue} Doctor`;
  if (value === '3') newValue = `${newValue} Vlog, Doctor`;
  return newValue;
};

export const checkFilterApplyOrNot = ({ fromDate, toDate, category }: typeof FORM_FIELD.filterListing): boolean => {
  if (category || (fromDate && toDate)) return true;
  else return false;
};

export const checkIcd10BlockFilterApplyOrNot = ({
  fromDate,
  toDate,
  insuranceCompanyId,
  statusId
}: typeof FORM_FIELD.icd10DiagnosisFilterListing): boolean => {
  if (insuranceCompanyId || statusId === '1' || statusId === '0' || (fromDate && toDate)) return true;
  else return false;
};

export const checkSchemeSpecificFilterApplyOrNot = ({
  fromDate,
  toDate,
  insuranceCompanyId,
  statusId
}: typeof FORM_FIELD.schemeSpecificFilterListing): boolean => {
  if (insuranceCompanyId || statusId === '1' || statusId === '0' || (fromDate && toDate)) return true;
  else return false;
};

export const convertQuestionCsvData = (questions: Question[]) => {
  const newQuestionArr: CsvQuestionData[] = [];
  if (questions?.length > 0) {
    let num = 1;
    for (const question of questions) {
      const newQuestion = {
        SrNo: num,
        Questions: question.Text ?? 'N/A',
        Description: question.Description ?? 'N/A',
        Category: question.CategoryName ?? 'N/A',
        AddedBy: question.PatientName ?? 'N/A',
        Answers: question.AnswerCount ?? 'N/A',
        ReportCountOnAnswers: question.ReportCount ?? 'N/A',
        AddedOn: formatDateAndTime(question.CreatedAt)
      };
      num++;
      newQuestionArr.push(newQuestion);
    }
  }
  return newQuestionArr;
};

export const convertVlogCsvData = (recommendations: RecommendationItem[]) => {
  const newRecommendationArr: Readonly<CsvDoctorVlogData>[] = [];
  if (recommendations?.length > 0) {
    let num = 1;
    for (const recommendation of recommendations) {
      const newRecommendation = {
        SrNo: num,
        BlogTitle: recommendation.Description ?? 'N/A',
        DoctorName: recommendation.DoctorName ?? 'N/A',
        PhoneNumber:
          `+${recommendation.DoctorPhoneCode ? recommendation.DoctorPhoneCode : ''}${
            recommendation.DoctorPhoneNumber ? recommendation.DoctorPhoneNumber : ''
          }` ?? 'N/A',
        Category: recommendation.CategoryName ?? 'N/A',
        ReportCount: recommendation.ReportCount ?? 'N/A',
        AddedOn: formatDateAndTime(recommendation?.CreatedAt)
      };
      num++;
      newRecommendationArr.push(newRecommendation);
    }
  }
  return newRecommendationArr;
};

export const convertRecommendationCsvData = (recommendations: RecommendationItem[]) => {
  const newRecommendationArr: Readonly<CsvDoctorRecommendationData>[] = [];
  if (recommendations?.length > 0) {
    let num = 1;
    for (const recommendation of recommendations) {
      const newRecommendation = {
        SrNo: num,
        Content: recommendation.Text ?? 'N/A',
        DoctorName: recommendation.DoctorName ?? 'N/A',
        PhoneNumber:
          `+${recommendation.DoctorPhoneCode ? recommendation.DoctorPhoneCode : ''}${
            recommendation.DoctorPhoneNumber ? recommendation.DoctorPhoneNumber : ''
          }` ?? 'N/A',
        Category: recommendation.CategoryName ?? 'N/A',
        ReportCount: recommendation.ReportCount ?? 'N/A',
        AddedOn: formatDateAndTime(recommendation?.CreatedAt)
      };
      num++;
      newRecommendationArr.push(newRecommendation);
    }
  }
  return newRecommendationArr;
};

export const convertCategoryCsvData = (categories: CategoriesType[]) => {
  const newCategoryArr: CategoriesCvs[] = [];
  if (categories?.length > 0) {
    let num = 1;
    for (const category of categories) {
      const newCategory = {
        SrNo: num,
        Category: category?.Name,
        AddedOn: formatDateAndTime(category?.CreatedAt)
      };
      num++;
      newCategoryArr.push(newCategory);
    }
  }
  return newCategoryArr;
};

export const getNotificationTimeStatus = (date: string) => {
  const now = moment(new Date());
  const duration = moment.duration(now.diff(date));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  if (days) return `${days} day ago`;
  if (hours) return `${hours} hour ago`;
  if (minutes) return `${minutes} min ago`;
  if (seconds) return `${seconds} sec ago`;
  return '';
};

export const decodeJwtToken = (token: string) => {
  return jwtDecode<JwtDecodeT>(token);
};

export const getLSData = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) return data;
  else return false;
};

export const whichPortalRun = () => {
  const userRoleId = getLSData('userRoleId');
  if (!userRoleId) {
    SESSION_MANAGEMENT.removeSession();
    window.location.href = '/';
  }
  return String(userRoleId);
};

export const convertAddBlockPayload = (
  { blockName, insuranceCompany }: typeof FORM_FIELD.addBlock,
  icd: IDiagnosis[],
  type: string,
  blockId: number | null
) => {
  const newIcd = [];
  for (const element of icd) {
    newIcd.push(element.DiagnosisId);
  }
  const payload = {
    Name: blockName.trim(),
    InsuranceCompanyId: insuranceCompany.InsuranceCompanyId,
    Diagnosis: newIcd
  };
  if (type === 'edit' && blockId) return { ...payload, BlockId: blockId, IsActive: true };
  else return payload;
};

export const convertAddFormularyPayload = (
  { formularyName, insuranceCompany }: typeof FORM_FIELD.addFormulary,
  type: string,
  formularyId: number | null
) => {
  const payload = {
    Name: formularyName.trim(),
    InsuranceCompanyId: insuranceCompany.InsuranceCompanyId
  };
  if (type === 'edit' && formularyId) return { ...payload, FormularyId: formularyId };
  else return payload;
};

export const convertDiagosis = (value: Diagnosis[]) => {
  const newValue = [];
  for (const element of value) {
    newValue.push({
      DiagnosisId: element.DiagnosisId,
      Code: element.DiagnosisCode,
      Description: element.DiagnosisDescription,
      InsuranceCompanyId: null
    });
  }
  return newValue;
};

export const convertAddMedicinePayload = (blockId: number, medicine: IcdMedicineList[]) => {
  const newMedcine = [];
  for (const element of medicine) {
    newMedcine.push({
      MedicineId: element.MedicineId,
      OrderOfTier: Number(element.orderOfTier)
    });
  }
  return {
    BlockId: blockId,
    Medicines: newMedcine
  };
};

export const convertCopyPayload = ({ insuranceCompanyId, name, diagnosis, medicine }: TCopyBlockPayload) => {
  const newIcd = [];
  for (const element of diagnosis) {
    newIcd.push(element.DiagnosisId);
  }
  const newMedcine = [];
  for (const element of medicine) {
    newMedcine.push({
      MedicineId: element.MedicineId,
      OrderOfTier: Number(element.OrderOfTier)
    });
  }
  return {
    InsuranceCompanyId: insuranceCompanyId,
    Name: name,
    Diagnosis: newIcd,
    ...(newMedcine.length > 0 && { Medicines: newMedcine })
  };
};

export const holdMedicineTier = (tier: IMedicines[]) => {
  const newTier: { [index: number]: number } = {};
  for (const element of tier) {
    newTier[element.MedicineId] = element.OrderOfTier;
  }
  return newTier;
};

export const addOrderOFTier = (medicine: IAddOrderOFTier, tier: IMedicines[]) => {
  const newMedicine = [];
  for (const element of medicine) {
    const found = tier.find((item) => item.MedicineId === element.MedicineId);
    if (found) {
      newMedicine.push({
        ...element,
        OrderOfTier: found.OrderOfTier
      });
    }
  }
  return newMedicine;
};

export const convertGetCopyBlockDataWhenInsurenceCompanyChagnePayload = ({
  insuranceCompanyId,
  diagnosis,
  medicine
}: {
  insuranceCompanyId: number;
  diagnosis: Diagnosis[];
  medicine: IMedicines[];
}) => {
  const newIcd = [];
  for (const element of diagnosis) {
    newIcd.push(element.DiagnosisId);
  }
  const newMedcine = [];
  for (const element of medicine) {
    newMedcine.push(element.MedicineId);
  }
  return {
    InsuranceCompanyId: insuranceCompanyId,
    Diagnosis: newIcd,
    Medicines: newMedcine
  };
};

export const nameRegex = /^[a-zA-Z ]{2,30}$/;

export const convertAddSchemeIcdMedicinePayload = (formularyId: number, medicine: IcdMedicineList[], blockId: number) => {
  const newMedcine = [];
  for (const element of medicine) {
    newMedcine.push({
      MedicineId: element.MedicineId,
      OrderOfTier: Number(element.orderOfTier)
    });
  }
  return {
    BlockId: blockId,
    FormularyId: formularyId,
    Medicines: newMedcine
  };
};

export function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}
