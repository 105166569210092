import { ManageIcd10DiagnosisBlocksModal, AddBlock, IcdDetail, IcdMedicine, CopyBlock } from '../../modal';
import { CopyICD10Block } from '../../modal/manage.icd10.diagnosis';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';

export const manageIcd10DiagnosisBlocksReducer = (
  state: ManageIcd10DiagnosisBlocksModal = new ManageIcd10DiagnosisBlocksModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.MANAGE_ICD10_DIAGNOSIS_BLOCKS_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const addBlockReducer = (state: AddBlock = new AddBlock(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.ADD_BLOCK:
      return { ...state, ...action.payload };
    case ACTION_NAME.RESET_ADD_BLOCK:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const icdDetailReducer = (state: IcdDetail = new IcdDetail(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.ICD_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const icdMedicineReducer = (state: IcdMedicine = new IcdMedicine(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.ICD_MEDICINE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const copyBlockReducer = (state: CopyBlock = new CopyBlock(), action: any) => {
  switch (action.type) {
    case ACTION_NAME.COPY_BLOCK:
      return { ...state, ...action.payload };
    case ACTION_NAME.DELETE_COPY_ICD10: {
      const filterIcd = state.copyIcd10.filter((item) => item.DiagnosisCode !== action.payload.DiagnosisCode);
      return { ...state, copyIcd10: filterIcd };
    }
    case ACTION_NAME.DELETE_COPY_MEDICINE: {
      const filterMedicine = state.copyMedicine.filter((item) => item.MedicineId !== action.payload.MedicineId);
      return { ...state, copyMedicine: filterMedicine };
    }
    default:
      return state;
  }
};

export const copyICD10BlockReducer = (state:CopyICD10Block = new CopyICD10Block(), action: any)=>{
  switch(action.type) {
    case ACTION_NAME.COPY_ICD10_BLOCK:
      return {...state, ...action.payload};
    case ACTION_NAME.RESET_COPY_ICD10_BLOCK:
      return {...state, ...action.payload};  
    default:
      return state;   
  }
}
