import { Autocomplete } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/form-ui/textfield-wrapper/input.field";
import useDebounce from "../../hooks/use.debounce";
import { ReducersModal } from "../../modal";
import { Block } from "../../types/manage.icd10.diagnosis.block";
import ACTION_NAME from "../../utils/action.name";
import showAlert from "../../utils/alert";
import { getICD10BlockListForAutoComplete } from "./action";

type AutocomBlockProps = {
    label: string;
    };
function AutocomIcdBlock({label}:AutocomBlockProps) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Block[]>([]);
    const [inputValue, setInputValue] = useState('');
    const debounceValue = useDebounce(inputValue, 500);
    const {FromBlockIds, ToBlock} = useSelector((state:ReducersModal)=> state.copyICD10BlockReducer)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);
      useEffect(() => {
        let active = true;
        if (!debounceValue || debounceValue.length < 3) {
          return undefined;
        }
        (async () => {
            try {
              const res = await getICD10BlockListForAutoComplete({
                page: 1,
                limit: 20,
                search: debounceValue,
                });
              if (active) {
                const newBlock: Block[] = [];
                for (const ele of res.data.Data.Blocks) {
                    newBlock.push({
                    ...ele,
                    inputV: debounceValue,
                    });
          }
          setOptions(newBlock.filter((block)=> !FromBlockIds.includes(block.BlockId)));
              }
            } catch (_err) {
              showAlert(2);
            }
          })();
      
          return () => {
            active = false;
          };
    
        }, [debounceValue]);  
    return (
        <Autocomplete
            // filterOptions={filterOptions}
            size="small"
            noOptionsText={debounceValue.length >= 3 ? 'No Block found' : 'Type at least 3 characters'}
            onChange={(_e, newValue) => {
            if (newValue) {
            // eslint-disable-next-line no-console
            console.log(newValue)
            dispatch({
                type:ACTION_NAME.COPY_ICD10_BLOCK,
                payload:{
                    ToBlock:[...ToBlock, newValue],
                    FromBlockIds:[...FromBlockIds, newValue.BlockId]
                }
            })
            }}}
            onInputChange={(_event, newInputValue, reason) => {
            if (reason === 'reset') setInputValue('');
            else setInputValue(newInputValue);
            }}
            
            inputValue={inputValue}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => `${option.Name}-${option.InsuranceCompanyName}`}
            options={options}
            renderInput={(params) => (
                <InputField
                {...params}
                label={label}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                    autoComplete: 'off'
                }}
                />
            )}
            />)
  }
export default AutocomIcdBlock