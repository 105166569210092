import { useCallback, useEffect, useState } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import ButtonWrapper from '../../components/button/button.wrapper';
import SearchTextfield from '../../components/search-textfield/search.textfield';
import AddCategoryModal from './add.category.modal';
import CategoryTable from './category.table';
import SuccessModal from '../../components/modal-wrapper/success.modal';
import InactiveModal from '../../components/modal-wrapper/inactive.modal';
import { useDispatch, useSelector } from 'react-redux';
import { getManageCategory, updateStatusCategoryApi } from './action';
import { ReducersModal } from '../../modal';
import { CategoriesCvs, CategoriesType, CategoryTypeAndData } from '../../types/manage.category.types';
import { CSVLink } from 'react-csv';
import { convertCategoryCsvData } from '../../utils/common.function';
import SelectWrapper from '../../components/form-ui/select-wrapper/select.wrapper';
import { SELECT_MENU_ITEM } from '../../utils/static.data';

function ManageCategory() {
  const dispatch = useDispatch();
  const { Categories } = useSelector((state: ReducersModal) => state.manageCategoryReducer);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [categoryTypeAndData, setCategoryTypeAndData] = useState({} as CategoryTypeAndData);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openInactive, setOpenInactive] = useState(false);
  const [updateStatusCategory, setUpdateStatusCategory] = useState({} as CategoriesType);
  const [csvCategoryData, setcsvCategoryData] = useState([] as CategoriesCvs[]);
  const [showSort, setShowSort] = useState<number>(10);

  const [currentItems, setCurrentItems] = useState([] as CategoriesType[]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    dispatch(getManageCategory());
  }, [dispatch]);

  useEffect(() => {
    if (inputValue === '') setcsvCategoryData(convertCategoryCsvData(Categories));
    else setcsvCategoryData(convertCategoryCsvData(currentItems));
  }, [Categories, inputValue, currentItems]);

  useEffect(() => {
    if (inputValue !== '') {
      searchingByName(inputValue);
    } else {
      const endOffset = itemOffset + Number(showSort);
      const CategoriesReverse = [...Categories].reverse();
      const CategoriesSlice = CategoriesReverse.slice(itemOffset, endOffset);
      setCurrentItems(CategoriesSlice);
      setPageCount(Math.ceil(Categories.length / Number(showSort)));
    }
  }, [itemOffset, showSort, Categories, inputValue]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * Number(showSort)) % Categories.length;
    setItemOffset(newOffset);
  };

  const handleOpenInactive = (editData: CategoriesType) => {
    setUpdateStatusCategory(editData);
    setOpenInactive(true);
  };
  const handleCloseInactive = useCallback(() => {
    setOpenInactive(false);
  }, []);
  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccess = useCallback(() => {
    setOpenSuccess(false);
  }, []);

  const handleOpenAddCategory = (type: string, editData?: CategoriesType) => {
    if (type === 'edit' && editData) setCategoryTypeAndData({ ...categoryTypeAndData, type, editData });

    if (type === 'add') setCategoryTypeAndData({ ...categoryTypeAndData, type, editData: null });
    setOpenAddCategory(true);
  };

  const handleCloseAddCategory = useCallback(() => {
    setOpenAddCategory(false);
  }, []);

  const handleConfirmInactive = useCallback(() => {
    if (!updateStatusCategory?.CategoryId) return;
    dispatch(
      updateStatusCategoryApi(
        {
          CategoryId: updateStatusCategory.CategoryId,
          IsActive: updateStatusCategory.IsActive === true ? false : true
        },
        handleCloseInactive
      )
    );
  }, [updateStatusCategory, dispatch, handleCloseInactive]);

  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    if (inputValue !== '') setInputValue('');
    if (event.target.value) setShowSort(Number(event.target.value));
    setItemOffset(0);
  };

  const searchingByName = (keyword: string) => {
    if (keyword !== '') {
      const results = Categories.filter((item: CategoriesType) => {
        return item.Name.toLowerCase().includes(keyword.toLowerCase());
      });
      setCurrentItems(results);
      setPageCount(Math.ceil(results.length / Number(showSort)));
    } else {
      setCurrentItems(Categories);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography variant="h1">Manage Category</Typography>
          <ButtonWrapper variant="contained" color="primary" onClick={() => handleOpenAddCategory('add')}>
            Add
          </ButtonWrapper>
        </Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: { xs: 'initial', sm: 'center' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1 }
          }}>
          <Box sx={{ flex: 1 }}>
            <SearchTextfield
              placeholder={'Search by Category name'}
              inputValue={inputValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              name="searchInput"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <Box sx={{ mr: 2 }}>
              <SelectWrapper value={showSort} onChange={handleChangeShowSort} menuItem={SELECT_MENU_ITEM} />
            </Box>
            <CSVLink data={csvCategoryData} filename={'category.csv'}>
              <ButtonWrapper variant="contained" color="primary">
                Export
              </ButtonWrapper>
            </CSVLink>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <CategoryTable
            data={currentItems}
            handleOpenEditCategory={handleOpenAddCategory}
            handleOpenInactive={handleOpenInactive}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            itemOffset={itemOffset}
            totalRecord={Categories.length}
            page={Categories.length >= showSort ? showSort : Categories.length}
          />
        </Box>
      </Box>
      <AddCategoryModal
        open={openAddCategory}
        handleClose={handleCloseAddCategory}
        typeAndData={categoryTypeAndData}
        handleOpenSuccess={handleOpenSuccess}
      />
      <SuccessModal
        open={openSuccess}
        handleClose={handleCloseSuccess}
        heading="Successful!"
        paragraph={
          categoryTypeAndData.type === 'add' ? (
            <>
              You have successfully added a new <br />
              Category
            </>
          ) : (
            <>
              You have successfully edited a <br />
              Category
            </>
          )
        }
      />
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusCategory.IsActive ? 'Inactive?' : 'Active?'}
        paragraph={
          <>
            Are you sure you want to {updateStatusCategory.IsActive ? 'inactivate' : 'activate'} <br /> the selected
            Category?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
}

export default ManageCategory;
