import { Box, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import ButtonWrapper from "../../../components/button/button.wrapper"
import ChipCus from "../../../components/chip/chip.cus"
import ModalWrapper from "../../../components/modal-wrapper/modal.wrapper"
import { ReducersModal } from "../../../modal"
import { Block } from "../../../types/manage.icd10.diagnosis.block"
import ACTION_NAME from "../../../utils/action.name"
import showAlert from "../../../utils/alert"
import { copyFormularyBlock, getFormularyIcd10DiagnosisBlocksList } from "../action"
import AutocomBlock from "./autocom.block"
type CopyFormularyBlockMedicineProps={
    open:boolean;
    handleClose: ()=>void;
    FormularyId:number;
    InsuranceCompanyId:number;
}

function CopyFormularyBlockMedicines({open, handleClose, FormularyId, InsuranceCompanyId}:CopyFormularyBlockMedicineProps) {
    const dispatch = useDispatch()
    const { ToBlocks, FromBlockId, FromBlock} = useSelector((state:ReducersModal)=>state.copySchemeSpecificFormularyBlockReducer)
    const {
        Page,
        ItemOffset,
        } = useSelector((state: ReducersModal) => state.manageFormularyIcd10DiagnosisBlocksReducer);
    const deleteSelectedItem=(item:Block)=>{
        const toBlocks = ToBlocks.filter(block => item.BlockId !== block.BlockId)
        const fromBlockId = FromBlockId.filter(block=> item.BlockId !== block)
        dispatch({
            type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK,
            payload:{
                ToBlocks: toBlocks,
                FromBlockId: fromBlockId
                
            }
        })
    }
    const Proceed = ()=>{
        const payload={
            FormularyId:FormularyId,
            BlockId: FromBlock,
            Blocks:ToBlocks.map((block)=> {return block.BlockId})

        }
        copyFormularyBlock(payload)
        .then(()=>{
            showAlert(1, "Success")
            dispatch(
                getFormularyIcd10DiagnosisBlocksList({
                  page: Page,
                  limit: ItemOffset,
                  ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
                  ...({formularyId:FormularyId}),
                   })
              );

            handleClose()
        })
        .catch(()=> {showAlert(2), handleClose()})
    }
    return (
        <ModalWrapper
        open={open}
        handleClose={handleClose}
        sx={{
            width: { xs: 300, sm: 650 },
            boxShadow: 'var(--link-box-shadow)'
        }}>
            <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
                <Typography variant="h1" sx={{ color: 'common.white' }}>
                    Copy ICD10 block code medicines to other blocks
                </Typography>
            </Box>
            <Box sx={{p: 2}}>
                <AutocomBlock label="Search and select ICD10 code blocks" FormularyId={FormularyId} InsuranceCompanyId={InsuranceCompanyId}/>
                {ToBlocks?.length > 0 && (
                <Box>
                {ToBlocks.map((item) => {
                    return (
                    <ChipCus
                        sx={{ mt: 0.8, mr: 0.5 }}
                        key={item.Name}
                        label={item.Name}
                        color="primary"
                        onDelete={()=>{deleteSelectedItem(item)}}
                    />
                    );
                })}
                </Box>
            )}
            </Box>
            <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 2,
                py:"10px"
                }}>
            <ButtonWrapper
                variant="outlined"
                sx={{
                color: (theme) => theme.palette.error.light,
                px: { xs: 2, sm: 5 },
                mr: 1,
                py: 1,
                borderColor: (theme) => theme.palette.error.light,
                '&:hover': {
                    borderColor: (theme) => theme.palette.error.light
                }
                }}
                onClick={handleClose}>
                Cancel
            </ButtonWrapper>
            <ButtonWrapper
                variant="contained"
                color="primary"
                sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
                onClick={Proceed}>
                Proceed
            </ButtonWrapper>
            </Box>
        </ModalWrapper>
    )
}

export default CopyFormularyBlockMedicines