import { memo, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ModalWrapper from '../../components/modal-wrapper/modal.wrapper';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TextFieldWrapper from '../../components/form-ui/textfield-wrapper/text.field.wrapper';
import { Form, Formik, FormikHelpers } from 'formik';
import FORM_FIELD from '../../validations/form-field';
import SCHEMA from '../../validations/schema';
import ButtonWrapper from '../../components/button/button.wrapper';
import { CategoryTypeAndData } from '../../types/manage.category.types';
import { useDispatch } from 'react-redux';
import { addCategory, getManageCategory, updateCategory } from './action';
import showAlert from '../../utils/alert';
import CONSTANTS from '../../utils/constants';

type AddCategoryModalProps = {
  open: boolean;
  handleClose: () => void;
  typeAndData: CategoryTypeAndData;
  handleOpenSuccess: () => void;
};

function AddCategoryModal({ open, handleClose, typeAndData, handleOpenSuccess }: AddCategoryModalProps) {
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState(FORM_FIELD.addCategory);
  const { type, editData } = typeAndData;

  useEffect(() => {
    if (type === 'edit' && editData) {
      setInitialValue({
        categoryName: editData?.Name
      });
    } else {
      setInitialValue({
        categoryName: ''
      });
    }
  }, [type, editData]);

  const handleSubmitAddCategory = (value: typeof FORM_FIELD.addCategory, action: FormikHelpers<typeof value>) => {
    const { setSubmitting } = action;

    const afterAddAndEditCompletedSuccess = () => {
      handleClose();
      handleOpenSuccess();
      dispatch(getManageCategory());
    };

    const payload = {
      Name: value.categoryName.trim(),
      ImageURL: null,
      Description: null,
      ParentCategoryId: null,
      ...(type === 'edit' && editData?.CategoryId && { CategoryId: editData?.CategoryId })
    };

    if (type === 'edit' && editData?.CategoryId) {
      updateCategory(payload)
        .then((response) => {
          const { status } = response;
          if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
            afterAddAndEditCompletedSuccess();
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          const { data } = error;
          showAlert(2, data?.Message);
        });
    }

    if (type === 'add') {
      addCategory(payload)
        .then((response) => {
          const { status } = response;
          if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
            afterAddAndEditCompletedSuccess();
          }
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          const { data } = error;
          showAlert(2, data?.Message);
        });
    }
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        p: 2,
        width: { xs: 300, sm: 400 },
        borderRadius: (theme) => theme.spacing(0.8),
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <>
        <Box>
          <CancelOutlinedIcon
            onClick={handleClose}
            color="primary"
            sx={{ cursor: 'pointer', float: 'right' }}
            fontSize="large"
          />
        </Box>
        <Box sx={{ p: 1.5, mt: 3 }}>
          <Typography variant="h3" sx={{ color: 'var( --table-head)' }}>
            {type === 'add' ? 'Add New' : 'Edit'} Category
          </Typography>
          <Formik
            initialValues={initialValue}
            validationSchema={SCHEMA.addCategory}
            onSubmit={handleSubmitAddCategory}
            enableReinitialize>
            {({ isSubmitting }) => {
              return (
                <Form>
                  <TextFieldWrapper
                    sx={{ my: 2 }}
                    fullWidth
                    name="categoryName"
                    label="Enter Category Name*"
                    size="small"
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    <ButtonWrapper
                      variant="outlined"
                      sx={{
                        color: (theme) => theme.palette.error.light,
                        flexBasis: '48%',
                        borderColor: (theme) => theme.palette.error.light,
                        '&:hover': {
                          borderColor: (theme) => theme.palette.error.light
                        }
                      }}
                      onClick={handleClose}
                      disabled={isSubmitting}>
                      Cancel
                    </ButtonWrapper>
                    <ButtonWrapper
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ flexBasis: '48%' }}
                      disabled={isSubmitting}>
                      {isSubmitting ? 'Loading...' : type === 'add' ? 'Add ' : 'Edit'}
                    </ButtonWrapper>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </>
    </ModalWrapper>
  );
}

export default memo(AddCategoryModal);
