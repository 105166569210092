import { createRoot } from 'react-dom/client';
import './index.css';
import App from './app';
import { Providers } from './templates/providers';
import reportWebVitals from './report.web.vitals';

const container = document.getElementById('root');
const root = createRoot(container as HTMLDivElement);
root.render(
  <Providers>
    <App />
  </Providers>
);

reportWebVitals();
