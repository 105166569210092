import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: 'rgba(82, 130, 240, 0.1)'
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#308E99',
            minHeight: 24
            // border: '3px solid #308E99'
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#809E9F'
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#809E9F'
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#809E9F'
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#809E9F'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#809E9F',
      main: '#308E99'
    },
    secondary: {
      light: '#F1F5F7',
      main: '#E8F0F0'
    }
  },
  spacing: 10,
  typography: {
    h1: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '19px',
      fontFamily: 'Roboto Sans, sans-serif',
      lineHeight: '22px',
      color: 'var(--black)'
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      fontFamily: 'Roboto Sans, sans-serif',
      lineHeight: '26px'
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      fontFamily: 'Roboto Sans, sans-serif',
      lineHeight: '100%'
    }
  }
});

theme = responsiveFontSizes(theme, {
  breakpoints: ['xs', 'sm', 'md', 'lg'],
  factor: 3,
  disableAlign: true
});

export default theme;
