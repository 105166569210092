import { Box, DialogContent, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import ChipCus from '../../components/chip/chip.cus';
import SearchTextfield from '../../components/search-textfield/search.textfield';
import { Diagnosis, IMedicines } from '../../types/manage.icd10.diagnosis.block';
import ACTION_NAME from '../../utils/action.name';
import { TIndex } from './copy.block';

type ViewAllProps = {
  type: 'med' | 'icd';
  index: TIndex;
  heading: string;
  handleChangeIndex: (index: TIndex) => void;
  bradcrumb: {
    link: string;
  };
  placeholder: string;
  inputValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (event: React.SyntheticEvent) => void;
  diagnosis?: Diagnosis[];
  medicines?: IMedicines[];
};

function ViewAll(props: ViewAllProps) {
  const {
    type,
    heading,
    handleChangeIndex,
    placeholder,
    inputValue,
    handleSearchChange,
    handleSearchSubmit,
    diagnosis,
    medicines
  } = props;
  const dispatch = useDispatch();
  return (
    <Box>
      <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
        <Typography variant="h1" sx={{ color: 'common.white' }}>
          {heading}
        </Typography>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
            mb: 1
          }}>
          <ButtonWrapper onClick={() => handleChangeIndex(0)} variant="contained" color="primary">
            Back
          </ButtonWrapper>
          <SearchTextfield
            sx={{ border: '2px solid', borderColor: 'primary.main', width: '50%' }}
            placeholder={placeholder}
            inputValue={inputValue}
            handleSearchChange={handleSearchChange}
            handleSearchSubmit={handleSearchSubmit}
            name="searchInput"
          />
        </Box>

        {type === 'icd' && diagnosis && diagnosis?.length > 0 && (
          <Box>
            {diagnosis.map((icd) => {
              return (
                <ChipCus
                  sx={{ mt: 0.8, mr: 0.5 }}
                  key={icd.DiagnosisCode}
                  label={`${icd.DiagnosisCode}-(${icd.DiagnosisDescription})`}
                  color="primary"
                  onDelete={() => {
                    dispatch({ type: ACTION_NAME.DELETE_COPY_ICD10, payload: icd });
                  }}
                />
              );
            })}
          </Box>
        )}
        {type === 'med' && medicines && medicines?.length > 0 && (
          <Box>
            {medicines.map((med) => {
              return (
                <ChipCus
                  sx={{ mt: 0.8, mr: 0.5 }}
                  key={med.MedicineId}
                  label={
                    <Typography>
                      {med.MedicineName}, <Box component="span">Tier: {med.OrderOfTier}</Box>
                    </Typography>
                  }
                  color="primary"
                  onDelete={() => {
                    dispatch({ type: ACTION_NAME.DELETE_COPY_MEDICINE, payload: med });
                  }}
                />
              );
            })}
          </Box>
        )}
      </DialogContent>
    </Box>
  );
}

export default ViewAll;
