import {
  Answer,
  FilterQuesByListQueryParam,
  Question,
  QuestionDetail,
  QuestionList,
  SortByQuesListQueryParam,
  SortOrderQuesListQueryParam
} from '../types/faq.types';
import FORM_FIELD from '../validations/form-field';

export class QuestionListModal implements QuestionList {
  TotalRecords = 0;
  Page = 1;
  Questions: Question[] = [];
  ItemOffset = 10;
  FilterBy: FilterQuesByListQueryParam = '3';
  SortBy: SortByQuesListQueryParam = '1';
  SortOrder: SortOrderQuesListQueryParam = 'DESC';
  CategoryId = 0;
  FromDate: string | null = '';
  ToDate: string | null = '';
  initialValueSortDate = FORM_FIELD.filterListing;
}

export class QuestionDetailModal implements QuestionDetail {
  Answers: Answer[] = [];
  CategoryName = '';
  PatientName = '';
  PatientImage = '';
  IsBookmarked = false;
  QuestionId = 0;
  CategoryId = 0;
  PatientId = 0;
  Description: string | null = '';
  Text: string | null = '';
  VideoURL = '';
  AudioURL = '';
  ViewsCount = 0;
  AnswerCount = 0;
  IsActive = 0;
  CreatedAt = '';
  CreatedBy = 0;
  ModifiedAt: string | null = '';
  ModifiedBy: string | null = '';
  ReportCount: number | null = 0;
}
