import {
  DoctorRecommendationPayload,
  RecommendationItem,
  FilterByListQueryParam,
  SortByListQueryParam,
  SortOrderListQueryParam,
  ReportListPayloadRedux,
  ReportItem
} from '../types/doctor.recommendation.types';
import FORM_FIELD from '../validations/form-field';

export class DoctorVlogModal implements DoctorRecommendationPayload {
  Recommendations: RecommendationItem[] = [];
  Page = 1;
  TotalRecords = 0;
  ItemOffset = 10;
  FilterBy: FilterByListQueryParam = '3';
  SortBy: SortByListQueryParam = '1';
  SortOrder: SortOrderListQueryParam = 'DESC';
  CategoryId = 0;
  FromDate: string | null = '';
  ToDate: string | null = '';
  initialValueSortDate = FORM_FIELD.filterListing;
}

export class VlogDetailModal implements RecommendationItem {
  CategoryId = 0;
  CategoryName = '';
  CreatedAt = '';
  CreatedBy = 0;
  Description = '';
  DoctorId = 0;
  DoctorImage = '';
  DoctorName = '';
  DoctorProfileViews = null;
  DoctorRating = null;
  DoctorSpecializations = '';
  IsActive = 0;
  ModifiedAt = '';
  ModifiedBy = '';
  RecommendationId = 0;
  ReportCount = 0;
  Text = '';
  TotalDislikes = 0;
  TotalLikes = 0;
  VideoURL = '';
  DoctorPhoneCode = null;
  DoctorPhoneNumber = null;
}

export class VlogReportListModal implements ReportListPayloadRedux {
  TotalRecords = 0;
  Page = 1;
  Reports: ReportItem[] = [];
  ItemOffset = 5;
}
