import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import AutocomWrap from '../../components/form-ui/autocom.wrap/autocom.wrap';
import TextFieldWrapper from '../../components/form-ui/textfield-wrapper/text.field.wrapper';
import ModalWrapper from '../../components/modal-wrapper/modal.wrapper';
import { ReducersModal } from '../../modal';
import { IDiagnosis } from '../../types/manage.icd10.diagnosis.block';
import ACTION_NAME from '../../utils/action.name';
import FORM_FIELD from '../../validations/form-field';
import SCHEMA from '../../validations/schema';
import AutocomICD from './autocom.icd';
import showAlert from '../../utils/alert';
import { addIcd10DiagnosisBlocks, getIcd10DiagnosisBlocksList, icdDetail, updateIcd10DiagnosisBlocks } from './action';
import { convertAddBlockPayload } from '../../utils/common.function';
import ChipCus from '../../components/chip/chip.cus';

type AddBlockProps = {
  open: boolean;
  handleClose: () => void;
};

function AddBlock({ open, handleClose }: AddBlockProps) {
  const dispatch = useDispatch();
  const { icd10, type, page, blockName, insuranceCompany, blockId } = useSelector(
    (state: ReducersModal) => state.addBlockReducer
  );
  const { Page, ItemOffset } = useSelector((state: ReducersModal) => state.manageIcd10DiagnosisBlocksReducer);
  const [initialValue, setInitialValue] = useState(FORM_FIELD.addBlock);

  useEffect(() => {
    if (blockId || blockName)
      setInitialValue({
        blockName: blockName,
        insuranceCompany: {
          InsuranceCompanyId: insuranceCompany.InsuranceCompanyId,
          Name: insuranceCompany.Name,
          LogoURL: insuranceCompany.LogoURL
        }
      });
    else
      setInitialValue({
        blockName: '',
        insuranceCompany: {
          InsuranceCompanyId: 0,
          Name: '',
          LogoURL: null
        }
      });
  }, [blockId, blockName]);

  const validateIcd10Fn = (value: IDiagnosis[]): boolean => {
    if (value.length > 0) return false;
    else return true;
  };

  const handleSubmitBlock = (
    value: typeof FORM_FIELD.addBlock,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.addBlock>
  ) => {
    if (value.insuranceCompany.InsuranceCompanyId === 0) {
      showAlert(2, 'Please select Insurance Company');
      setSubmitting(false);
      return;
    }
    const validateIcd10 = validateIcd10Fn(icd10);
    if (validateIcd10) {
      showAlert(2, 'Please select minimum one ICD10 code');
      setSubmitting(false);
      return;
    }
    const payload = convertAddBlockPayload(value, icd10, type, blockId);
    if (type === 'edit')
      updateIcd10DiagnosisBlocks(payload)
        .then(() => {
          showAlert(1, 'You have successfully edited a Block');
          setSubmitting(false);
          if (blockId && page === 'detail') dispatch(icdDetail(String(blockId)));
          else
            dispatch(
              getIcd10DiagnosisBlocksList({
                page: Page,
                limit: ItemOffset
              })
            );
          handleClose();
        })
        .catch(() => {
          setSubmitting(false);
        });
    else
      addIcd10DiagnosisBlocks(payload)
        .then(() => {
          showAlert(1, 'You have successfully added a new ICD10 Block');
          setSubmitting(false);
          dispatch(
            getIcd10DiagnosisBlocksList({
              page: Page,
              limit: ItemOffset
            })
          );
          handleClose();
        })
        .catch(() => {
          setSubmitting(false);
        });
  };

  const handleDeleteICD = (icd: IDiagnosis) => {
    const filterIcd = icd10.filter((item) => item.Code !== icd.Code);
    dispatch({
      type: ACTION_NAME.ADD_BLOCK,
      payload: {
        icd10: filterIcd
      }
    });
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: { xs: 300, sm: 850 },
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <Box>
        <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
          <Typography variant="h1" sx={{ color: 'common.white' }}>
            {type === 'add' ? 'Add ' : 'Edit'} ICD10 Code Block
          </Typography>
        </Box>
        <Formik
          initialValues={initialValue}
          validationSchema={SCHEMA.addBlock}
          onSubmit={handleSubmitBlock}
          enableReinitialize>
          {({ isSubmitting }) => {
            return (
              <Form>
                <Grid container sx={{ p: 2 }} spacing={1.5}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <TextFieldWrapper
                        sx={{ mb: 2 }}
                        fullWidth
                        name="blockName"
                        label="Add Block Name*"
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <AutocomWrap name="insuranceCompany" label="Select Insurance Company" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <AutocomICD label="Select ICD10 Code(s)" />
                      {icd10?.length > 0 && (
                        <Box>
                          {icd10.map((icd) => {
                            return (
                              <ChipCus
                                sx={{ mt: 0.8, mr: 0.5 }}
                                key={icd.Code}
                                label={`${icd.Code}-(${icd.Description})`}
                                color="primary"
                                onDelete={() => handleDeleteICD(icd)}
                              />
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <ButtonWrapper
                        variant="outlined"
                        sx={{
                          color: (theme) => theme.palette.error.light,
                          flexBasis: '48%',
                          borderColor: (theme) => theme.palette.error.light,
                          '&:hover': {
                            borderColor: (theme) => theme.palette.error.light
                          }
                        }}
                        onClick={handleClose}
                        disabled={isSubmitting}>
                        Cancel
                      </ButtonWrapper>
                      <ButtonWrapper
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ flexBasis: '48%' }}
                        disabled={isSubmitting}>
                        {isSubmitting ? 'Loading...' : type === 'add' ? 'Add ' : 'Update'}
                      </ButtonWrapper>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalWrapper>
  );
}

export default AddBlock;
