import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton, IconButtonProps } from '@mui/material';
import Badge from '@mui/material/Badge';

type FilterButtonProps = {
  isFilterApply: boolean;
} & IconButtonProps;

const ButtonCustomize = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}));

function FilterButton({ isFilterApply, ...otherProps }: FilterButtonProps) {
  const ButtonReuse = () => (
    <>
      <ButtonCustomize {...otherProps}>
        <FilterListIcon
          fontSize="large"
          sx={{
            color: (theme) => theme.palette.common.white,
            '&:hover': {
              color: (theme) => theme.palette.common.white
            }
          }}
        />
      </ButtonCustomize>
    </>
  );
  return (
    <>
      {isFilterApply ? (
        <Badge
          color="error"
          badgeContent={''}
          sx={{
            '& .MuiBadge-badge': {
              right: 7,
              top: 7
            }
          }}>
          {ButtonReuse()}
        </Badge>
      ) : (
        <>{ButtonReuse()}</>
      )}
    </>
  );
}

export default FilterButton;
