import { memo } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ModalWrapper from './modal.wrapper';
import LOCAL_IMAGES from '../../utils/local.images';
import ButtonWrapper from '../button/button.wrapper';

type InactiveModalProps = {
  open: boolean;
  handleClose: () => void;
  heading: string;
  paragraph: React.ReactNode;
  handleConfirm: () => void;
};

function InactiveModal({ open, handleClose, heading, paragraph, handleConfirm }: InactiveModalProps) {
  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: { xs: 300, sm: 450 },
        borderRadius: (theme) => theme.spacing(0.8),
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          py: { xs: 2, sm: 4 }
        }}>
        <Box>
          <img src={LOCAL_IMAGES.INACTIVE} alt="" />
        </Box>
        <Typography variant="h1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: 3 }}>
          {heading}
        </Typography>
        <Typography
          variant="h1"
          sx={{ fontWeight: 400, textAlign: 'center', lineHeight: (theme) => theme.spacing(2.8) }}>
          {paragraph}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2
          }}>
          <ButtonWrapper
            variant="outlined"
            sx={{
              color: (theme) => theme.palette.error.light,
              px: { xs: 2, sm: 5 },
              mr: 1,
              py: 1,
              borderColor: (theme) => theme.palette.error.light,
              '&:hover': {
                borderColor: (theme) => theme.palette.error.light
              }
            }}
            onClick={handleClose}>
            Cancel
          </ButtonWrapper>
          <ButtonWrapper
            variant="contained"
            color="primary"
            sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
            onClick={handleConfirm}>
            Confirm
          </ButtonWrapper>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

export default memo(InactiveModal);
