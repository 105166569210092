import { Autocomplete } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../../components/form-ui/textfield-wrapper/input.field';
import useDebounce from '../../../hooks/use.debounce';
import { ReducersModal } from '../../../modal';
import ACTION_NAME from '../../../utils/action.name';
import showAlert from '../../../utils/alert';
import { getSchemeList } from '../action';

export type Scheme = { Name: string }[];

type AutocomSchemeProps = {
  label: string;
  disable:boolean;
};

function AutocomScheme({ label, disable}: AutocomSchemeProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Scheme>([]);
  const [inputValue, setInputValue] = useState('');
  const debounceValue = useDebounce(inputValue, 500);
  const dispatch = useDispatch()
  const { InsuranceCompanyId, FormularyId, Scheme, SchemeNameList } = useSelector((state: ReducersModal) => state.assignFormularytoSchemeReducer);

  useEffect(() => {
    let active = true;

    if (!debounceValue || debounceValue.length < 3) {
      return undefined;
    }
    if (InsuranceCompanyId === 0) return;
    (async () => {
      try {
        const res = await getSchemeList({
          page: 1,
          limit: 20,
          search: debounceValue,
          insuranceCompanyId: InsuranceCompanyId,
          formularyId: Number(FormularyId)
        });
        if (active) {
          setOptions(res.data?.Data?.Schemes.filter((scheme)=> !SchemeNameList.includes(scheme.Name)));
        }
      } catch (_err) {
        showAlert(2);
      }
    })();

    return () => {
      active = false;
    };
  }, [debounceValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  

  return (
    <Autocomplete

      disabled={InsuranceCompanyId === 0 || disable}
      noOptionsText={debounceValue.length >= 3 ? 'No scheme found' : 'Type at least 3 characters'}
      size="small"
      onChange={(_e, newValue) => {
        if (newValue) {
          const findScheme = Scheme.find((ele) => ele.Name === newValue.Name);
          if (findScheme) return;
          // setScheme([...scheme, newValue]);
          dispatch({
            type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
            payload: {
              Scheme:[...Scheme, newValue],
              SchemeNameList:[...SchemeNameList, newValue.Name]
            }
          });
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'reset') setInputValue('');
        else setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.Name}
      options={options}
      renderInput={(params) => (
        <InputField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
}

export default AutocomScheme;
