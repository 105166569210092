import { Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CONSTANTS from '../../utils/constants';
import DeleteButton from '../button/delete.button';
import { EventClassEnum, NotificationItem } from '../../types/notification.types';
import { getNotificationTimeStatus } from '../../utils/common.function';
import { useNavigate } from 'react-router-dom';
import ROUTES_NAME from '../../utils/routes.name';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../modal';
import { getNotificationListAction } from '../../pages/notification/action';

type NotificationCardProps = {
  item: NotificationItem;
};

function NotificationCard({ item }: NotificationCardProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { TotalCount, PrevEntityId } = useSelector((state: ReducersModal) => state.notificationListReducer);
  const { EventClass, EntityId, Title, Description, SenderImage, CreatedAt, IsRead } = item;

  const handleNavigate = () => {
    if (!EntityId) return;
    if (IsRead === false && PrevEntityId !== EntityId)
      dispatch(getNotificationListAction({ TotalCount: TotalCount - 1, PrevEntityId: EntityId }));
    if (EventClass === EventClassEnum.ANSWER || EventClass === EventClassEnum.QUESTION) {
      navigate(`${ROUTES_NAME.FAQ_DETAIL}/${EntityId}`);
    }
    if (EventClass === EventClassEnum.RECOMMENDATION) {
      navigate(`${ROUTES_NAME.DOCTORS_RECOMMENDATION_DETAIL}/${EntityId}`);
    }
    if (EventClass === EventClassEnum.VLOG) {
      navigate(`${ROUTES_NAME.DOCTORS_VLOG_DETAIL}/${EntityId}`);
    }
  };

  return (
    <Box
      onClick={handleNavigate}
      sx={{
        py: 1,
        px: 1.5,
        backgroundColor: IsRead === false ? 'var(--notification-read)' : 'common.white',
        cursor: 'pointer',
        borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              bgcolor: (theme) => theme.palette.secondary.light
            }}>
            {SenderImage ? (
              <img src={CONSTANTS.IMAGE_BASE_URL + SenderImage} alt="" width="40" height="40" />
            ) : (
              <PersonIcon color="primary" />
            )}
          </Avatar>
          <Box
            sx={{
              ml: 1.5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-between'
            }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight:
                  IsRead === false
                    ? (theme) => theme.typography.fontWeightBold
                    : (theme) => theme.typography.fontWeightMedium
              }}>
              {Title || 'N/A'}
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: 'var(--notification-subTitle)', fontSize: (theme) => theme.spacing(1.4), mt: 0.5 }}>
              {Description || 'N/A'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <DeleteButton sx={{ display: 'none' }} />
          <Typography sx={{ color: 'var(--notification-subTitle)', fontSize: (theme) => theme.spacing(1.4) }}>
            {getNotificationTimeStatus(CreatedAt) || 'N/A'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationCard;
