import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../../components/button/button.wrapper';
import InactiveModal from '../../../components/modal-wrapper/inactive.modal';
import SearchTextfield from '../../../components/search-textfield/search.textfield';
import useDebounce from '../../../hooks/use.debounce';
import { ReducersModal } from '../../../modal';
import { IMedicines } from '../../../types/manage.icd10.diagnosis.block';
import showAlert from '../../../utils/alert';
import { calculatePage, calculateRowPerPage } from '../../../utils/common.function';
import { getMedicineAction, getMedicineList, updateMedicine } from '../action';
import AddMedicine from './add.medicine';
import MedicineTable from './medicine.table';

type MedicineProps = {
  blockId: string;
};

function MedicineCard({ blockId }: MedicineProps) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [openInactive, setOpenInactive] = useState(false);
  const [updateStatusFaq, setUpdateStatusFaq] = useState({} as IMedicines);
  const { Page, TotalRecords, ItemOffset, SortBy, SortOrder, Medicines } = useSelector(
    (state: ReducersModal) => state.icdMedicineReducer
  );
  const [pageCount, setPageCount] = useState(Page);
  const [openAddMedicine, setOpenAddMedicine] = useState(false);
  const debouncedValue = useDebounce(inputValue.trim(), 1000);

  useEffect(() => {
    dispatch(
      getMedicineList({
        blockId,
        page: Page,
        limit: ItemOffset,
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(debouncedValue !== '' && { filterValue: debouncedValue })
      })
    );
  }, [blockId, Page, ItemOffset, SortBy, SortOrder, debouncedValue]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(getMedicineAction({ Page: selectedPage + 1 }));
  };

  const handleCloseInactive = useCallback(() => {
    setOpenInactive(false);
  }, []);

  const handleOpenInactive = (item: IMedicines) => {
    setOpenInactive(true);
    setUpdateStatusFaq(item);
  };

  const handleConfirmInactive = useCallback(() => {
    const { BlockId, MedicineId } = updateStatusFaq;
    updateMedicine({ blockId: BlockId, medicineId: MedicineId })
      .then(({ status, data }) => {
        if (status === 200 && blockId) {
          showAlert(1, data?.Message);
          dispatch(
            getMedicineList({
              blockId,
              page: Page,
              limit: ItemOffset,
              ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
              ...(debouncedValue !== '' && { filterValue: debouncedValue }),
              handleCloseInactive: handleCloseInactive
            })
          );
        }
      })
      .catch(() => {
        showAlert(2);
        handleCloseInactive();
      });
  }, [updateStatusFaq]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };

  const handleOpenAddMedicine = () => {
    setOpenAddMedicine(true);
  };

  const handleCloseAddMedicine = () => {
    setOpenAddMedicine(false);
  };

  const handlePrevious = () => {
    dispatch(getMedicineAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(getMedicineAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h2" sx={{ color: 'primary.main' }}>
        Medicines
      </Typography>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          alignItems: { xs: 'initial', sm: 'center' },
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1 }
        }}>
        <Box sx={{ flex: 1 }}>
          <SearchTextfield
            placeholder={'Search by Drug Id, Product trade name, API'}
            inputValue={inputValue}
            handleSearchChange={handleSearchChange}
            handleSearchSubmit={handleSearchSubmit}
            name="searchInput"
          />
        </Box>
        <ButtonWrapper
          variant="contained"
          color="primary"
          onClick={handleOpenAddMedicine}
          sx={{ textTransform: 'none' }}>
          Assign medicine to this Block
        </ButtonWrapper>
      </Box>
      <MedicineTable
        data={Medicines}
        handleOpenInactive={handleOpenInactive}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
        page={calculatePage(Page, ItemOffset)}
        totalRecord={TotalRecords}
        rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        activePage={Page}
      />
      <AddMedicine open={openAddMedicine} handleClose={handleCloseAddMedicine} />
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusFaq.IsActive === 0 ? 'Activate' : 'Delete'}
        paragraph={
          <>
            Are you sure you want to {updateStatusFaq.IsActive === 0 ? 'activate' : 'delete'}
            <br /> the selected Medicine?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </Box>
  );
}

export default MedicineCard;
