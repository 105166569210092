export interface Login {
  PrincipalIdentifierValue: string;
  Password: string;
  PlatformEnumId: 1 | 2 | 3 | 4;
  TokenExpiryTime: '1m' | '1h' | '2h' | '1d' | '2d';
}
export interface Logout {
  PrincipalIdentifierValue: string;
  PlatformEnumId: 1 | 2 | 3 | 4;
}

export enum PlatformEnum {
  ANDROID = 1,
  IOS = 2,
  WEB = 3,
  ALL = 4
}

export interface LoginResDTO {
  SecurityPrincipalId: string;
  FirstName: string;
  LastName: string;
  ProfileImageURL: null;
  PrincipalIdentifier: PrincipalIdentifier;
}

export interface PrincipalIdentifier {
  PrincipalIdentifierId: string;
  PrincipalIdentifierEnumId: number;
  PrincipalIdentifierValue: string;
  LoginMethodId: string;
  LoginMethodEnumId: number;
  Device: Device;
}

export interface Device {
  DeviceId: string;
  AccessToken: string;
  RefreshToken: string;
  PlatformEnumId: number;
  ApplicationVersionId: string;
}
