import { Box, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material';
import TableCellWrapper from '../../../components/table/table.cell.wrapper';
import TableRowWrapper from '../../../components/table/table.row.wrapper';
import LOCAL_IMAGES from '../../../utils/local.images';
import { formatDateAndTime, trimStringICD } from '../../../utils/common.function';
import PaginateWrapper from '../../../components/paginate-wrapper/paginate.wrapper';
import ConfirmButton from '../../../components/button/confirm.button';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../../modal';
import { Block } from '../../../types/manage.icd10.diagnosis.block';
import EditButton from '../../../components/button/edit.button';
import DeleteButton from '../../../components/button/delete.button';
import CopyButton from '../../../components/button/copy.button';
import TooltipWrapper from '../../../components/tooltip-wrapper/tooltip.wrapper';
import useGlobalStyle from '../../../hooks/use.global.style';
import { TableSort, TSort } from '../../../types/global.types';
import { getFormularyIcd10DiagnosisBlocksList, manageFormularyIcd10DiagnosisBlockListAction } from '../action';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES_NAME from '../../../utils/routes.name';
import ACTION_NAME from '../../../utils/action.name';
import showAlert from '../../../utils/alert';

type ICD10TableProps = {
  data: Block[]
  handleOpenInactive: (item: Block) => void;
  pageCount: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  rowPerPage: number;
  totalRecord: number;
  page: number;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
  handleOpenCopyDialog:() => void;
};

function SICD10Table({
  data,
  handleOpenInactive,
  pageCount,
  handlePageClick,
  totalRecord,
  page,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage,
  handleOpenCopyDialog
}: ICD10TableProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const globalClasses = useGlobalStyle();
  const {
    Page,
    ItemOffset,
    SortOrder,
    StatusId,
    FromDate,
    ToDate,
    } = useSelector((state: ReducersModal) => state.manageFormularyIcd10DiagnosisBlocksReducer);
    const {
      FormularyId,
      InsuranceCompanyId,
      } = useSelector((state: ReducersModal) => state.schemeDetailReducer);
  // const {FromBlockId} = useSelector((state:ReducersModal)=>state.copySchemeSpecificFormularyBlockReducer)
  const handleAscAndDesc = (SortBy: TSort) => {
    dispatch(
      manageFormularyIcd10DiagnosisBlockListAction({
        ...(SortOrder === 'ASC' ? { SortOrder: 'DESC' } : { SortOrder: 'ASC' }),
        ...{ SortBy: SortBy }
      })
    );
    dispatch(
      getFormularyIcd10DiagnosisBlocksList({
        page: Page,
        limit: ItemOffset,
        ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
        ...({formularyId:FormularyId}),
        ...(StatusId && { statusId: StatusId }),
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder === 'ASC' ?'DESC': 'ASC'}),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      })
    );
  };
  const navigateToDetail = (blockId:number, blockName:string ) => {
    navigate(`${ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL}/${param.schemeId}/${ROUTES_NAME.SCHEME_SPECIFIC_FORMULARY_DETAIL_MEDICINE}/${blockId}`, {state:{blockName:blockName}});
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>Block Name</TableCellWrapper>
                <TableCellWrapper>ICD10 Code(s)</TableCellWrapper>
                <TableCellWrapper>No. of Medicines</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Created at
                    </Typography>
                    <Box sx={{ ml: 0.5, cursor: 'pointer' }} onClick={()=>{handleAscAndDesc(TableSort.CreatedAt)}}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Updated At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.UpdatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Status</TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: Block, index: number) => {
                  return (
                    <TableRowWrapper hover 
                    key={item?.BlockId} 
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigateToDetail(item?.BlockId, item?.Name)}>
                      <TableCellWrapper>{index + page}</TableCellWrapper>
                      <TableCellWrapper>{item.Name}</TableCellWrapper>
                      <TableCellWrapper>
                        {item?.Diagnosis ? (
                          <>
                            <TooltipWrapper
                              title={item?.Diagnosis.map((item) => item.DiagnosisCode).join(', ')}
                              placement="right"
                              arrow>
                              <span className={globalClasses.span}>
                                {trimStringICD(item?.Diagnosis.map((item) => item.DiagnosisCode).join(', '), 9)}
                              </span>
                            </TooltipWrapper>
                          </>
                        ) : (
                          'N/A'
                        )}
                      </TableCellWrapper>
                      <TableCellWrapper>{item.MedicineCount}</TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item.CreatedAt) ?? 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        <>{item.ModifiedAt !== null ? formatDateAndTime(item.ModifiedAt) : 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>{item.IsActive === 1 ? 'Active' : 'In Active'}</TableCellWrapper>
                      <TableCellWrapper>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CopyButton
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch({
                                type: ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK,
                                payload:{
                                  FromBlockId:[item.BlockId],
                                  FromBlock:item.BlockId
                                }
                              })
                              if(item.MedicineCount)handleOpenCopyDialog()
                              else showAlert(2, "None medicine are in this block of formulary")
                              
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>
                          <EditButton
                            onClick={(e) => {
                              e.stopPropagation();
                              navigateToDetail(item?.BlockId,item?.Name)
                              
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>

                          {item.IsActive === 1 && (
                            <DeleteButton
                              title='Suspend'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                          {item.IsActive === 0 && (
                            <ConfirmButton
                              title='Activate'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                        </Box>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={9}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            activePage={activePage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={page}
            rowpPerPage={rowPerPage}
            totalRecord={totalRecord}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            // isShowPreAndNext
          />
        )}
      </Box>
    </>
  );
}

export default SICD10Table;
