import { GlobalApiResponseErrorFunction, GlobalApiResponseSuccessFunction } from '../types/global.types';
import CONSTANTS from './constants';
import SESSION_MANAGEMENT from './session.management';

const getApiCall = (
  endPoint: string,
  params = '',
  successCallback: GlobalApiResponseSuccessFunction,
  errorCallback: GlobalApiResponseErrorFunction
) => {
  SESSION_MANAGEMENT.getAccessToken();
  CONSTANTS.AXIOS.get(CONSTANTS.API_URL + endPoint + params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const postApiCall = (
  endPoint: string,
  params: object,
  successCallback: GlobalApiResponseSuccessFunction,
  errorCallback: GlobalApiResponseErrorFunction
) => {
  SESSION_MANAGEMENT.getAccessToken();
  CONSTANTS.AXIOS.post(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const deleteApiCall = (
  endPoint: string,
  params = '',
  successCallback: GlobalApiResponseSuccessFunction,
  errorCallback: GlobalApiResponseErrorFunction
) => {
  SESSION_MANAGEMENT.getAccessToken();
  CONSTANTS.AXIOS.delete(CONSTANTS.API_URL + endPoint + params, {})
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const patchApiCall = (
  endPoint: string,
  params: object,
  successCallback: GlobalApiResponseSuccessFunction,
  errorCallback: GlobalApiResponseErrorFunction
) => {
  SESSION_MANAGEMENT.getAccessToken();
  CONSTANTS.AXIOS.patch(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const putApiCall = (
  endPoint: string,
  params: object,
  successCallback: GlobalApiResponseSuccessFunction,
  errorCallback: GlobalApiResponseErrorFunction
) => {
  SESSION_MANAGEMENT.getAccessToken();
  CONSTANTS.AXIOS.put(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
};

const API = {
  getApiCall,
  postApiCall,
  deleteApiCall,
  patchApiCall,
  putApiCall
};

export default API;
