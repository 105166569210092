import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import ButtonWrapper from '../../components/button/button.wrapper';
import InputField from '../../components/form-ui/textfield-wrapper/input.field';
import CopyAutoInsurance from './copy.auto.insurance';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../modal';
import ACTION_NAME from '../../utils/action.name';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, useEffect, useTransition } from 'react';
import ViewAll from './view.all';
import { Diagnosis, IMedicines } from '../../types/manage.icd10.diagnosis.block';
import showAlert from '../../utils/alert';
import { convertCopyPayload } from '../../utils/common.function';
import { copyBlock } from './action';
import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import { VIEW_NUMBER } from '../../utils/static.data';
import ChipCus from '../../components/chip/chip.cus';
import { useNavigate } from 'react-router-dom';
import ROUTES_NAME from '../../utils/routes.name';

export type TIndex = 0 | 1 | 2;

type CopyBlockProps = {
  open: boolean;
  handleClose: () => void;
};

function CopyBlock({ open, handleClose }: CopyBlockProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();
  const navigate = useNavigate();
  const [value, setValue] = useState<TIndex>(0);
  const [medicineInputValue, setMedicineInputValue] = useState('');
  const [icd10nputValue, setIcd10nputValue] = useState('');
  const [copyMedicineArr, setCopyMedicineArr] = useState<IMedicines[]>([]);
  const [copyIcd10Arr, setCopyIcd10Arr] = useState<Diagnosis[]>([]);
  const { copyBlockName, copyIcd10, copyMedicine, copyInsuranceCompany } = useSelector(
    (state: ReducersModal) => state.copyBlockReducer
  );
  const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);
  const dispatch = useDispatch();

  // icd10 code
  const icdFilter = (medicine: Diagnosis[], keyword: string) => {
    return medicine.filter(
      (item) =>
        item.DiagnosisCode.toLowerCase().includes(keyword.toLowerCase().trim()) ||
        item.DiagnosisDescription.toLowerCase().includes(keyword.toLowerCase().trim())
    );
  };

  useEffect(() => {
    if (icd10nputValue) {
      const filtercopyIcd10Arr = icdFilter(copyIcd10, icd10nputValue);
      setCopyIcd10Arr(filtercopyIcd10Arr);
    } else setCopyIcd10Arr(copyIcd10);
  }, [copyIcd10.length, icd10nputValue]);

  const handleSearchChangeIcd10 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setIcd10nputValue(keyword);
    const filtercopyIcd10Arr = icdFilter(copyIcd10, keyword);
    startTransition(() => setCopyIcd10Arr(filtercopyIcd10Arr));
  };

  const handleSearchSubmitIcd10 = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setIcd10nputValue(keyword);
    const filtercopyIcd10Arr = icdFilter(copyIcd10, keyword);
    startTransition(() => setCopyIcd10Arr(filtercopyIcd10Arr));
  };

  // medicin code
  const medicineFilter = (medicine: IMedicines[], keyword: string) => {
    return medicine.filter((item) => item.MedicineName.toLowerCase().includes(keyword.toLowerCase().trim()));
  };

  useEffect(() => {
    if (medicineInputValue) {
      const filterCopyMedicineArr = medicineFilter(copyMedicine, medicineInputValue);
      setCopyMedicineArr(filterCopyMedicineArr);
    } else setCopyMedicineArr(copyMedicine);
  }, [copyMedicine.length, medicineInputValue]);

  const handleSearchChangeMedicine = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setMedicineInputValue(keyword);
    const filterCopyMedicineArr = medicineFilter(copyMedicine, keyword);
    startTransition(() => setCopyMedicineArr(filterCopyMedicineArr));
  };

  const handleSearchSubmitMedicine = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setMedicineInputValue(keyword);
    const filterCopyMedicineArr = medicineFilter(copyMedicine, keyword);
    startTransition(() => setCopyMedicineArr(filterCopyMedicineArr));
  };

  const handleChangeIndex = (index: TIndex) => {
    setValue(index);
  };

  const handleCloseDialog = () => {
    setValue(0);
    setMedicineInputValue('');
    setIcd10nputValue('');
    handleClose();
  };

  const validate = () => {
    if (!copyBlockName.trim()) return showAlert(2, 'Block name is required');
    if (!copyInsuranceCompany.InsuranceCompanyId) return showAlert(2, 'Insurance company is required');
    if (copyIcd10.length <= 0) return showAlert(2, 'Minimum one ICD10 code  is required');
    return true;
  };

  const handleCopy = () => {
    const isVaild = validate();
    if (!isVaild) return;
    const payload = convertCopyPayload({
      insuranceCompanyId: copyInsuranceCompany.InsuranceCompanyId,
      name: copyBlockName,
      diagnosis: copyIcd10,
      medicine: copyMedicine
    });
    dispatch(globalLoaderStart());
    copyBlock(payload)
      .then((res) => {
        dispatch(globalLoaderEnd());
        handleCloseDialog();
        navigate(`${ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS_DETAIL}/${res.data.Data.BlockId}`);
      })
      .catch(() => {
        dispatch(globalLoaderEnd());
        showAlert(2);
      });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleCloseDialog} scroll={'paper'}>
      <>
        {value === 0 && (
          <Box>
            <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
              <Typography variant="h1" sx={{ color: 'common.white' }}>
                Copy ICD10 Code Block
              </Typography>
            </Box>
            <DialogContent>
              <Grid container sx={{ p: 2 }} spacing={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <InputField
                      value={copyBlockName}
                      onChange={(e) => {
                        dispatch({
                          type: ACTION_NAME.COPY_BLOCK,
                          payload: {
                            copyBlockName: e.target.value
                          }
                        });
                      }}
                      fullWidth
                      label="Add Block Name*"
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <CopyAutoInsurance label="Select Insurance Company" />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <Typography variant="h1" sx={{ color: 'primary.main' }}>
                        ICD10 Code(s)
                      </Typography>
                      {copyIcd10.length > VIEW_NUMBER && (
                        <ButtonWrapper
                          onClick={() => handleChangeIndex(1)}
                          endIcon={<ArrowForwardIosIcon />}
                          variant="contained"
                          color="primary">
                          View All
                        </ButtonWrapper>
                      )}
                    </Box>
                    {copyIcd10?.length > 0 ? (
                      <Box>
                        {copyIcd10.map((icd, index) => {
                          if (index > VIEW_NUMBER) return null;
                          return (
                            <ChipCus
                              sx={{ mt: 0.8, mr: 0.5 }}
                              key={icd.DiagnosisCode}
                              label={`${icd.DiagnosisCode}-(${icd.DiagnosisDescription})`}
                              color="primary"
                              onDelete={() => {
                                dispatch({ type: ACTION_NAME.DELETE_COPY_ICD10, payload: icd });
                              }}
                            />
                          );
                        })}
                      </Box>
                    ) : (
                      <Typography variant="h3" sx={{ color: 'error.light', mt: 1 }}>
                        No ICD10 Code
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <Typography variant="h1" sx={{ color: 'primary.main' }}>
                        Medicines
                      </Typography>
                      {copyMedicine.length > VIEW_NUMBER && (
                        <ButtonWrapper
                          onClick={() => handleChangeIndex(2)}
                          endIcon={<ArrowForwardIosIcon />}
                          variant="contained"
                          color="primary">
                          View All
                        </ButtonWrapper>
                      )}
                    </Box>
                    {copyMedicine?.length > 0 ? (
                      <Box>
                        {copyMedicine.map((med, index) => {
                          if (index > VIEW_NUMBER) return;
                          return (
                            <ChipCus
                              sx={{ mt: 0.8, mr: 0.5 }}
                              key={med.MedicineId}
                              label={`${med.MedicineName},Tier: ${med.OrderOfTier}`}
                              color="primary"
                              onDelete={() => {
                                dispatch({ type: ACTION_NAME.DELETE_COPY_MEDICINE, payload: med });
                              }}
                            />
                          );
                        })}
                      </Box>
                    ) : (
                      <Typography variant="h3" sx={{ color: 'error.light', mt: 1 }}>
                        No Medicine
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                    <ButtonWrapper
                      disabled={isLoading}
                      variant="outlined"
                      sx={{
                        color: (theme) => theme.palette.error.light,
                        flexBasis: '48%',
                        borderColor: (theme) => theme.palette.error.light,
                        '&:hover': {
                          borderColor: (theme) => theme.palette.error.light
                        }
                      }}
                      onClick={handleClose}>
                      Cancel
                    </ButtonWrapper>
                    <ButtonWrapper
                      disabled={isLoading}
                      onClick={handleCopy}
                      variant="contained"
                      color="primary"
                      sx={{ flexBasis: '48%' }}>
                      {isLoading ? 'Loading...' : 'Save & Continue'}
                    </ButtonWrapper>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      color: 'primary.main',
                      mt: 1.5
                    }}>
                    <InfoIcon color="primary" sx={{ mr: 0.5 }} />
                    In order to update any other details, you can save this detail which will redirect you to Edit Block
                    Section
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        )}
        {value === 1 && (
          <ViewAll
            type="icd"
            index={value}
            heading="All ICD10 Code(s)"
            handleChangeIndex={handleChangeIndex}
            bradcrumb={{
              link: 'ICD10 Code'
            }}
            placeholder="Search ICD10 Code, Description"
            inputValue={icd10nputValue}
            handleSearchChange={handleSearchChangeIcd10}
            handleSearchSubmit={handleSearchSubmitIcd10}
            diagnosis={copyIcd10Arr}
          />
        )}
        {value === 2 && (
          <>
            <ViewAll
              type="med"
              index={value}
              heading="All Medicines"
              handleChangeIndex={handleChangeIndex}
              bradcrumb={{
                link: 'Medicines'
              }}
              placeholder="Search Medicine"
              inputValue={medicineInputValue}
              handleSearchChange={handleSearchChangeMedicine}
              handleSearchSubmit={handleSearchSubmitMedicine}
              medicines={copyMedicineArr}
            />
          </>
        )}
      </>
    </Dialog>
  );
}

export default CopyBlock;
