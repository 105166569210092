import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import { AppDispatch } from '../../store/store';
import { AxiosResponseGlobal } from '../../types/global.types';
import { NotificationApiResponse, NotificationPayloadRedux } from '../../types/notification.types';
import ACTION_NAME from '../../utils/action.name';
import showAlert from '../../utils/alert';
import { checkOnline } from '../../utils/common.function';
import CONSTANTS from '../../utils/constants';
import END_POINTS from '../../utils/end.points';

export const getNotificationCount = () => {
  return new Promise<AxiosResponseGlobal<NotificationApiResponse>>((resolve, reject) => {
    checkOnline();
    CONSTANTS.AXIOS.get(END_POINTS.NOTIFICATION_COUNT)
      .then((res) => {
        if (res?.status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const getNotificationList = (page: number, limit: number) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    const params = `${END_POINTS.NOTIFICATION_LIST}?Page=${page}&Limit=${limit}`;
    dispatch(globalLoaderStart());
    CONSTANTS.AXIOS.get(params)
      .then((res) => {
        if (res?.status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(getNotificationListAction({ ...res.data?.Data }));
          dispatch(globalLoaderEnd());
        }
      })
      .catch(() => {
        dispatch(globalLoaderEnd());
        showAlert(2);
      });
  };
};

export const getNotificationListAction = (payload: NotificationPayloadRedux) => {
  return {
    type: ACTION_NAME.GET_NOTIFICATION_LIST,
    payload
  };
};
