import { styled } from '@mui/material/styles';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import React from 'react';

type TableRowWrapperProps = {
  children: React.ReactNode;
} & TableRowProps;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.secondary.main
  },
  '& td, & th': {
    border: 0
  }
}));

function TableRowWrapper({ children, ...otherProps }: TableRowWrapperProps) {
  return <StyledTableRow {...otherProps}>{children}</StyledTableRow>;
}

export default TableRowWrapper;
