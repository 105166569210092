import { Box, Link, SelectChangeEvent, Typography } from "@mui/material"
import { FormikHelpers } from "formik"
import { useCallback, useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ButtonWrapper from "../../../components/button/button.wrapper"
import FilterButton from "../../../components/button/filter.button"
import SelectWrapper from "../../../components/form-ui/select-wrapper/select.wrapper"
import Icd10DiagnosisBlockFilterModal from "../../../components/modal-wrapper/icd10.diagnosis.block.filter.modal"
import InactiveModal from "../../../components/modal-wrapper/inactive.modal"
import SearchTextfield from "../../../components/search-textfield/search.textfield"
import useDebounce from "../../../hooks/use.debounce"
import {ReducersModal } from "../../../modal"
import { Formulary } from "../../../types/manage.scheme.specific.formulary"
import { calculatePage, calculateRowPerPage, checkSchemeSpecificFilterApplyOrNot, formatDateAndTimeForfilter } from "../../../utils/common.function"
import { LOCAL_FILE } from "../../../utils/local.file"
import { SELECT_MENU_ITEM } from "../../../utils/static.data"
import FORM_FIELD from "../../../validations/form-field"
import { getAssignFormularytoSchemeList, manageAssignFormularytoSchemeListAction, resetAssignFormularyToScheme, resetCopyFromulary, updateStatusAssignFormularyApi} from "../action"
import CopySchemeSpecificFormulary from "../scheme-detail/copy-scheme-specific-formulary/copy-scheme-specific-modal"
import SchemeViewDialog from "../scheme-detail/scheme.view.dialog"
import AssignFormularySchemeDialog from "./assign.formulary.scheme.dialog"
import AssignFormularyTable from "./assign.formulary.table"


function AssignFormularyToScheme() {
  const [openFilter, setOpenFilter] = useState(false);
  const filterRef = useRef<HTMLElement>(null);
  const [openAssignFormularyDialog, setOpenAssignFormularyDialog] = useState(false)
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue.trim(), 1000);
  const [openInactive, setOpenInactive] = useState(false);
  const [updateStatusSchemeSpecificFormulary, setUpdateStatusSchemeSpecificFormulary] = useState({} as Formulary);
  const [openAllSchemeView, setOpenAllSchemeView] = useState(false)
  const [selectedFormulary, setSelectedFormulary] = useState(0);
  const [openCopyScheme, setOpenCopyScheme] = useState(false)
  const {
    Page,
    ItemOffset,
    SortBy,
    SortOrder,
    InsuranceCompanyId,
    StatusId,
    FromDate,
    ToDate,
    Formulary,
    TotalRecords,
    initialValueSortDate
  } = useSelector((state: ReducersModal) => state.manageAssignFormularytoSchemeReducer);
  const [pageCount] = useState(Page);
  useEffect(() => {
    dispatch(
      getAssignFormularytoSchemeList({
        page: Page,
        limit: ItemOffset,
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(debouncedValue && debouncedValue !== '' && { filterValue: debouncedValue }),
        ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
        ...(StatusId && { statusId: StatusId }),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      })
    );
  }, [dispatch, Page, ItemOffset, debouncedValue, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate]);

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const handleOpenFilter = () => {
    setInputValue('');
    setOpenFilter(true);
  };
  const handleAssignFormularyDialog=()=>{
    dispatch(resetAssignFormularyToScheme())
    setOpenAssignFormularyDialog(false)
  }
  const handlePrevious = () => {
    dispatch(manageAssignFormularytoSchemeListAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(manageAssignFormularytoSchemeListAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };
  const handleFilterSubmit = (
    { fromDate, toDate, insuranceCompanyId, statusId }: typeof FORM_FIELD.schemeSpecificFilterListing,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.icd10DiagnosisFilterListing>
  ) => {
    let statusVal = statusId;
    if (statusId === 'all') statusVal = '';
    if (fromDate && toDate) {
      dispatch(
        manageAssignFormularytoSchemeListAction({
          FromDate: formatDateAndTimeForfilter(fromDate),
          ToDate: formatDateAndTimeForfilter(toDate),
          initialValueSortDate: {
            fromDate,
            toDate,
            insuranceCompanyId,
            statusId
          }
        })
      );
    }
    dispatch(
      manageAssignFormularytoSchemeListAction({
        InsuranceCompanyId: insuranceCompanyId,
        StatusId: statusVal,
        initialValueSortDate: {
          fromDate,
          toDate,
          insuranceCompanyId,
          statusId
        }
      })
    );

    handleCloseFilter();
    setSubmitting(false);
  };
  const handleFilterReset = () => {
    dispatch(
      manageAssignFormularytoSchemeListAction({
        InsuranceCompanyId: 0,
        StatusId: '',
        FromDate: null,
        ToDate: null,
        initialValueSortDate: {
          fromDate: null,
          toDate: null,
          insuranceCompanyId: 0,
          statusId: 'all'
        }
      })
    );
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };
  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };
  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    dispatch(manageAssignFormularytoSchemeListAction({ Page: 1, ItemOffset: Number(itemOffset) }));
  };
  const handleOpenInactive = (item: Formulary) => {
    setOpenInactive(true);
    setUpdateStatusSchemeSpecificFormulary(item);
  };
  const handleCloseInactive = () => {
    setOpenInactive(false);
  };
  const handleConfirmInactive = useCallback(() => {
    const payload = {
      FormularyId: updateStatusSchemeSpecificFormulary.FormularyId,
      IsActive: updateStatusSchemeSpecificFormulary.IsActive === 0 ? true : false
    };
    dispatch(updateStatusAssignFormularyApi(payload, handleCloseInactive, 'list'));
  }, [updateStatusSchemeSpecificFormulary]);
  const handleOpenEditBlock = ()=> {
    setOpenAssignFormularyDialog(true)

  }
  const handleCloseEditBlock = ()=>{
    setOpenAssignFormularyDialog(false)
  }
  const hanldeCloseAllSchemeView=()=>{
    setOpenAllSchemeView(false)
  }
  const hanldeOpenAllSchemeView = ()=>{
    setOpenAllSchemeView(true)
  }
  const handleSelectedFormulary = (id:number)=>{
    setSelectedFormulary(id)
  }
  const handleCloseCopyScheme = useCallback(()=>{
    dispatch(resetCopyFromulary())
    setOpenCopyScheme(false)},[])

  const handleOpenCopyBlock  = ()=>{
    setOpenCopyScheme(true)
  }
  return (
    <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography variant="h1">Assign Formulary to Scheme</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <ButtonWrapper
              variant="contained"
              color="primary"
              sx={{ mr: 1 }}
              onClick={() => {setOpenAssignFormularyDialog(true)}}>
              Assign New Formulary to Scheme
            </ButtonWrapper>
            <Box ref={filterRef}>
              <FilterButton
                onClick={handleOpenFilter}
                isFilterApply={checkSchemeSpecificFilterApplyOrNot(initialValueSortDate)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: { xs: 'initial', sm: 'center' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1 }
          }}>
          <Box sx={{ flex: 1 }}>
            <SearchTextfield
              placeholder={'Search by Formulary name'}
              inputValue={inputValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              name="searchInput"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <Box sx={{ mr: 2 }}>
              <SelectWrapper value={ItemOffset} onChange={handleChangeShowSort} menuItem={SELECT_MENU_ITEM} />
            </Box>
            <Box sx={{ textAlign: 'end', cursor: 'pointer' }}>
              <Link href={LOCAL_FILE.FormularySchemes} download>
                Download sample file
              </Link>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <AssignFormularyTable
            data={Formulary}
            handleOpenInactive={handleOpenInactive}
            pageCount={pageCount}
            handlePageClick={()=>{return false}}
            page={calculatePage(Page, ItemOffset)}
            totalRecord={TotalRecords}
            rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            activePage={Page}
            handleOpenEditBlock={handleOpenEditBlock}
            handleCloseEditBlock={handleCloseEditBlock}
            hanldeOpenAllSchemeView={hanldeOpenAllSchemeView}
            setSelectedFormulary={handleSelectedFormulary}
            handleOpenCopyBlock={handleOpenCopyBlock}
          />
        </Box>
        <Icd10DiagnosisBlockFilterModal
          open={openFilter}
          handleClose={handleCloseFilter}
          handleFilterSubmit={handleFilterSubmit}
          initialValues={initialValueSortDate}
          handleFilterReset={handleFilterReset}
          filterButtonPosition={filterRef.current?.getBoundingClientRect()}
        />
        <AssignFormularySchemeDialog
        open={openAssignFormularyDialog}
        handleClose= {handleAssignFormularyDialog}
        />
        <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusSchemeSpecificFormulary.IsActive === 0 ? 'Activate' : 'Suspend'}
        paragraph={
          <>
            Are you sure you want to {updateStatusSchemeSpecificFormulary.IsActive === 0 ? 'activate' : 'suspend'}
            <br /> the Formulary ?
          </>
        }
        handleConfirm={handleConfirmInactive}
        
      />
      <SchemeViewDialog
      open={openAllSchemeView}
      handleClose={hanldeCloseAllSchemeView}
      FormularyId={selectedFormulary}/>

      <CopySchemeSpecificFormulary
        open={openCopyScheme}
        handleClose={handleCloseCopyScheme}
      />
    </>    

  )
}

export default AssignFormularyToScheme