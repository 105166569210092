import { Breadcrumbs, Typography } from '@mui/material';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { Link } from 'react-router-dom';

type LinkData = {
  name: string;
  link?: string;
};

type BreadcrumbProps = {
  metaData: LinkData[];
};

function Breadcrumb({ metaData }: BreadcrumbProps) {
  return (
    <Breadcrumbs
      separator={<KeyboardDoubleArrowRightOutlinedIcon fontSize="small" color="primary" />}
      aria-label="breadcrumb">
      {metaData.map((item: LinkData, index: number) => {
        if (item?.link) {
          return (
            <Link to={item.link} key={index}>
              <Typography variant="h1">{item.name}</Typography>
            </Link>
          );
        } else {
          return (
            <Typography
              variant="h1"
              color="primary"
              key={index}
              sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
              {item.name}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
