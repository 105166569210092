import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';

function ConfirmButton({ ...otherProps }: IconButtonProps) {
  return (
    <IconButton color="primary" sx={{ p: 0.25 }} {...otherProps}>
      <CheckIcon sx={{ fontSize: { xs: 'medium', sm: 'large' } }} />
    </IconButton>
  );
}

export default ConfirmButton;
