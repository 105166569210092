import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import showAlert from '../../utils/alert';
import END_POINTS from '../../utils/end.points';
import CONSTANTS from '../../utils/constants';
import ACTION_NAME from '../../utils/action.name';
import {
  checkOnline,
  convertCopyPayload,
  convertGetCopyBlockDataWhenInsurenceCompanyChagnePayload
} from '../../utils/common.function';
import {
  AddIcd10DiagnosisBlocksPayload,
  Block,
  CopyICD10BlockPayload,
  IAddMedicinePayload,
  Icd10DiagnosisBlocksListPayload,
  Icd10DiagnosisBlocksListPayloadRedux,
  IcdMedicineAllListResponse,
  ICopyBlockDataWhenInsurenceCompanyChagne,
  IICDDetailsPayload,
  IICDList,
  IIcdMedicine,
  IIcdMedicineApiPayload,
  IIcdMedicinePayload,
  UpdateStatusIcd10DiagnosisBlocks
} from '../../types/manage.icd10.diagnosis.block';
import { AxiosResponseGlobal } from '../../types/global.types';
import { AppDispatch, AppDispatchFunction } from '../../store/store';
import { AddBlock, CopyICD10Block, ReducersModal } from '../../modal';

export const getIcd10DiagnosisBlocks = () => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
  };
};

export const getIcd10DiagnosisBlocksList = ({
  page,
  limit,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive,
  insuranceCompanyId,
  statusId,
  fromDate,
  toDate
}: Icd10DiagnosisBlocksListPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST}?Page=${page}&Limit=${limit}${
      filterValue ? `&Search=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      insuranceCompanyId ? `&InsuranceCompanyId=${insuranceCompanyId}` : ''
    }${statusId ? `&IsActive=${statusId === '1' ? true : false}` : ''}${fromDate ? `&FromDate=${fromDate}` : ''}${
      toDate ? `&ToDate=${toDate}` : ''
    }`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(manageIcd10DiagnosisBlockListAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const addIcd10DiagnosisBlocks = (payload: AddIcd10DiagnosisBlocksPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const updateIcd10DiagnosisBlocks = (payload: AddIcd10DiagnosisBlocksPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

export const updateStatusIcd10DiagnosisBlocksApi = (
  payload: UpdateStatusIcd10DiagnosisBlocks,
  handleCloseInactive: () => void,
  type: 'detail' | 'list'
) => {
  return (dispatch: AppDispatchFunction, getState: () => ReducersModal) => {
    const { Page, ItemOffset, SortBy, SortOrder, InsuranceCompanyId, StatusId, FromDate, ToDate } =
      getState().manageIcd10DiagnosisBlocksReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.UPDATE_STATUS_ADMIN_ICD10_DIAGNOSIS, params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          if (type === 'list') {
            dispatch(
              getIcd10DiagnosisBlocksList({
                page: Page,
                limit: ItemOffset,
                sortBy: SortBy,
                sortOrder: SortOrder,
                ...(InsuranceCompanyId && { insuranceCompanyId: InsuranceCompanyId }),
                ...(StatusId && { statusId: StatusId }),
                ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate }),
                handleCloseInactive
              })
            );
          }
          if (type === 'detail') {
            dispatch(icdDetail(String(payload.BlockId), handleCloseInactive));
          }
          showAlert(1, data?.Message);
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

export const getICDList = ({
  page,
  limit,
  search,
  insuranceCompanyId
}: {
  page: number;
  limit: number;
  search: string;
  insuranceCompanyId: number;
}) => {
  return new Promise<AxiosResponseGlobal<IICDList>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.ADMIN_ICD10_DIAGNOSIS}?Page=${page}&Limit=${limit}&Search=${search}&InsuranceCompanyId=${insuranceCompanyId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * icd10 details api
 * @param payload
 * @returns
 */

export const icdDetail = (blockId: string, handleCloseInactive?: () => void) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST}/${blockId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(icdDetailAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

/**
 * medicine api for geting medicine list
 */

export const getMedicineList = ({
  blockId,
  page,
  limit,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive
}: IIcdMedicineApiPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `${END_POINTS.BLOCK_MEDICINE}?Page=${page}&Limit=${limit}&BlockId=${blockId}${
      filterValue ? `&Search=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(getMedicineAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      })
      .catch((err) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      });
  };
};

/** get all medicin list of adding new medicin */

export const getAllMedicine = ({
  page,
  limit,
  search,
  insuranceCompanyId,
  blockId
}: {
  page: number;
  limit: number;
  search: string;
  insuranceCompanyId: number;
  blockId: number;
}) => {
  return new Promise<AxiosResponseGlobal<IcdMedicineAllListResponse>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.GET_ALL_MEDICINE}?Page=${page}&Limit=${limit}&Search=${search}&InsuranceCompanyId=${insuranceCompanyId}&BlockId=${blockId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res: AxiosResponseGlobal<IcdMedicineAllListResponse>) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// soft delete medicine api

export const updateMedicine = ({ blockId, medicineId }: { blockId: number; medicineId: number }) => {
  return new Promise<AxiosResponseGlobal<null>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.BLOCK_MEDICINE}/${blockId}/${medicineId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.delete(params)
      .then((res: AxiosResponseGlobal<null>) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add medicine api
export const addMedicineApi = (payload: IAddMedicinePayload) => {
  return new Promise<AxiosResponseGlobal<null>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.BLOCK_MEDICINE}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(params, payload)
      .then((res: AxiosResponseGlobal<null>) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// copy get medicine api
export const copyGetMedicne = ({ page, limit, blockId }: { page: number; limit: number; blockId: number }) => {
  return new Promise<AxiosResponseGlobal<IIcdMedicine>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.BLOCK_MEDICINE}?Page=${page}&Limit=${limit}&BlockId=${blockId}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        const { data } = err;
        showAlert(2, data?.Message);
      });
  });
};

// copy post block api
export const copyBlock = (payload: ReturnType<typeof convertCopyPayload>) => {
  return new Promise<AxiosResponseGlobal<{ BlockId: number }>>((resolve, reject) => {
    checkOnline();
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

// when insurance company is changed then copy block api is called
export const getCopyBlockDataWhenInsurenceCompanyChagne = (
  payload: ReturnType<typeof convertGetCopyBlockDataWhenInsurenceCompanyChagnePayload>
) => {
  return new Promise<AxiosResponseGlobal<ICopyBlockDataWhenInsurenceCompanyChagne>>((resolve, reject) => {
    checkOnline();
    const params = payload;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.post(END_POINTS.INSURANCE_COMPANY_METADATA, params)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.POST_SUCCESS) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
        showAlert(2);
      });
  });
};

// action creater
export const manageIcd10DiagnosisBlockListAction = (payload: Icd10DiagnosisBlocksListPayloadRedux) => {
  return {
    type: ACTION_NAME.MANAGE_ICD10_DIAGNOSIS_BLOCKS_LIST,
    payload
  };
};

export const icdDetailAction = (payload: IICDDetailsPayload) => {
  return {
    type: ACTION_NAME.ICD_DETAIL,
    payload
  };
};

export const getMedicineAction = (payload: IIcdMedicinePayload) => {
  return {
    type: ACTION_NAME.ICD_MEDICINE,
    payload
  };
};

export const resetAddBlock = () => {
  return {
    type: ACTION_NAME.RESET_ADD_BLOCK,
    payload: {
      ...new AddBlock()
    }
  };
};
export const resetCopyICD10Block = ()=>{
  return {
    type: ACTION_NAME.RESET_COPY_ICD10_BLOCK,
    payload:{
      ... new CopyICD10Block()
    }
  }
}

export const getICD10BlockListForAutoComplete = ({
  page,
  limit,
  search
}: {
  page: number;
  limit: number;
  search:string
}) => {
  return new Promise<AxiosResponseGlobal<{ Blocks: Block[] }>>((resolve, reject) => {
    checkOnline();
    const params = `${END_POINTS.ADMIN_ICD10_DIAGNOSIS_BLOCK_LIST}?Page=${page}&Limit=${limit}&Search=${search}`;
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.get(params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const copyICD10Block = (payload: CopyICD10BlockPayload) => {
  return new Promise((resolve, reject) => {
    checkOnline();
    // const params = { FormularyId: payload.FormularyId, Name: payload.Name, InsuranceCompanyId: payload.InsuranceCompanyId };
    CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.COPY_ICD10BLOCK_MEDCINES, payload)
      .then((res) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      })
      .catch((error:any) => {
        if (error) showAlert(2, error?.response?.data?.Message);
          else showAlert(2);
          reject(error)
         });
        
  });
};