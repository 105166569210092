import { Box, Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import { Role } from '../../types/global.types';
import { whichPortalRun } from '../../utils/common.function';
import LOCAL_IMAGES from '../../utils/local.images';
import ROUTES_NAME from '../../utils/routes.name';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      width: '100%',
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        '& .activeText': {
          color: 'var(--white)',
          fontWeight: theme.typography.fontWeightBold
        },
        '& .active-img': {
          display: 'block'
        },
        '& .img': {
          display: 'none'
        }
      },
      '& .active-img': {
        display: 'none'
      }
    },
    linkBox: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5)
    },
    linkText: {
      '&.MuiTypography-root': {
        font: `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.6)} Roboto Sans, sans-serif`,
        cursor: 'pointer',
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1.5)
      }
    }
  })
);

function Sidebar() {
  const classes = useStyles();

  const reuseLinkContent = (linkName: string, icon: string, activeIcon: string) => {
    return (
      <Box className={classes.linkBox}>
        <img src={icon} className="img" alt="" />
        <img src={activeIcon} className="active-img" alt="" />
        <Typography className={`${classes.linkText} activeText`}>{linkName}</Typography>
      </Box>
    );
  };

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          flexBasis: '92%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            height: (theme) => theme.spacing(6.6),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img src={LOCAL_IMAGES.LOGO} alt="logo" />
        </Box>
        <NavLink to={ROUTES_NAME.FAQ} className={classes.link}>
          {reuseLinkContent('FAQs Management', LOCAL_IMAGES.FAQ, LOCAL_IMAGES.FAQ_ACTIVE)}
        </NavLink>
        <NavLink to={ROUTES_NAME.DOCTORS_VLOG} className={classes.link}>
          {reuseLinkContent('Doctor’s Video', LOCAL_IMAGES.DOCTORS_VLOG, LOCAL_IMAGES.DOCTORS_VLOG_ACTIVE)}
        </NavLink>
        <NavLink to={ROUTES_NAME.DOCTORS_RECOMMENDATION} className={classes.link}>
          {reuseLinkContent(
            'Doctor’s Recommendation',
            LOCAL_IMAGES.DOCTOR_RECOMMENDATION,
            LOCAL_IMAGES.DOCTOR_RECOMMENDATION_ACTIVE
          )}
        </NavLink>
        <NavLink to={ROUTES_NAME.MANAGE_CATEGORY} className={classes.link}>
          {reuseLinkContent('Manage Category', LOCAL_IMAGES.MANAGE_CATEGORY, LOCAL_IMAGES.MANAGE_CATEGORY_ACTIVE)}
        </NavLink>
        <NavLink to={ROUTES_NAME.MANAGE_POINT} className={classes.link}>
          {reuseLinkContent(
            'Manage Livia Points',
            LOCAL_IMAGES.MANAGE_LIVIA_POINST,
            LOCAL_IMAGES.MANAGE_LIVIA_POINST_ACTIVE
          )}
        </NavLink>
        {whichPortalRun() !== Role.Blog && (
          <>
            <NavLink to={ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS} className={classes.link}>
              {reuseLinkContent(
                'ICD10 Code Blocks',
                LOCAL_IMAGES.MANAGE_ICD10_DIAGNOSIS,
                LOCAL_IMAGES.MANAGE_ICD10_DIAGNOSIS_ACTIVE
              )}
            </NavLink>
          </>
        )}
        {whichPortalRun() !== Role.Blog && (
          <>
            <NavLink to={ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY} className={classes.link}>
              {reuseLinkContent(
                'Scheme Specific Formulary',
                LOCAL_IMAGES.MANAGE_SCHEME,
                LOCAL_IMAGES.MANAGE_SCHEME_ACTIVE
              )}
            </NavLink>
          </>
        )}
        {whichPortalRun() !== Role.Blog && (
          <>
            <NavLink to={ROUTES_NAME.ASSIGN_SPECIFIC_TO_SCHEME} className={classes.link}>
              {reuseLinkContent(
                'Assign Formulary to Scheme',
                LOCAL_IMAGES.ASSIGN_SCHEME,
                LOCAL_IMAGES.ASSIGN_SCHEME_ACTIVE
              )}
            </NavLink>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Sidebar;
