import { memo } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ModalWrapper from './modal.wrapper';
import LOCAL_IMAGES from '../../utils/local.images';
import ButtonWrapper from '../button/button.wrapper';

type SuccessModalProps = {
  open: boolean;
  handleClose: () => void;
  heading: string;
  paragraph: React.ReactNode;
};

function SuccessModal({ open, handleClose, heading, paragraph }: SuccessModalProps) {
  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: { xs: 300, sm: 450 },
        borderRadius: (theme) => theme.spacing(0.8),
        boxShadow: 'var(--link-box-shadow)'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          py: 4
        }}>
        <Box>
          <img src={LOCAL_IMAGES.SUCCESSFULLY} alt="" />
        </Box>
        <Typography variant="h1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, my: 3 }}>
          {heading}
        </Typography>
        <Typography
          variant="h1"
          sx={{ fontWeight: 400, textAlign: 'center', lineHeight: (theme) => theme.spacing(2.8) }}>
          {paragraph}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          <ButtonWrapper variant="contained" color="primary" onClick={handleClose} sx={{ px: 5, mt: 2 }}>
            Ok
          </ButtonWrapper>
        </Box>
      </Box>
    </ModalWrapper>
  );
}

export default memo(SuccessModal);
