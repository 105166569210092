import { Box, Grid } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import ButtonWrapper from "../../../../components/button/button.wrapper"
import AutocomFormularyWrap from "../../../../components/form-ui/autocom.wrap/autocom.formularywrap"
import TextFieldWrapper from "../../../../components/form-ui/textfield-wrapper/text.field.wrapper"
import { ReducersModal } from "../../../../modal"
import showAlert from "../../../../utils/alert"
import FORM_FIELD from "../../../../validations/form-field"
import SCHEMA from "../../../../validations/schema"
import { addFormulary, getAssignFormularytoSchemeList, getSchemeSpecificFormularyList } from "../../action"
type NewFormularyProps={
  handleClose:()=>void
}

export default function NewFormulary({handleClose}:NewFormularyProps) {
  const {SourceFormulary, FromAssignScheme} = useSelector((state:ReducersModal)=> state.copySchemeSpecificFormularyReducer);
  const { Page, ItemOffset } = useSelector((state: ReducersModal) => state.manageSchemeSpecificFormularyReducer);
  const [initialValue] = useState(FORM_FIELD.copyNewFormulary);
  const dispatch=useDispatch()
  const handleSubmitBlock = (
    value: typeof FORM_FIELD.copyNewFormulary,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.copyNewFormulary>
  ) => {
    // if (va
    if (value.insuranceCompany.InsuranceCompanyId === 0) {
      showAlert(2, 'Please select Insurance Company');
      setSubmitting(false);
      return;
    }
    
    const payload={
      InsuranceCompanyId:value.insuranceCompany.InsuranceCompanyId,
      Name:value.formularyName,
      FormularyId:Number(SourceFormulary)
    }
    addFormulary(payload)
        .then(() => {
          showAlert(1, 'Success');
          setSubmitting(false);
          if(FromAssignScheme){
            dispatch(
              getAssignFormularytoSchemeList({
                page: Page,
                limit: ItemOffset
              })
            );
          }
          else{
            dispatch(
              getSchemeSpecificFormularyList({
                page: Page,
                limit: ItemOffset
              })
            );
          }
          
          handleClose();
        })
        .catch(() => {
          setSubmitting(false);
        });
     
    
  };
  return (
    <Box>
      <Formik
        initialValues={initialValue}
        validationSchema={SCHEMA.copyNewFormulary}
        onSubmit={handleSubmitBlock}
        enableReinitialize>
        {({ isSubmitting }) => {
          return (
            <Form>
              <Grid container sx={{ pt:2 }} spacing={1.5}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <TextFieldWrapper
                      sx={{ mb: 2 }}
                      fullWidth
                      name="formularyName"
                      label="Add Formulary Name*"
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box>
                    <AutocomFormularyWrap name="insuranceCompany" label="Select Insurance Company" />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 2,
                    py:"10px"
                  }}>
                <ButtonWrapper
                    variant="outlined"
                    sx={{
                    color: (theme) => theme.palette.error.light,
                    px: { xs: 2, sm: 5 },
                    mr: 1,
                    py: 1,
                    borderColor: (theme) => theme.palette.error.light,
                    '&:hover': {
                        borderColor: (theme) => theme.palette.error.light
                    }
                    }}
                    onClick={handleClose}>
                    Cancel
                </ButtonWrapper>
                <ButtonWrapper
                    variant="contained"
                    type="submit"
                    color="primary"
                    sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
                    onClick={()=> {return false}}>
                    {isSubmitting? "Loading":"Proceed"}
                </ButtonWrapper>
              </Box>
                 
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
        {/* <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
              py:"10px"
            }}>
          <ButtonWrapper
              variant="outlined"
              sx={{
              color: (theme) => theme.palette.error.light,
              px: { xs: 2, sm: 5 },
              mr: 1,
              py: 1,
              borderColor: (theme) => theme.palette.error.light,
              '&:hover': {
                  borderColor: (theme) => theme.palette.error.light
              }
              }}
              onClick={handleClose}>
              Cancel
          </ButtonWrapper>
          <ButtonWrapper
              variant="contained"
              color="primary"
              sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
              onClick={()=> {return false}}>
              Proceed
          </ButtonWrapper>
        </Box> */}
    </Box>
    
    
  )
}
