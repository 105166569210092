const ACTION_NAME = {
  LOADING: 'LOADING',
  MANAGE_ICD10_DIAGNOSIS_BLOCKS_LIST: 'MANAGE_ICD10_DIAGNOSIS_BLOCKS_LIST',
  ADD_BLOCK: 'ADD_BLOCK',
  ADD_FORMULARY: 'ADD_FORMULARY',
  RESET_ADD_BLOCK: 'RESET_ADD_BLOCK',
  RESET_ADD_FORMULARY: 'RESET_ADD_FORMULARY',
  GET_MANAGE_CATEGORY: 'GET_MANAGE_CATEGORY',
  GET_DOCTOR_RECOMMENDATION_LIST: 'GET_DOCTOR_RECOMMENDATION_LIST',
  RECOMMENDATION_DETAIL: 'RECOMMENDATION_DETAIL',
  RECOMMENDATION_REPORT_LIST: 'RECOMMENDATION_REPORT_LIST',
  QUESTION_LIST: 'QUESTION_LIST',
  QUESTION_DETAIL: 'QUESTION_DETAIL',
  GET_DOCTOR_VLOG_LIST: 'GET_DOCTOR_VLOG_LIST',
  VLOG_DETAIL: 'VLOG_DETAIL',
  GET_NOTIFICATION_LIST: 'GET_NOTIFICATION_LIST',
  ICD_DETAIL: 'ICD_DETAIL',
  ICD_MEDICINE: 'ICD_MEDICINE',
  COPY_BLOCK: 'COPY_BLOCK',
  DELETE_COPY_ICD10: 'DELETE_COPY_ICD10',
  DELETE_COPY_MEDICINE: 'DELETE_COPY_MEDICINE',
  MANAGE_SCHEME_SPECIFIC_FORMULARY_LIST: 'MANAGE_SCHEME_SPECIFIC_FORMULARY_LIST',
  SCHEME_DETAIL: 'SCHEME_DETAIL',
  SCHEME_DETAIL_RESET: 'SCHEME_DETAIL_RESET',
  SCHEME_MEDICINE: 'SCHEME_MEDICINE',
  MANAGE_FORMULARY_ICD10_DIAGNOSIS_BLOCKS_LIST: 'MANAGE_FORMULARY_ICD10_DIAGNOSIS_BLOCKS_LIST',
  MANAGE_ASSIGN_FORMULARY_TO_SCHEME_LIST: 'MANAGE_ASSIGN_FORMULARY_TO_SCHEME_LIST',
  ASSIGN_FORMULARY_TO_SCHEME:"ASSIGN_FORMULARY_TO_SCHEME",
  RESET_ASSIGN_FORMULARY_TO_SCHEME:"RESET_ASSIGN_FORMULARY_TO_SCHEME",
  COPY_SCHEME_SPECIFIC_FORMULARY:"COPY_SCHEME_SPECIFIC_FORMULARY",
  RESET_COPY_SCHEME_SPECIFIC_FORMULARY:"RESET_COPY_SCHEME_SPECIFIC_FORMULARY",
  COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK:"COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK",
  RESET_COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK:"RESET_COPY_SCHEME_SPECIFIC_FORMULARY_BLOCK",
  COPY_ICD10_BLOCK:"COPY_ICD10_BLOCK",
  RESET_COPY_ICD10_BLOCK:"RESET_COPY_ICD10_BLOCK"
};

export default ACTION_NAME;
