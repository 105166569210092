import Autocomplete from '@mui/material/Autocomplete';
import InputField from '../../components/form-ui/textfield-wrapper/input.field';
import { getICDList } from './action';
import { IDiagnosis } from '../../types/manage.icd10.diagnosis.block';
import showAlert from '../../utils/alert';
import { Fragment, useEffect, useState } from 'react';
import useDebounce from '../../hooks/use.debounce';
import { useDispatch, useSelector } from 'react-redux';
import ACTION_NAME from '../../utils/action.name';
import { ReducersModal } from '../../modal';

type AutocomICDProps = {
  label: string;
};

export default function AutocomICD({ label }: AutocomICDProps) {
  const dispatch = useDispatch();
  const { icd10, insuranceCompany } = useSelector((state: ReducersModal) => state.addBlockReducer);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly IDiagnosis[]>([]);
  const [inputValue, setInputValue] = useState('');
  const debounceValue = useDebounce(inputValue, 500);

  useEffect(() => {
    let active = true;

    if (!debounceValue) {
      return undefined;
    }
    if (insuranceCompany.InsuranceCompanyId === 0) return;
    (async () => {
      try {
        const res = await getICDList({
          page: 1,
          limit: 20,
          search: debounceValue,
          insuranceCompanyId: insuranceCompany.InsuranceCompanyId
        });
        if (active) {
          setOptions(res.data?.Data?.Diagnosis);
        }
      } catch (_err) {
        showAlert(2);
      }
    })();

    return () => {
      active = false;
    };
  }, [debounceValue]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      disabled={insuranceCompany.InsuranceCompanyId === 0}
      size="small"
      onChange={(_e, newValue) => {
        if (newValue) {
          const findIcd = icd10.find((ele) => ele.Code === newValue.Code);
          if (findIcd) return;
          dispatch({
            type: ACTION_NAME.ADD_BLOCK,
            payload: {
              icd10: [...icd10, newValue]
            }
          });
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'reset') setInputValue('');
        else setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.Code === value.Code || option.Description === value.Description}
      getOptionLabel={(option) => `${option.Code}-(${option.Description})`}
      options={options}
      renderInput={(params) => (
        <InputField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
            autoComplete: 'off'
          }}
        />
      )}
    />
  );
}
