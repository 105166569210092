import { Box, Theme } from '@mui/material';
import React from 'react';
import GlobalLoader from '../components/global-loader/global.loader';
import Header from '../components/header/header';
import Sidebar from '../components/sidebar/sidebar';
import { makeStyles, createStyles } from '@mui/styles';
import SESSION_MANAGEMENT from '../utils/session.management';
import { Navigate, useLocation } from 'react-router-dom';
import ROUTES_NAME from '../utils/routes.name';
import { whichPortalRun } from '../utils/common.function';
import { Role } from '../types/global.types';

type PrivateContainerProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'grid',
      gridTemplateColumns: `20% 1fr`,
      gridTemplateAreas: `"nav header"
      "nav main"`,
      [theme.breakpoints.down('md')]: {
        gridTemplateAreas: `"header header"
      "main main"`
      }
    },
    header: {
      display: 'flex',
      flexBasis: '100%',
      gridArea: 'header',
      height: theme.spacing(7),
      position: 'relative'
    },
    nav: {
      gridArea: 'nav',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    main: {
      gridArea: 'main',
      overflowX: 'hidden',
      overflowY: 'auto',
      height: 'calc(100vh - 70px)',
      backgroundColor: theme.palette.secondary.light
    }
  })
);

function PrivateContainer({ children }: PrivateContainerProps) {
  const location = useLocation();
  const classes = useStyles();

  if (!SESSION_MANAGEMENT.getAccessToken()) {
    return <Navigate to={ROUTES_NAME.Login} replace />;
  }

  const checkIcdLink = () => {
    return [ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS, ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY].includes(
      location.pathname
    );
  };
  if (whichPortalRun() === Role.Blog && checkIcdLink()) {
    return <Navigate to={ROUTES_NAME.FAQ} replace />;
  }

  return (
    <>
      <GlobalLoader />
      <Box className={classes.root}>
        <Box component="header" className={classes.header}>
          <Header />
        </Box>
        <Box component="nav" className={classes.nav}>
          <Sidebar />
        </Box>
        <Box component="main" className={classes.main}>
          <Box sx={{ p: { xs: 0.8, sm: 1.5 } }}>{children}</Box>
        </Box>
      </Box>
    </>
  );
}

export default PrivateContainer;
