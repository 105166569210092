import { IconButton, InputBase, Theme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

type SearchTextfieldProps = {
  name: 'searchInput';
  placeholder: string;
  inputValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (event: React.SyntheticEvent) => void;
} & BoxProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.spacing(0.6),
      width: '70%',
      display: 'flex',
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    inputBase: {
      '&.MuiInputBase-root': {
        flexBasis: '80%',
        color: theme.palette.primary.main,
        font: `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.6)} Roboto Sans, sans-serif`
      }
    }
  })
);

function SearchTextfield({
  name,
  placeholder,
  inputValue,
  handleSearchSubmit,
  handleSearchChange,
  ...otherProps
}: SearchTextfieldProps) {
  const classes = useStyles();
  return (
    <Box className={classes.root} {...otherProps} component="form" onSubmit={handleSearchSubmit}>
      <IconButton type="submit" aria-label="search" color="primary">
        <SearchIcon />
      </IconButton>
      <InputBase
        name={name}
        onChange={handleSearchChange}
        value={inputValue}
        className={classes.inputBase}
        placeholder={placeholder}
        inputProps={{ 'aria-label': `${placeholder}` }}
      />
    </Box>
  );
}

export default SearchTextfield;
