import { Box, TablePagination, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import NotificationCard from '../../components/notification-card/notification.card';
import { ReducersModal } from '../../modal';
import { getNotificationList, getNotificationListAction } from './action';

function Notification() {
  const dispatch = useDispatch();
  const { Notifications, Page, Limit, TotalRecords } = useSelector(
    (state: ReducersModal) => state.notificationListReducer
  );

  useEffect(() => {
    if (Page) dispatch(getNotificationList(Page, Limit));
  }, [Page, Limit]);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPages: number) => {
    const newPage = newPages + 1;
    dispatch(getNotificationListAction({ Page: newPage }));
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Typography variant="h1">Notifications</Typography>
        <ButtonWrapper variant="contained" color="primary" sx={{ display: 'none' }}>
          Clear All
        </ButtonWrapper>
      </Box>
      <Box
        sx={{
          mt: 2,
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <Box>
          {Notifications?.length > 0 ? (
            Notifications.map((item) => <NotificationCard key={item.NotificationId} item={item} />)
          ) : (
            <Typography variant="h1" sx={{ textAlign: 'center' }}>
              No notification available
            </Typography>
          )}
          {Notifications?.length > 0 && (
            <Box>
              <TablePagination
                sx={{
                  '& .MuiTablePagination-selectLabel': {
                    display: 'none'
                  },
                  '& .MuiTablePagination-selectRoot': {
                    display: 'none'
                  },
                  '& .MuiTablePagination-select': {
                    display: 'none'
                  },
                  '& .MuiTablePagination-selectIcon': {
                    display: 'none'
                  }
                }}
                component="div"
                count={TotalRecords}
                page={Page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={10}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Notification;
