const addCategory = {
  categoryName: ''
};

const addIcd10DiagnosisBlocks = {
  blockName: '',
  insuranceCompanyId: '',
  diagnosisIds: ''
};

const filterListing = {
  fromDate: null,
  toDate: null,
  category: 0
};

const icd10DiagnosisFilterListing = {
  fromDate: null,
  toDate: null,
  insuranceCompanyId: 0,
  statusId: 'all'
};
const schemeSpecificFilterListing = {
  fromDate: null,
  toDate: null,
  insuranceCompanyId: 0,
  statusId: 'all'
};
const assignSchemeFilterListing = {
  fromDate: null,
  toDate: null,
  insuranceCompanyId: 0,
  statusId: 'all'
};

const pointAnswer = {
  answer: ''
};

const pointLike = {
  like: ''
};

const pointDisLike = {
  dislike: ''
};

const login = {
  email: '',
  password: ''
};

const addBlock = {
  blockName: '',
  insuranceCompany: {
    InsuranceCompanyId: 0,
    Name: '',
    LogoURL: null
  }
};

const addFormulary = {
  formularyName: '',
  insuranceCompany: {
    InsuranceCompanyId: 0,
    Name: '',
    LogoURL: null
  }
};
const copyNewFormulary = {
  formularyName: '',
  insuranceCompany: {
    InsuranceCompanyId: 0,
    Name: '',
    LogoURL: null
  }
};

const FORM_FIELD = {
  addCategory,
  addIcd10DiagnosisBlocks,
  filterListing,
  icd10DiagnosisFilterListing,
  schemeSpecificFilterListing,
  assignSchemeFilterListing,
  pointAnswer,
  pointLike,
  pointDisLike,
  login,
  addBlock,
  addFormulary,
  copyNewFormulary
};

export default FORM_FIELD;
