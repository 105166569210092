import { Typography, Box, alpha } from '@mui/material';
import { useState } from 'react';

type ReadMoreProps = {
  children: string;
  showString: number;
};

function ReadMore({ children, showString }: ReadMoreProps) {
  const [isReadMore, setIsReadMore] = useState(true);
  const text = children;
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: (theme) => theme.spacing(1.8),
        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.5),
        padding: (theme) => theme.spacing(1, 2),
        marginTop: (theme) => theme.spacing(0.5),
        borderRadius: (theme) => theme.spacing(0.5),
        textAlign: 'justify',
        color: (theme) => theme.palette.primary.main
      }}>
      {text.length < showString ? text : isReadMore ? text.slice(0, showString) : text}
      <Box
        sx={{
          display: 'inline',
          cursor: 'pointer',
          fontWeight: (theme) => theme.typography.fontWeightBold,
          fontSize: (theme) => theme.spacing(1.8),
          color: (theme) => theme.palette.common.black
        }}
        onClick={toggleReadMore}>
        {text.length < showString ? '' : isReadMore ? '... View More>' : ' <View Less'}
      </Box>
    </Typography>
  );
}

export default ReadMore;
