import { DoctorRecommendationModal, RecommendationsDetailModal, RecommendationReportListModal } from '../../modal';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';

export const doctorRecommendationReducer = (
  state: DoctorRecommendationModal = new DoctorRecommendationModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.GET_DOCTOR_RECOMMENDATION_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const recommendationDeatilReducer = (
  state: RecommendationsDetailModal = new RecommendationsDetailModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.RECOMMENDATION_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const recommendationReportListReducer = (
  state: RecommendationReportListModal = new RecommendationReportListModal(),
  action: ActionType
) => {
  switch (action.type) {
    case ACTION_NAME.RECOMMENDATION_REPORT_LIST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
