import * as Yup from 'yup';

const addCategory = () =>
  Yup.object().shape({
    categoryName: Yup.string()
      .trim()
      .required('Category name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .min(3, 'min 3 characters required')
      .max(100, 'max 100 characters required')
      .nullable()
  });

const addIcd10DiagnosisBlock = () =>
  Yup.object().shape({
    blockName: Yup.string()
      .trim()
      .required('Block name is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .min(3, 'min 3 characters required')
      .max(100, 'max 100 characters required')
      .nullable(),
    insuranceCompanyId: Yup.number().required('Insurance Company is required').nullable(),
    diagnosisIds: Yup.string().trim().required('ICD10 Diagnosis is required').nullable()
  });

const filterModal = () =>
  Yup.object().shape({
    fromDate: Yup.date().nullable().typeError('Invaild!'),
    toDate: Yup.date().nullable().typeError('Invaild!')
  });

const pointAnswer = () =>
  Yup.object().shape({
    answer: Yup.number().required('Answer point is required').nullable()
  });

const pointLike = () =>
  Yup.object().shape({
    like: Yup.number().required('Like point is required').nullable()
  });

const pointDisLike = () =>
  Yup.object().shape({
    dislike: Yup.number().required('Dislike point is required').nullable()
  });

const login = () =>
  Yup.object().shape({
    email: Yup.string().trim().email().required('Email is required'),
    password: Yup.string()
      .min(8, 'Must be greater then 8 characters')
      .required('Please enter new password')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

const addBlock = () =>
  Yup.object().shape({
    blockName: Yup.string()
      .trim()
      .required('Block name is required')
      .min(3, 'min 3 characters required')
      .max(50, 'max 100 characters required')
      .matches(/^([a-zA-Z0-9 _-]+)$/, 'Enter valid block name')
      .nullable(),
    insuranceCompany: Yup.object().required('Block name is required').nullable()
  });

const addFormulary = () =>
  Yup.object().shape({
    formularyName: Yup.string()
      .trim()
      .required('Formulary name is required')
      .min(3, 'min 3 characters required')
      .max(50, 'max 100 characters required')
      .matches(/^([a-zA-Z0-9 _-]+)$/, 'Enter valid formulary name')
      .nullable(),
    insuranceCompany: Yup.object().required('Formulary name is required').nullable()
  });
  const copyNewFormulary = () =>
  Yup.object().shape({
    formularyName: Yup.string()
      .trim()
      .required('Formulary name is required')
      .min(3, 'min 3 characters required')
      .max(50, 'max 100 characters required')
      .matches(/^([a-zA-Z0-9 _-]+)$/, 'Enter valid formulary name')
      .nullable(),
    insuranceCompany: Yup.object().required('Formulary name is required').nullable()
  });

const SCHEMA = {
  addCategory,
  addIcd10DiagnosisBlock,
  filterModal,
  pointAnswer,
  pointLike,
  pointDisLike,
  login,
  addBlock,
  addFormulary,
  copyNewFormulary
};

export default SCHEMA;
