import { Box, TableContainer, Table, TableHead, TableBody, Typography, SelectChangeEvent } from '@mui/material';
import TableCellWrapper from '../table/table.cell.wrapper';
import TableRowWrapper from '../table/table.row.wrapper';
import { REPORT_DETAIL_SELECT_MENU_ITEM } from '../../utils/static.data';
import SelectWrapper from '../form-ui/select-wrapper/select.wrapper';
import { ReportItem } from '../../types/doctor.recommendation.types';
import { formatDateAndTime, getReportReason } from '../../utils/common.function';
import PaginateWrapper from '../paginate-wrapper/paginate.wrapper';
import { FaqReportItem } from '../../types/faq.types';
import { ReportListType } from '../../types/global.types';

type ReportDetailTableProps = {
  title: string;
  data: ReportItem[] | FaqReportItem[];
  pageCount: number;
  rowPerPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  totalRecord: number;
  page: number;
  handleChangeShowSort: (event: SelectChangeEvent<unknown>) => void;
  itemOffset: number;
  type: ReportListType;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
};

function ReportDetailTable({
  title,
  data,
  pageCount,
  handlePageClick,
  page,
  totalRecord,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage,
  handleChangeShowSort,
  itemOffset,
  type
}: ReportDetailTableProps) {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1.5 }}>
        <Typography variant="h1" sx={{ fontSize: (theme) => theme.spacing(2) }}>
          {title}
        </Typography>
        <SelectWrapper value={itemOffset} onChange={handleChangeShowSort} menuItem={REPORT_DETAIL_SELECT_MENU_ITEM} />
      </Box>
      <Box>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            py: 1.5,
            borderRadius: (theme) => theme.spacing(0.8)
          }}>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRowWrapper>
                  <TableCellWrapper>S.No.</TableCellWrapper>
                  <TableCellWrapper>Reported by</TableCellWrapper>
                  <TableCellWrapper>Reason</TableCellWrapper>
                  <TableCellWrapper>Reported at</TableCellWrapper>
                </TableRowWrapper>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  data.map((item, index: number) => {
                    return (
                      <TableRowWrapper key={index}>
                        <TableCellWrapper>{index + page}</TableCellWrapper>
                        <TableCellWrapper>{item.PatientName ?? 'N/A'}</TableCellWrapper>
                        <TableCellWrapper>
                          {(getReportReason(item.ReportReasonEnumId, type) || item.ReportReason) ?? 'N/A'}
                        </TableCellWrapper>
                        <TableCellWrapper>{formatDateAndTime(item.CreatedAt) ?? 'N/A'}</TableCellWrapper>
                      </TableRowWrapper>
                    );
                  })
                ) : (
                  <TableRowWrapper>
                    <TableCellWrapper colSpan={5}>No data found</TableCellWrapper>
                  </TableRowWrapper>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ mt: 2 }}>
          {data?.length > 0 && (
            <PaginateWrapper
              activePage={activePage}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              page={page}
              rowpPerPage={rowPerPage}
              totalRecord={totalRecord}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              // isShowPreAndNext
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ReportDetailTable;
