import { Avatar, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../../components/button/button.wrapper';
import { ReducersModal } from '../../../modal';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ACTION_NAME from '../../../utils/action.name';
import { convertDiagosis } from '../../../utils/common.function';
import AddBlock from '../add.block';
import InactiveModal from '../../../components/modal-wrapper/inactive.modal';
import { useState } from 'react';
import { updateStatusIcd10DiagnosisBlocksApi } from '../action';
import CONSTANTS from '../../../utils/constants';

function DiabetesBlock() {
  const dispatch = useDispatch();
  const [openInactive, setOpenInactive] = useState(false);
  const {
    BlockId,
    Name,
    InsuranceCompanyId,
    InsuranceCompanyName,
    InsuranceCompanyLogoURL,
    Diagnosis,
    IsActive,
    MedicineCount
  } = useSelector((state: ReducersModal) => state.icdDetailReducer);
  const { openBlock } = useSelector((state: ReducersModal) => state.addBlockReducer);

  const handleEditBlock = () => {
    if (!BlockId) return;
    const diagnosis = convertDiagosis(Diagnosis);
    dispatch({
      type: ACTION_NAME.ADD_BLOCK,
      payload: {
        page: 'detail',
        openBlock: true,
        blockId: BlockId,
        type: 'edit',
        blockName: Name,
        insuranceCompany: {
          InsuranceCompanyId: InsuranceCompanyId,
          Name: InsuranceCompanyName,
          LogoURL: null
        },
        icd10: diagnosis
      }
    });
  };

  const handleCloseBlock = () => {
    dispatch({
      type: ACTION_NAME.ADD_BLOCK,
      payload: {
        openBlock: false
      }
    });
  };

  const handleCloseInactive = () => {
    setOpenInactive(false);
  };

  const handleOpenInactive = () => {
    setOpenInactive(true);
  };

  const handleConfirmInactive = () => {
    const payload = {
      BlockId: BlockId,
      IsActive: IsActive === 0 ? 1 : 0
    };
    dispatch(updateStatusIcd10DiagnosisBlocksApi(payload, handleCloseInactive, 'detail'));
  };

  const title = (name: string, count: number | string, img?: boolean) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
      <Box
        sx={{
          width: 33,
          height: 33,
          bgcolor: 'primary.main',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: (theme) => theme.shadows[3],
          mr: 1
        }}>
        {img ? (
          <Avatar
            sx={{
              bgcolor: (theme) => theme.palette.secondary.light
            }}>
            <img src={CONSTANTS.IMAGE_BASE_URL + count} alt="" width="40" height="40" />
          </Avatar>
        ) : (
          <Typography variant="h1" sx={{ color: 'common.white' }}>
            {count ?? count}
          </Typography>
        )}
      </Box>
      <Typography variant="h1" sx={{ color: 'common.white' }}>
        {name}
      </Typography>
    </Box>
  );

  return (
    <>
      <Box sx={{ bgcolor: 'primary.light', borderRadius: 1.5, px: 2, py: 1, my: 2 }}>
        <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ color: 'common.white', flexBasis: '70%' }}>
            {Name}
          </Typography>
          <Box>
            <ButtonWrapper
              onClick={handleEditBlock}
              sx={{ px: 1, mr: 1 }}
              size="small"
              startIcon={<ModeEditOutlineOutlinedIcon />}
              variant="contained">
              Edit Block
            </ButtonWrapper>
            <ButtonWrapper
              onClick={handleOpenInactive}
              sx={{ px: 1 }}
              size="small"
              startIcon={IsActive === 0 ? <CheckIcon /> : <DoDisturbOutlinedIcon />}
              variant="contained">
              {IsActive === 0 ? 'Activate' : 'Suspend'}
            </ButtonWrapper>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '80%',
            flexWrap: 'wrap',
            my: 2
          }}>
          {title(InsuranceCompanyName, InsuranceCompanyLogoURL, true)}
          {title('Medicines', MedicineCount)}
          {title('ICD10 Code(s)', Diagnosis.length)}
        </Box>
      </Box>
      <AddBlock open={openBlock} handleClose={handleCloseBlock} />
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={IsActive === 0 ? 'Activate' : 'Suspend'}
        paragraph={
          <>
            Are you sure you want to {IsActive === 0 ? 'activate' : 'suspend'}
            <br /> the selected block?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
}

export default DiabetesBlock;
