import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

type ChipCusProps = ChipProps;

const ChipCustomize = styled(Chip)<ChipProps>(({ theme }) => ({
  color: theme.palette.common.white,
  font: `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.7)} Roboto Sans, sans-serif`
}));

function ChipCus({ ...otherProps }: ChipCusProps) {
  return <ChipCustomize {...otherProps} />;
}

export default ChipCus;
