import { Avatar, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../../components/button/button.wrapper';
import { ReducersModal } from '../../../modal';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';
import CheckIcon from '@mui/icons-material/Check';
import InactiveModal from '../../../components/modal-wrapper/inactive.modal';
import { useEffect, useState } from 'react';
import CONSTANTS from '../../../utils/constants';
import useToggle from '../../../hooks/use.toggle';
import BootstrapInput from '../../../components/form-ui/textfield-wrapper/bootstrap.input';
// import { nameRegex } from '../../../utils/common.function';
import showAlert from '../../../utils/alert';
import END_POINTS from '../../../utils/end.points';
import { schemeDetailAction, updateStatusFormularyApi } from '../action';
import { Link, useLocation } from 'react-router-dom';
import { makeBreadcrumb } from '../../../utils/breadcrumb';
import ROUTES_NAME from '../../../utils/routes.name';
import { Edit } from '@mui/icons-material';
import LOCAL_IMAGES from '../../../utils/local.images';

function SchemeBlock() {
  const [openInactive, setOpenInactive] = useState(false);
  const [state, toggle] = useToggle();
  const [editBrand, setEditBrand] = useState({
    name: '',
    isLoading: false,
    error: ''
  });
  const [breadCrumb, setBreadCrumb] = useState<string[]>([])
  const dispatch = useDispatch();
  const {
    FormularyId,
    Name,
    InsuranceCompanyName,
    InsuranceCompanyLogoURL,
    IsActive,
    InsuranceCompanyId,
    BlockCount
  } = useSelector((state: ReducersModal) => state.schemeDetailReducer);
  const location= useLocation()

  const handleCloseInactive = () => {
    setOpenInactive(false);
  };

  const handleOpenInactive = () => {
    setOpenInactive(true);
  };

  const handleConfirmInactive = () => {
    if (!FormularyId) return showAlert(2, "Can't find formulary id");
    const payload = {
      FormularyId,
      IsActive: IsActive === 0 ? true : false
    };
    dispatch(updateStatusFormularyApi(payload, handleCloseInactive, 'detail'));
  };
  useEffect(()=>{
    // eslint-disable-next-line no-console
    console.log(makeBreadcrumb(location?.pathname, Name))
    setBreadCrumb(makeBreadcrumb(location?.pathname, Name))
  },[location, Name])

  const handleEditBrand = async () => {
    // if (!nameRegex.test(editBrand.name)) return showAlert(2, 'Enter valid Brand name');
    const payload = {
      FormularyId: FormularyId,
      Name: editBrand.name,
      InsuranceCompanyId: InsuranceCompanyId

    };
    try {
      setEditBrand({ ...editBrand, isLoading: true });
      const { status, data } = await CONSTANTS.ICD10_DIAGNOSIS_BLOCK_AXIOS.patch(END_POINTS.FORMULARY, payload);
      if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
        setEditBrand({ ...editBrand, isLoading: false });
        dispatch(schemeDetailAction(data?.Data));
        toggle();
      }
    } catch (err:any) {
      if (err && err?.response?.status === 400) showAlert(2, err?.response?.data?.Message);
      else showAlert(2);
      setEditBrand({ ...editBrand, isLoading: false });
      
    }
  };

  const title = (name: string, count: number | string, img?: boolean) => (
    <Box
      sx={{
        flexBasis: '50%'
       }}>
      {img && <Box sx={{display:"flex", alignItems: 'center'}}>
        <Box
          sx={{
            ...(img ? { width: 33 } : { minWidth: 33, p: 1 }),
            height: 33,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: (theme) => theme.shadows[3],
            mr: 1
          }}>
          <Avatar
              sx={{
                bgcolor: (theme) => theme.palette.secondary.light
              }}>
              <img src={CONSTANTS.IMAGE_BASE_URL + count} alt="" width="40" height="40" />
            </Avatar>
          
       </Box>
        <Typography variant="h1" sx={{textDecoration:"underline", fontWeight:"700"}}>
          {name}
        </Typography>
      </Box>}
      
      {!img && <Box sx={{display:"flex", alignItems: 'center'}}>
        <img src={LOCAL_IMAGES.MANAGE_ICD10_DIAGNOSIS} className="img" alt="" />&nbsp;&nbsp;&nbsp;
        <Typography variant="h1" sx={{textDecoration:"underline", fontWeight:"700"}}>
        {name}
        </Typography>
        <Typography variant="h1" sx={{textDecoration:"underline", fontWeight:"700", color:"primary.main"}}>
              &nbsp;{ ` (${count})`}
        </Typography>
      </Box>
      }
    </Box>
  );

  return (
    <>
      <Typography variant="h1">
        <Link to={ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY} style={{color:"#308E99"}}>
          {breadCrumb[0]}
        </Link>
        {' > '}
        <Link to="" style={{fontWeight:"bold", color:"black"}}>
          {breadCrumb[1]}
        </Link>
      </Typography>
      <Box sx={{ borderRadius: 1.5, px: 2, py: 1, my: 2, bgcolor:"common.white" }}>
        <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexBasis: '50%' }}>
            {state ? (
              <BootstrapInput
                value={editBrand.name}
                placeholder="Enter Formulary name"
                onChange={(e) =>
                  setEditBrand({
                    ...editBrand,
                    name: e.target.value
                  })
                }
              />
            ) : (
              <Typography variant="h2" sx={{color: 'primary.main'}}>
                {Name}
              </Typography>
            )}
            <Box sx={{ ml: 1 }}>
              {state ? (
                <>
                  <ButtonWrapper
                    disabled={editBrand.isLoading}
                    onClick={toggle}
                    sx={{ px: 1 }}
                    size="small"
                    variant="contained">
                    Cancel
                  </ButtonWrapper>
                  <ButtonWrapper
                    disabled={editBrand.isLoading}
                    onClick={handleEditBrand}
                    sx={{ px: 1, ml: 1 }}
                    size="small"
                    variant="contained">
                    {editBrand.isLoading ? 'Loading...' : 'Save'}
                  </ButtonWrapper>
                </>
              ) : ""}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexBasis: '50%' ,justifyContent: !state?'space-around':'end',}}>
            {!state && <ButtonWrapper
                onClick={() => {
                  setEditBrand({ ...editBrand, name: Name });
                  toggle();
                }}
                sx={{ px: 1 }}
                size="small"
                startIcon={ <Edit />}
              variant="contained">
              {'Edit Formulary Name'}
            </ButtonWrapper>}
            <ButtonWrapper
              onClick={handleOpenInactive}
              sx={{ px: 1 }}
              size="small"
              startIcon={IsActive === 0 ? <CheckIcon /> : <DoDisturbOutlinedIcon />}
              variant="contained">
              {IsActive === 0 ? 'Activate Formulary' : 'Suspend Formulary'}
            </ButtonWrapper>
          </Box>
        </Box>
        <hr style={{marginTop:"10px", marginBottom:"10px", borderTop:"1px dashed #308E99"}}></hr>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent:"space-between",
            my: 2
          }}>
          {title(InsuranceCompanyName, InsuranceCompanyLogoURL, true)}
          {/* {title('Schemes', SchemeCount)}
          {title('Medicines', MedicineCount)} */}
          {title('Active ICD10 Code(s) Block', BlockCount)}
        </Box>
      </Box>
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={IsActive === 0 ? 'Activate' : 'Suspend'}
        paragraph={
          <>
            Are you sure you want to {IsActive === 0 ? 'activate' : 'suspend'}
            <br /> the Formulary ?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
}

export default SchemeBlock;
