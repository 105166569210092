export interface NotificationItem {
  SenderId: number;
  Title: string;
  Description: string;
  EventClass: Partial<EventClassEnum>;
  EventName: Partial<EventNameEnum>;
  EntityId: number;
  IsActive: boolean;
  CreatedAt: string;
  CreatedBy: number;
  ModifiedAt: string | null;
  ModifiedBy: string | null;
  IsRead: boolean;
  NotificationId: string;
  SenderImage: string;
  SenderName: string;
}

export interface NotificationList {
  TotalRecords: number;
  Page: number;
  Notifications: NotificationItem[];
  NextPage: boolean;
  Limit: number;
  TotalCount: number;
  PrevEntityId: number;
}

export interface NotificationApiResponse {
  QuestionCount: number;
  RecommendationCount: number;
  VlogCount: number;
}

export type NotificationPayloadRedux = Partial<NotificationList>;

export enum EventClassEnum {
  ANSWER = 1,
  QUESTION = 2,
  RECOMMENDATION = 3,
  VLOG = 4
}

export enum EventNameEnum {
  CREATE = 1,
  LIKE = 2,
  DISLIKE = 3,
  REPORT = 4,
  DELETE = 5
}
