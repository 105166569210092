import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

type TooltipWrapperProps = {
  children: React.ReactNode;
  title: string;
} & TooltipProps;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--white)'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--white)',
    color: theme.palette.primary.main
  }
}));

function TooltipWrapper({ children, title, ...otherProps }: TooltipWrapperProps) {
  return (
    <StyledTooltip title={title} {...otherProps}>
      {children}
    </StyledTooltip>
  );
}

export default TooltipWrapper;
