import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DoctorRecommendation from '../pages/doctors-recommendation/doctor.recommendation';
import DoctorRecomDetail from '../pages/doctors-recommendation/doctor-recom-detail/doctor.recom.detail';
import DoctorVlog from '../pages/doctors-vlog/doctors.vlog';
import Faq from '../pages/faq/faq';
import FaqDetail from '../pages/faq/faq-detail/faq.detail';
import ManageCategory from '../pages/manage-category/manage.category';
import ROUTES_NAME from '../utils/routes.name';
import ProtectedRoutes from './protected.routes';
import DoctorVlogDetail from '../pages/doctors-vlog/doctor-vlog-detail/doctor.vlog.detail';
import Notification from '../pages/notification/notification';
import Login from '../pages/login/login';
import ManagePoint from '../pages/manage-point/manage.point';
import IcdDetail from '../pages/manage-icd10-diagnosis/icd-detail/icd.detail';
import ManageIcd10DiagnosisBlocks from '../pages/manage-icd10-diagnosis/manage.icd10.diagnosis';
import SchemDetail from '../pages/scheme-specific-formulary/scheme-detail/scheme.detail';
import SchemeSpecificFormulary from '../pages/scheme-specific-formulary/scheme.specific.formulary';
import MedicineDetail from '../pages/scheme-specific-formulary/scheme-detail/medicine-detail/medicine-detail';
import AssignFormularyToScheme from '../pages/scheme-specific-formulary/assign-formulary-to-scheme/assign.formulary';
// import AssignFormularyToScheme from '../pages/scheme-specific-formulary/assign-formulary-to-scheme/assign.formulary.list';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES_NAME.Login} element={<Login />} />
        <Route
          path={ROUTES_NAME.FAQ}
          element={
            <ProtectedRoutes>
              <Faq />
            </ProtectedRoutes>
          }
        />
        <Route
          path={`${ROUTES_NAME.FAQ_DETAIL}/:faqId`}
          element={
            <ProtectedRoutes>
              <FaqDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.DOCTORS_VLOG}
          element={
            <ProtectedRoutes>
              <DoctorVlog />
            </ProtectedRoutes>
          }
        />
        <Route
          path={`${ROUTES_NAME.DOCTORS_VLOG_DETAIL}/:recommendationId`}
          element={
            <ProtectedRoutes>
              <DoctorVlogDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.DOCTORS_RECOMMENDATION}
          element={
            <ProtectedRoutes>
              <DoctorRecommendation />
            </ProtectedRoutes>
          }
        />
        <Route
          path={`${ROUTES_NAME.DOCTORS_RECOMMENDATION_DETAIL}/:recommendationId`}
          element={
            <ProtectedRoutes>
              <DoctorRecomDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.MANAGE_CATEGORY}
          element={
            <ProtectedRoutes>
              <ManageCategory />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.NOTIFICATION}
          element={
            <ProtectedRoutes>
              <Notification />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.MANAGE_POINT}
          element={
            <ProtectedRoutes>
              <ManagePoint />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS}
          element={
            <ProtectedRoutes>
              <ManageIcd10DiagnosisBlocks />
            </ProtectedRoutes>
          }
        />
        <Route
          path={`${ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS_DETAIL}/:blockId`}
          element={
            <ProtectedRoutes>
              <IcdDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY}
          element={
            <ProtectedRoutes>
              <SchemeSpecificFormulary />
            </ProtectedRoutes>
          }
        />
        <Route
          path={`${ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL}/:schemeId`}
          element={
            <ProtectedRoutes>
              <SchemDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={`${ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL}/:schemeId/${ROUTES_NAME.SCHEME_SPECIFIC_FORMULARY_DETAIL_MEDICINE}/:blockId`}
          element={
            <ProtectedRoutes>
              <MedicineDetail />
            </ProtectedRoutes>
          }
        />
        <Route
          path={ROUTES_NAME.ASSIGN_SPECIFIC_TO_SCHEME}
          element={
            <ProtectedRoutes>
              <AssignFormularyToScheme />
            </ProtectedRoutes>
          }
        />
        <Route path="*" element={<>Page not found</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
