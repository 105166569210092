import { ReducersModal } from '../../modal';
import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import { AppDispatch, AppDispatchFunction } from '../../store/store';
import {
  AnswerPointListApiResponse,
  QuestionDetail,
  QuestionDetailPayloadRedux,
  QuestionListApiResponse,
  QuestionListPayload,
  QuestionListPayloadRedux,
  UpdateStatusAnswerPayload,
  UpdateStatusQuestionPayload
} from '../../types/faq.types';
import { AxiosResponseGlobal } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';
import showAlert from '../../utils/alert';
import API from '../../utils/api';
import { checkOnline } from '../../utils/common.function';
import CONSTANTS from '../../utils/constants';
import END_POINTS from '../../utils/end.points';

export const csvQuestionList = ({
  page,
  limit,
  filterBy,
  filterValue,
  sortBy,
  sortOrder,
  categoryId,
  fromDate,
  toDate
}: QuestionListPayload) => {
  return new Promise<AxiosResponseGlobal<QuestionListApiResponse>>((resolve, reject) => {
    checkOnline();
    const params = `?Page=${page}&Limit=${limit}${filterBy ? `&FilterBy=${filterBy}` : ''}${
      filterValue ? `&FilterValue=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      categoryId ? `&CategoryId=${categoryId}` : ''
    }${fromDate ? `&FromDate=${fromDate}` : ''}${toDate ? `&ToDate=${toDate}` : ''}`;
    API.getApiCall(
      END_POINTS.QUESTION_LIST,
      params,
      (res: AxiosResponseGlobal<QuestionListApiResponse>) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  });
};

export const questionList = ({
  page,
  limit,
  filterBy,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive,
  categoryId,
  fromDate,
  toDate
}: QuestionListPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `?Page=${page}&Limit=${limit}${filterBy ? `&FilterBy=${filterBy}` : ''}${
      filterValue ? `&FilterValue=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      categoryId ? `&CategoryId=${categoryId}` : ''
    }${fromDate ? `&FromDate=${fromDate}` : ''}${toDate ? `&ToDate=${toDate}` : ''}`;

    API.getApiCall(
      END_POINTS.QUESTION_LIST,
      params,
      (res: AxiosResponseGlobal<QuestionListApiResponse>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(questionListAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

export const updateStatusQuestion = (
  payload: UpdateStatusQuestionPayload,
  handleCloseInactive: () => void,
  type: 'detail' | 'list'
) => {
  return (dispatch: AppDispatchFunction, getState: () => ReducersModal) => {
    const { Page, ItemOffset, SortBy, SortOrder } = getState().questionListReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    API.patchApiCall(
      END_POINTS.UPDATE_STATUS_QUESTION,
      params,
      (res: AxiosResponseGlobal<unknown>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          showAlert(1, data?.Message);
          if (type === 'list') {
            dispatch(
              questionList({
                page: Page,
                limit: ItemOffset,
                sortBy: SortBy,
                sortOrder: SortOrder,
                handleCloseInactive
              })
            );
          } else if (type === 'detail') {
            dispatch(questionDeatil(String(payload.QuestionId), handleCloseInactive));
          }
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

export const questionDeatil = (paramId: string, handleCloseInactive?: () => void) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `/${paramId}`;
    API.getApiCall(
      END_POINTS.QUESTION_DETAIL,
      params,
      (res: AxiosResponseGlobal<QuestionDetail>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(questionDetailAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

// faq detail point answers  api call
export const answerPointList = (page: number, limit: number, answerId: string) => {
  return new Promise<AxiosResponseGlobal<AnswerPointListApiResponse>>((resolve, reject) => {
    checkOnline();
    const params = `?Page=${page}&Limit=${limit}&AnswerId=${answerId}`;
    API.getApiCall(
      END_POINTS.ANSWER_POINT_LIST,
      params,
      (res: AxiosResponseGlobal<AnswerPointListApiResponse>) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  });
};

// faq detail point answers  api call
export const reportPointList = (page: number, limit: number, answerId: string, vlog?: boolean) => {
  return new Promise<AxiosResponseGlobal<AnswerPointListApiResponse>>((resolve, reject) => {
    checkOnline();
    const params = vlog
      ? `?Page=${page}&Limit=${limit}&RecommendationId=${answerId}`
      : `?Page=${page}&Limit=${limit}&AnswerId=${answerId}`;

    API.getApiCall(
      vlog ? END_POINTS.RECOMMENDATION_REPORT_LIST : END_POINTS.ANSWER_REPORT_LIST,
      params,
      (res: AxiosResponseGlobal<AnswerPointListApiResponse>) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  });
};

// update answer status
export const updateStatusAnswer = (
  payload: UpdateStatusAnswerPayload,
  paramId: string,
  handleCloseInactive: () => void
) => {
  return (dispatch: AppDispatchFunction) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    API.patchApiCall(
      END_POINTS.UPDATE_STATUS_ANSWER,
      params,
      (res: AxiosResponseGlobal<unknown>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(questionDeatil(paramId, handleCloseInactive));
          showAlert(1, data?.Message);
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

// action creater
export const questionListAction = (payload: QuestionListPayloadRedux) => {
  return {
    type: ACTION_NAME.QUESTION_LIST,
    payload
  };
};

export const questionDetailAction = (payload: QuestionDetailPayloadRedux) => {
  return {
    type: ACTION_NAME.QUESTION_DETAIL,
    payload
  };
};
