import { Box, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material';
import TableCellWrapper from '../../components/table/table.cell.wrapper';
import TableRowWrapper from '../../components/table/table.row.wrapper';
import LOCAL_IMAGES from '../../utils/local.images';
import DeleteButton from '../../components/button/delete.button';
import ConfirmButton from '../../components/button/confirm.button';
import { formatDateAndTime, trimString } from '../../utils/common.function';
import { RecommendationItem } from '../../types/doctor.recommendation.types';
import PaginateWrapper from '../../components/paginate-wrapper/paginate.wrapper';
import { ReducersModal } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import { getRcommondationListAction } from './action';
import { useNavigate } from 'react-router-dom';
import ROUTES_NAME from '../../utils/routes.name';
import TooltipWrapper from '../../components/tooltip-wrapper/tooltip.wrapper';
import useGlobalStyle from '../../hooks/use.global.style';

type DoctorRecommendationProps = {
  data: RecommendationItem[];
  handleOpenInactive: (item: RecommendationItem) => void;
  pageCount: number;
  rowPerPage: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  totalRecord: number;
  page: number;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
};

function DoctorRecommendation({
  data,
  handleOpenInactive,
  pageCount,
  handlePageClick,
  page,
  totalRecord,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage
}: DoctorRecommendationProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalClasses = useGlobalStyle();
  const { SortOrder } = useSelector((state: ReducersModal) => state.doctorRecommendationReducer);

  const handleAscAndDesc = () => {
    dispatch(
      getRcommondationListAction({
        ...(SortOrder === 'ASC' ? { SortOrder: 'DESC' } : { SortOrder: 'ASC' })
      })
    );
  };

  const navigateToDetail = (recommendationId: string | number) => {
    navigate(`${ROUTES_NAME.DOCTORS_RECOMMENDATION_DETAIL}/${recommendationId}`);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>Recommendation</TableCellWrapper>
                <TableCellWrapper>Doctor name</TableCellWrapper>
                <TableCellWrapper>Phone number</TableCellWrapper>
                <TableCellWrapper>Category</TableCellWrapper>
                <TableCellWrapper>
                  Report <br /> count
                </TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Created at
                    </Typography>
                    <Box sx={{ ml: 0.5, cursor: 'pointer' }} onClick={handleAscAndDesc}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: RecommendationItem, index: number) => {
                  return (
                    <TableRowWrapper
                      sx={{ cursor: 'pointer' }}
                      key={item?.RecommendationId}
                      hover
                      onClick={() => navigateToDetail(item.RecommendationId)}>
                      <TableCellWrapper>{index + page}</TableCellWrapper>
                      <TableCellWrapper>
                        {item?.Text ? (
                          <>
                            <TooltipWrapper title={item?.Text} placement="right" arrow>
                              <span className={globalClasses.span}>{trimString(item?.Text, 15)}</span>
                            </TooltipWrapper>
                          </>
                        ) : (
                          'N/A'
                        )}
                      </TableCellWrapper>
                      <TableCellWrapper>{item.DoctorName ?? 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        {`+${item.DoctorPhoneCode ? item.DoctorPhoneCode : ''}${
                          item.DoctorPhoneNumber ? item.DoctorPhoneNumber : ''
                        }` ?? 'N/A'}
                      </TableCellWrapper>
                      <TableCellWrapper>{item?.CategoryName ?? 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.ReportCount ?? 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item?.CreatedAt) ?? 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        {item.IsActive === 1 && (
                          <DeleteButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenInactive(item);
                            }}
                          />
                        )}
                        {item.IsActive === 0 && (
                          <ConfirmButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenInactive(item);
                            }}
                          />
                        )}
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={8}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            activePage={activePage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={page}
            rowpPerPage={rowPerPage}
            totalRecord={totalRecord}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            // isShowPreAndNext
          />
        )}
      </Box>
    </>
  );
}

export default DoctorRecommendation;
