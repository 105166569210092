import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import showAlert from '../../utils/alert';
import API from '../../utils/api';
import END_POINTS from '../../utils/end.points';
import CONSTANTS from '../../utils/constants';
import ACTION_NAME from '../../utils/action.name';
import { checkOnline } from '../../utils/common.function';
import {
  AddCategoryPayload,
  CategoriesType,
  CategoriesTypeApiResponse,
  UpdateCategoryPayload,
  UpdateStatusCategory
} from '../../types/manage.category.types';
import { AxiosResponse } from 'axios';
import { GlobalGetApiResponse } from '../../types/global.types';
import { AppDispatch, AppDispatchFunction } from '../../store/store';

export const getManageCategory = () => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = '';
    API.getApiCall(
      END_POINTS.ADMIN_CATEGORY,
      params,
      (res: AxiosResponse<GlobalGetApiResponse<CategoriesTypeApiResponse>>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(getManageCategoryAction(data?.Data));
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponse<GlobalGetApiResponse<unknown>>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

export const addCategory = (payload: AddCategoryPayload) => {
  return new Promise<
    AxiosResponse<GlobalGetApiResponse<CategoriesType>> | AxiosResponse<GlobalGetApiResponse<unknown>>
  >((resolve, reject) => {
    checkOnline();
    const params = payload;
    API.postApiCall(
      END_POINTS.ADMIN_CATEGORY,
      params,
      (res: AxiosResponse<GlobalGetApiResponse<CategoriesType>>) => {
        resolve(res);
      },
      (err: AxiosResponse<GlobalGetApiResponse<unknown>>) => {
        reject(err);
      }
    );
  });
};

export const updateCategory = (payload: UpdateCategoryPayload) => {
  return new Promise<
    AxiosResponse<GlobalGetApiResponse<CategoriesType>> | AxiosResponse<GlobalGetApiResponse<unknown>>
  >((resolve, reject) => {
    checkOnline();
    const params = payload;
    API.patchApiCall(
      END_POINTS.UPDATE_ADMIN_CATEGORY,
      params,
      (res: AxiosResponse<GlobalGetApiResponse<CategoriesType>>) => {
        resolve(res);
      },
      (err: AxiosResponse<GlobalGetApiResponse<unknown>>) => {
        reject(err);
      }
    );
  });
};

export const updateStatusCategoryApi = (payload: UpdateStatusCategory, handleCloseInactive: () => void) => {
  return (dispatch: AppDispatchFunction) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    API.patchApiCall(
      END_POINTS.UPDATE_STATUS_ADMIN_CATEGORY,
      params,
      (res: AxiosResponse<GlobalGetApiResponse<unknown>>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(getManageCategory());
          showAlert(1, data?.Message);
          handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponse<GlobalGetApiResponse<unknown>>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

// action creater

const getManageCategoryAction = (payload: CategoriesTypeApiResponse) => {
  return {
    type: ACTION_NAME.GET_MANAGE_CATEGORY,
    payload
  };
};
