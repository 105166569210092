import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

function EditButton({ ...otherProps }: IconButtonProps) {
  return (
    <IconButton color="primary" sx={{ p: 0.25 }} {...otherProps}>
      <ModeEditOutlineOutlinedIcon />
    </IconButton>
  );
}

export default EditButton;
