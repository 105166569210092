import { Box, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material';
import TableCellWrapper from '../../components/table/table.cell.wrapper';
import TableRowWrapper from '../../components/table/table.row.wrapper';
import LOCAL_IMAGES from '../../utils/local.images';
import { Block } from '../../types/manage.icd10.diagnosis.block';
import PaginateWrapper from '../../components/paginate-wrapper/paginate.wrapper';
import { manageIcd10DiagnosisBlockListAction } from './action';
import { ReducersModal } from '../../modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES_NAME from '../../utils/routes.name';
import { formatDateAndTime, trimStringICD } from '../../utils/common.function';
import DeleteButton from '../../components/button/delete.button';
import ConfirmButton from '../../components/button/confirm.button';
import EditButton from '../../components/button/edit.button';
import CopyButton from '../../components/button/copy.button';
import TooltipWrapper from '../../components/tooltip-wrapper/tooltip.wrapper';
import useGlobalStyle from '../../hooks/use.global.style';
import { TableSort, TSort } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';
import showAlert from '../../utils/alert';

type Icd10DiagnosisTableProps = {
  data: Block[];
  handleOpenInactive: (item: Block) => void;
  pageCount: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  rowPerPage: number;
  totalRecord: number;
  page: number;
  handlePrevious?: () => void;
  handleNext?: () => void;
  activePage?: number;
  handleOpenEditBlock: (type: string, item: Block) => void;
  handleOpenCopyICD10Block: () => void;
};

function Icd10DiagnosisBlockTable({
  data,
  handleOpenInactive,
  pageCount,
  handlePageClick,
  totalRecord,
  page,
  rowPerPage,
  handlePrevious,
  handleNext,
  activePage,
  handleOpenEditBlock,
  handleOpenCopyICD10Block
}: Icd10DiagnosisTableProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalClasses = useGlobalStyle();
  const { SortOrder } = useSelector((state: ReducersModal) => state.manageIcd10DiagnosisBlocksReducer);

  const handleAscAndDesc = (SortBy: TSort) => {
    dispatch(
      manageIcd10DiagnosisBlockListAction({
        ...(SortOrder === 'ASC' ? { SortOrder: 'DESC' } : { SortOrder: 'ASC' }),
        ...{ SortBy: SortBy }
      })
    );
  };

  const navigateToDetail = (blockId: number) => {
    navigate(`${ROUTES_NAME.MANAGE_ICD10_DIAGNOSIS_DETAIL}/${blockId}`);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Block Name
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.BlockName);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Insurance Company</TableCellWrapper>
                <TableCellWrapper>ICD10 Code(s)</TableCellWrapper>
                <TableCellWrapper>No. of Medicines</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Created At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.CreatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Updated At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.UpdatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Status</TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: Block, index: number) => {
                  return (
                    <TableRowWrapper
                      hover
                      key={item?.BlockId}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigateToDetail(item.BlockId)}>
                      <TableCellWrapper>{index + page}</TableCellWrapper>
                      <TableCellWrapper>{item.Name || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.InsuranceCompanyName || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        {item?.Diagnosis ? (
                          <>
                            <TooltipWrapper
                              title={item?.Diagnosis.map((item) => item.DiagnosisCode).join(', ')}
                              placement="right"
                              arrow>
                              <span className={globalClasses.span}>
                                {trimStringICD(item?.Diagnosis.map((item) => item.DiagnosisCode).join(', '), 9)}
                              </span>
                            </TooltipWrapper>
                          </>
                        ) : (
                          'N/A'
                        )}
                      </TableCellWrapper>
                      <TableCellWrapper>{item.MedicineCount ?? 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item.CreatedAt) ?? 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        <>{item.ModifiedAt !== null ? formatDateAndTime(item.ModifiedAt) : 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>{item.IsActive === 1 ? 'Active' : 'In Active'}</TableCellWrapper>
                      <TableCellWrapper>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CopyButton
                            onClick={(e) => {
                              e.stopPropagation();
                              if(item.MedicineCount){
                                dispatch({
                                  type:ACTION_NAME.COPY_ICD10_BLOCK,
                                  payload:{
                                    FromBlock:item,
                                    FromBlockIds:[item.BlockId]
                                  }
                                })
                                handleOpenCopyICD10Block();
                              }
                              else showAlert(2, "None medicine are in this block")
                              
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>
                          <EditButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenEditBlock('edit', item);
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>

                          {item.IsActive === 1 && (
                            <DeleteButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                          {item.IsActive === 0 && (
                            <ConfirmButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                        </Box>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={9}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            activePage={activePage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={page}
            rowpPerPage={rowPerPage}
            totalRecord={totalRecord}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        )}
      </Box>
    </>
  );
}

export default Icd10DiagnosisBlockTable;
