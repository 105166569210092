// import CopySchemeSpecificFormulary from "../copy-scheme-specific-formulary/copy-scheme-specific-modal"
import { Avatar, Box, Button, Divider, Typography, Link as MLink } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useParams } from "react-router-dom"
import ButtonWrapper from "../../../../components/button/button.wrapper"
import ImportDialog from "../../../../components/modal-wrapper/import.dialog"
import InactiveModal from "../../../../components/modal-wrapper/inactive.modal"
import SearchTextfield from "../../../../components/search-textfield/search.textfield"
import useDebounce from "../../../../hooks/use.debounce"
import { ReducersModal } from "../../../../modal"
import { globalLoaderEnd, globalLoaderStart } from "../../../../reducer/global.reducer"
import { ISMedicine } from "../../../../types/manage.scheme.specific.formulary"
import showAlert from "../../../../utils/alert"
import { makeBreadcrumb } from "../../../../utils/breadcrumb"
import { calculatePage, calculateRowPerPage } from "../../../../utils/common.function"
import CONSTANTS from "../../../../utils/constants"
import { LOCAL_FILE } from "../../../../utils/local.file"
import LOCAL_IMAGES from "../../../../utils/local.images"
import ROUTES_NAME from "../../../../utils/routes.name"
import { icdDetail } from "../../../manage-icd10-diagnosis/action"
import IcdCode from "../../../manage-icd10-diagnosis/icd-detail/icd.code"
import { getSchemeIcdMedicineAction, getSchemeIcdMedicineList, importSchemeFromExcelApi, schemeDetail, updateSchemeIcdMedicine } from "../../action"
import AddMedicine from "../add.medicine"
import MedicineTable from "../medicine.table"

function MedicineDetail() {
  const dispatch = useDispatch()
  const [breadCrumb, setBreadCrumb] = useState<string[]>([])
  const [openImport, setOpenImport] = useState(false);
  const importRef = useRef<HTMLButtonElement>(null);
  const {pathname, state} = useLocation() as any
  const {schemeId, blockId}= useParams();
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue.trim(), 1000);
  const [openAddMedicine, setOpenAddMedicine] = useState(false);
  const [openInactive, setOpenInactive] = useState(false);
  const [updateStatusFaq, setUpdateStatusFaq] = useState({} as ISMedicine);
  const { Diagnosis } = useSelector((state: ReducersModal) => state.icdDetailReducer);
  const {
    Name,
    InsuranceCompanyName,
    InsuranceCompanyLogoURL,
    FormularyId
    } = useSelector((state: ReducersModal) => state.schemeDetailReducer);

    const { Page, ItemOffset, SortBy, SortOrder, Medicines, TotalRecords } = useSelector(
      (state: ReducersModal) => state.schemeIcdMedicineReducer
    );
    const [pageCount, setPageCount] = useState(Page);

    useEffect(() => {
      setPageCount(Math.ceil(TotalRecords / ItemOffset));
    }, [ItemOffset, TotalRecords]);
    
    useEffect(() => {
      if (schemeId) dispatch(schemeDetail(schemeId));
      }, [schemeId]);

    useEffect(() => {
      if (blockId) dispatch(icdDetail(blockId));
    }, [blockId]);  

    useEffect(() => {
      dispatch(
        getSchemeIcdMedicineList({
          blockId:Number(blockId),
          formularyId: FormularyId,
          page: Page,
          limit: ItemOffset,
          ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
          ...(debouncedValue !== '' && { filterValue: debouncedValue })
        })
      );
    }, [FormularyId, Page, ItemOffset, SortBy, SortOrder, debouncedValue]);

  useEffect(()=>{
    // eslint-disable-next-line no-console
    setBreadCrumb(makeBreadcrumb(pathname, Name, state?.blockName))
  },[pathname, Name])

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
  };
  const handleOpenAddMedicine = () => {
    setOpenAddMedicine(true);
  };

  const handleCloseAddMedicine = () => {
    setOpenAddMedicine(false);
  };
  function importButton(heading: string, type?: 'replace') {
    return (
      <Button
        sx={{
          font: (theme) => `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.5)} Roboto Sans, sans-serif`,
          textTransform: 'inherit'
        }}
        component="label"
        startIcon={
          <img src={type === 'replace' ? LOCAL_IMAGES.REPLACE_IMPORT : LOCAL_IMAGES.ADD_IMPORT} alt="add import" />
        }>
        {heading}
        <input
          onChange={(e) => handleImportChange(e, type)}
          type="file"
          hidden
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </Button>
    );
  }
  const handleImportChange = async (event: React.ChangeEvent<HTMLInputElement>, type?: 'replace') => {
    if (!event.target.files?.length || !FormularyId) return;
    const fileObj = event.target.files[0];
    const data = new FormData();
    data.append('BlockId', `${blockId}`)
    data.append('FormularyId', `${FormularyId}`);
    data.append('IsReplaceExisting', `${type === 'replace' ? true : false}`);
    data.append('File', fileObj);
    try {
      dispatch(globalLoaderStart());
      const res = await importSchemeFromExcelApi(data, 'medicine');
      dispatch(schemeDetail(String(FormularyId)));
      dispatch(
        getSchemeIcdMedicineList({
          formularyId: FormularyId,
          blockId:Number(blockId),
          page: Page,
          limit: ItemOffset,
          ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
          ...(debouncedValue !== '' && { filterValue: debouncedValue })
        })
      );
      showAlert(
        1,
        <>
          <Typography variant="h1">Import is progress</Typography>
          <Typography variant="subtitle2">
            Total records found in the file: {res.data?.Data?.TotalRecords} Total records processed:{' '}
            {res.data?.Data?.RecordProcessed}
          </Typography>
        </>
      );
      handleCloseImport();
      dispatch(globalLoaderEnd());
    } catch (_err) {
      handleCloseImport();
      dispatch(globalLoaderEnd());
      showAlert(2);
    }
  };
  const handleOpenInactive = (item: ISMedicine) => {
    setOpenInactive(true);
    setUpdateStatusFaq(item);
  };
  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(getSchemeIcdMedicineAction({ Page: selectedPage + 1 }));
  };
  const handlePrevious = () => {
    dispatch(getSchemeIcdMedicineAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(getSchemeIcdMedicineAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };
  const handleCloseInactive = useCallback(() => {
    setOpenInactive(false);
  }, []);
  
  const handleConfirmInactive = useCallback(() => {
    const { FormularyId, MedicineId } = updateStatusFaq;
    updateSchemeIcdMedicine({ formulary: FormularyId, medicineId: MedicineId, blockId: Number(blockId) })
      .then(({ status, data }) => {
        if (status === 200 && FormularyId) {
          showAlert(1, data?.Message);
          batch(() => {
            dispatch(
              getSchemeIcdMedicineList({
                blockId: Number(blockId),
                formularyId: FormularyId,
                page: Page,
                limit: ItemOffset,
                ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
                ...(debouncedValue !== '' && { filterValue: debouncedValue }),
                handleCloseInactive: handleCloseInactive
              })
            );
            dispatch(schemeDetail(String(FormularyId)));
          });
        }
      })
      .catch(() => {
        showAlert(2);
        handleCloseInactive();
      });
  }, [updateStatusFaq]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };
  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };
  return (
    <>
        <Typography variant="h1">
          <Link to={ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY} style={{color:"#308E99"}}>
           {breadCrumb[0]}{' > '}
          </Link>
          
          <Link to={`${ROUTES_NAME.MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL}/${schemeId}`} style={{color:"#308E99"}}>
          {breadCrumb[1]}
          </Link>
          {' > '}
          <Link to="" style={{fontWeight:"bold", color:"black"}}>
          {breadCrumb[2]}
          </Link>
        </Typography>
        <Box sx={{ borderRadius: 1.5, px: 2, py: 2, my: 2, bgcolor:"common.white" }}>
        <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{color: 'primary.main'}}>
            {Name}
          </Typography>
          <Box sx={{display:"flex", alignItems: 'center'}}>
        <Box
          sx={{
            width: 33 ,
            height: 33,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: (theme) => theme.shadows[3],
            mr: 1
          }}>
          <Avatar
              sx={{
                bgcolor: (theme) => theme.palette.secondary.light
              }}>
              <img src={CONSTANTS.IMAGE_BASE_URL + InsuranceCompanyLogoURL} alt="" width="40" height="40" />
            </Avatar>
          
       </Box>
        <Typography variant="h1" sx={{textDecoration:"underline", fontWeight:"700"}}>
          {InsuranceCompanyName}
        </Typography>
      </Box>
        </Box>
        </Box>
        <IcdCode data={Diagnosis} />
        <Box sx={{ display: 'flex', justifyContent:"space-between", alignItems:"center", py:2}}>
          <Typography variant="h2" sx={{ color: 'primary.main', mb: 1 }}>
          {state?.blockName}
          </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              <Box>
                <ButtonWrapper
                  onClick={handleOpenAddMedicine}
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: 'none', mr: 1 }}>
                  Add Medicine(s)
                </ButtonWrapper>
              </Box>
              <ButtonWrapper
                ref={importRef}
                variant="contained"
                color="primary"
                onClick={handleOpenImport}
                sx={{ textTransform: 'none', mr: 1 }}>
                  Import Medicines
              </ButtonWrapper>
              
            </Box>
            
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
          <Box sx={{py:1, flexBasis:"70%"}}>
            <SearchTextfield
                placeholder={'Search by Drug Id, Product trde, API'}
                inputValue={inputValue}
                handleSearchChange={handleSearchChange}
                handleSearchSubmit={handleSearchSubmit}
                name="searchInput"
              />
          </Box> 
          <Box sx={{ alignItems:"center", cursor: 'pointer' }}>
            <MLink href={LOCAL_FILE.FormularyDrugs} download>
              Download sample file
            </MLink>
          </Box> 
        </Box>
        <MedicineTable
          data={Medicines}
          handleOpenInactive={handleOpenInactive}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          page={calculatePage(Page, ItemOffset)}
          totalRecord={TotalRecords}
          rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          activePage={Page}
        />
        <ImportDialog
        open={openImport}
        handleClose={handleCloseImport}
        filterButtonPosition={importRef.current?.getBoundingClientRect()}>
        <Box sx={{ mb: 0.5 }}>{importButton('Replace existing list', 'replace')}</Box>
        <Divider />
        <Box sx={{ mt: 0.5 }}>{importButton('Add/Update to existing list')}</Box>
        </ImportDialog>
        <AddMedicine open={openAddMedicine} handleClose={handleCloseAddMedicine} />
        <InactiveModal
          open={openInactive}
          handleClose={handleCloseInactive}
          heading={updateStatusFaq.IsActive === 0 ? 'Activate' : 'Delete'}
          paragraph={
            <>
              Are you sure you want to {updateStatusFaq.IsActive === 0 ? 'activate' : 'delete'}
              <br /> the selected Medicine?
            </>
          }
          handleConfirm={handleConfirmInactive}
        />
        {/* <CopySchemeSpecificFormulary/> */}
    </>    
  )
}

export default MedicineDetail