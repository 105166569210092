import { Box, CssBaseline } from '@mui/material';
import ToasterContainer from './components/toaster-container/toaster.container';
import Router from './router/router';

function App() {
  return (
    <Box>
      <CssBaseline />
      <ToasterContainer />
      <Router />
    </Box>
  );
}

export default App;
