import { globalLoaderEnd, globalLoaderStart } from '../../reducer/global.reducer';
import showAlert from '../../utils/alert';
import API from '../../utils/api';
import END_POINTS from '../../utils/end.points';
import CONSTANTS from '../../utils/constants';
import ACTION_NAME from '../../utils/action.name';
import { checkOnline } from '../../utils/common.function';
import {
  DoctorRecommendationPayload,
  DoctorRecommendationApiResponse,
  UpdateStatusRecommendationPayload,
  RecommendationItem,
  RecommendationDeatilPayloadRedux,
  GetDoctorRecommendationListPayload
} from '../../types/doctor.recommendation.types';
import { AxiosResponseGlobal } from '../../types/global.types';
import { ReducersModal } from '../../modal';
import { AppDispatch, AppDispatchFunction } from '../../store/store';

export const csvVlogList = ({
  page,
  limit,
  filterBy,
  filterValue,
  sortBy,
  sortOrder,
  categoryId,
  fromDate,
  toDate
}: GetDoctorRecommendationListPayload) => {
  return new Promise<AxiosResponseGlobal<DoctorRecommendationApiResponse>>((resolve, reject) => {
    checkOnline();
    const params = `?RecommendationType=2&Page=${page}&Limit=${limit}${filterBy ? `&FilterBy=${filterBy}` : ''}${
      filterValue ? `&FilterValue=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      categoryId ? `&CategoryId=${categoryId}` : ''
    }${fromDate ? `&FromDate=${fromDate}` : ''}${toDate ? `&ToDate=${toDate}` : ''}`;

    API.getApiCall(
      END_POINTS.ADMIN_RCOMMENDATION_LIST,
      params,
      (res: AxiosResponseGlobal<DoctorRecommendationApiResponse>) => {
        const { status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          resolve(res);
        }
      },
      (err: AxiosResponseGlobal<unknown>) => {
        reject(err);
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  });
};

export const getVlogList = ({
  page,
  limit,
  filterBy,
  filterValue,
  sortBy,
  sortOrder,
  handleCloseInactive,
  categoryId,
  fromDate,
  toDate
}: GetDoctorRecommendationListPayload) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `?RecommendationType=2&Page=${page}&Limit=${limit}${filterBy ? `&FilterBy=${filterBy}` : ''}${
      filterValue ? `&FilterValue=${filterValue}` : ''
    }${sortBy ? `&SortBy=${sortBy}` : ''}${sortOrder ? `&SortOrder=${sortOrder}` : ''}${
      categoryId ? `&CategoryId=${categoryId}` : ''
    }${fromDate ? `&FromDate=${fromDate}` : ''}${toDate ? `&ToDate=${toDate}` : ''}`;

    API.getApiCall(
      END_POINTS.ADMIN_RCOMMENDATION_LIST,
      params,
      (res: AxiosResponseGlobal<DoctorRecommendationApiResponse>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(getVlogListAction(data?.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

export const updateStatusVlog = (
  payload: UpdateStatusRecommendationPayload,
  handleCloseInactive: () => void,
  type: 'detail' | 'list'
) => {
  return (dispatch: AppDispatchFunction, getState: () => ReducersModal) => {
    const { Page, ItemOffset, SortBy, SortOrder } = getState().doctorVlogReducer;
    checkOnline();
    dispatch(globalLoaderStart());
    const params = payload;
    API.patchApiCall(
      END_POINTS.UPDATE_STATUS_RECOMMENDATION,
      params,
      (res: AxiosResponseGlobal<unknown>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          showAlert(1, data?.Message);
          if (type === 'list') {
            dispatch(
              getVlogList({
                page: Page,
                limit: ItemOffset,
                sortBy: SortBy,
                sortOrder: SortOrder,
                handleCloseInactive
              })
            );
          } else if (type === 'detail') {
            dispatch(vlogDeatil(String(payload.RecommendationId), handleCloseInactive));
          }
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

export const vlogDeatil = (paramId: string, handleCloseInactive?: () => void) => {
  return (dispatch: AppDispatch) => {
    checkOnline();
    dispatch(globalLoaderStart());
    const params = `/${paramId}`;
    API.getApiCall(
      END_POINTS.RECOMMENDATION_DETAIL,
      params,
      (res: AxiosResponseGlobal<RecommendationItem>) => {
        const { data, status } = res;
        if (status === CONSTANTS.API_SUCCESS_CODE.SUCCESS) {
          dispatch(vlogDetailAction(data.Data));
          if (handleCloseInactive) handleCloseInactive();
        }
        dispatch(globalLoaderEnd());
      },
      (err: AxiosResponseGlobal<unknown>) => {
        dispatch(globalLoaderEnd());
        const { data } = err;
        showAlert(2, data?.Message);
      }
    );
  };
};

export const getVlogListAction = (payload: DoctorRecommendationPayload) => {
  return {
    type: ACTION_NAME.GET_DOCTOR_VLOG_LIST,
    payload
  };
};

export const vlogDetailAction = (payload: RecommendationDeatilPayloadRedux) => {
  return {
    type: ACTION_NAME.VLOG_DETAIL,
    payload
  };
};
