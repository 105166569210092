import { Box, Dialog, Typography } from "@mui/material";
import {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonWrapper from "../../components/button/button.wrapper";
import ChipCus from "../../components/chip/chip.cus";
import InputField from "../../components/form-ui/textfield-wrapper/input.field";
import { ReducersModal } from "../../modal";
import { Block } from "../../types/manage.icd10.diagnosis.block";
import ACTION_NAME from "../../utils/action.name";
import showAlert from "../../utils/alert";
import { VIEW_NUMBER } from "../../utils/static.data";
import { copyICD10Block, getIcd10DiagnosisBlocksList } from "./action";
import AutocomIcdBlock from "./autocom.icd.block";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TIndex } from "./copy.block";
import SchemeViewAll from "../scheme-specific-formulary/scheme.view.all";

type CopyIcd10BlockProps={
    open:boolean;
    handleClose:()=>void;
}
function CopyIcd10Block({open, handleClose}:CopyIcd10BlockProps) {
    const dispatch = useDispatch()
    const [indexValue, setIndexValue] = useState(0);
    const {FromBlock, ToBlock, FromBlockIds} = useSelector((state:ReducersModal)=> state.copyICD10BlockReducer)
    const {
        Page,
        ItemOffset,
        } = useSelector((state: ReducersModal) => state.manageIcd10DiagnosisBlocksReducer);
    const deleteICD10Block=(block:Block)=>{
    const toBlocks = ToBlock.filter((item)=> item.BlockId !== block.BlockId)
    const fromBlockIds = FromBlockIds.filter((item) => item !== block.BlockId)
    dispatch({
        type:ACTION_NAME.COPY_ICD10_BLOCK,
        payload:{
            FromBlockIds:fromBlockIds,
            ToBlock:toBlocks
        }
    })
   }
   const onProceed=()=>{
    if(!ToBlock.length)
    {
        showAlert(2,"Please select atleast on Block.")
        return false
    }
    const payload={
        BlockId:Number(FromBlock?.BlockId),
        Blocks:ToBlock.map((block)=> {return block.BlockId})
    }
    // eslint-disable-next-line no-console
    console.log(payload)
    copyICD10Block(payload)
        .then(()=>{
            showAlert(1, "Success")
            dispatch(
                getIcd10DiagnosisBlocksList({
                  page: Page,
                  limit: ItemOffset,
                  })
              );

            handleClose()
        })
        .catch(()=> {showAlert(2), handleClose()})
   }
   const handleChangeIndex = (index: TIndex) => {
    setIndexValue(index);
  };
//   const blockFilter = (block: Block[], keyword: string) => {
//     return block.filter(
//       (item) =>
//         item.BlockId === keyword.toLowerCase().trim())
//     );
//   };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth maxWidth="md"
      scroll={'paper'}>
        {indexValue === 0 && <>
        <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
            <Typography variant="h1" sx={{ color: 'common.white' }}>
            Copy ICD10 Code Block
            </Typography>
        </Box>
        <Box sx={{p: 2}}>
            <InputField
                disabled={true}
                label={"From Block"}
                name="Block Name"
                value={`${FromBlock?.Name}-${FromBlock?.InsuranceCompanyName}`}
                size={"small"}
                fullWidth
            />
        </Box>   
        <Box sx={{p: 2}}>
            <AutocomIcdBlock label="Search and select ICD10 code blocks"/>
            {ToBlock?.length > 0 && (
                <Box>
                {ToBlock.map((item, index) => {
                    if (index > VIEW_NUMBER) return null;
                    return (
                    <ChipCus
                        sx={{ mt: 0.8, mr: 0.5 }}
                        key={item.Name}
                        label={`${item.Name}-${item.InsuranceCompanyName}`}
                        color="primary"
                        onDelete={()=>{deleteICD10Block(item)}}
                    />
                    );
                })}
                </Box>
            )}
            {ToBlock.length> VIEW_NUMBER && <ButtonWrapper
            onClick={() => handleChangeIndex(1)}
            endIcon={<ArrowForwardIosIcon />}
            variant="contained"
            color="primary"
            sx={{mt:2}}
            >
            View All
          </ButtonWrapper>}
        </Box>
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 2,
                py:"10px"
                }}>
            <ButtonWrapper
                variant="outlined"
                sx={{
                color: (theme) => theme.palette.error.light,
                px: { xs: 2, sm: 5 },
                mr: 1,
                py: 1,
                borderColor: (theme) => theme.palette.error.light,
                '&:hover': {
                    borderColor: (theme) => theme.palette.error.light
                }
                }}
                onClick={handleClose}>
                Cancel
            </ButtonWrapper>
            <ButtonWrapper
                variant="contained"
                color="primary"
                sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
                onClick={onProceed}>
                Proceed
            </ButtonWrapper>
        </Box></>}
        {indexValue === 1 &&  (
          <>
            <SchemeViewAll
              type="block"
              index={indexValue}
              heading="All Blocks"
              handleChangeIndex={handleChangeIndex}
              bradcrumb={{
                link: 'Medicines'
              }}
              placeholder="Search Block"
              inputValue={""}
              handleSearchChange={()=>{return false}}
              handleSearchSubmit={()=>{return false}}
              handleDeleteBlock={deleteICD10Block}
              blocks={ToBlock}
            />
          </>
        )}
      </Dialog>)
      }
  

export default CopyIcd10Block