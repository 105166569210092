const LOGO = '/images/logo.svg';
const FAQ = '/images/faq.svg';
const FAQ_ACTIVE = '/images/faq-active.svg';
const DOCTORS_VLOG = '/images/doctor-vlog.svg';
const DOCTORS_VLOG_ACTIVE = '/images/doctor-vlog-active.svg';
const DOCTOR_RECOMMENDATION = '/images/doctor-recommendation.svg';
const DOCTOR_RECOMMENDATION_ACTIVE = '/images/doctor-recommendation-active.svg';
const MANAGE_CATEGORY = '/images/manage-category.svg';
const MANAGE_CATEGORY_ACTIVE = '/images/manage-category-active.svg';
const SUCCESSFULLY = '/images/successfully.svg';
const INACTIVE = '/images/inactive.svg';
const MANAGE_LIVIA_POINST = '/images/ic_unselect_livapoint.svg';
const MANAGE_LIVIA_POINST_ACTIVE = '/images/ic_select_livapoint.svg';
const MANAGE_ICD10_DIAGNOSIS = '/images/ic_unselect_icd.svg';
const MANAGE_ICD10_DIAGNOSIS_ACTIVE = '/images/ic_select_icd.svg';
const MANAGE_SCHEME= '/images/ic_unselect_scheme.svg';
const MANAGE_SCHEME_ACTIVE= '/images/ic_select_scheme.svg';
const ASSIGN_SCHEME='/images/ic_unselect_assign.svg';
const ASSIGN_SCHEME_ACTIVE='/images/ic_select_assign.svg';

// ?OLD
const BEll = '/images/bell.svg';
const SIDEBAR_ICON = '/images/sidebar-icon.svg';
const SIDEBAR_WHITE_ICON = '/images/sidebar-white-icon.svg';
const PROFILE_ICON = '/images/profile-icon.svg';
const FILTER = '/images/filter.svg';
const UP_DOWN_FILTER = '/images/up-down-filter.svg';
const REPLACE_IMPORT = '/images/ic_replace.svg';
const ADD_IMPORT = '/images/ic_add.svg';

const LOCAL_IMAGES = {
  LOGO,
  FAQ,
  FAQ_ACTIVE,
  DOCTORS_VLOG,
  DOCTORS_VLOG_ACTIVE,
  DOCTOR_RECOMMENDATION,
  DOCTOR_RECOMMENDATION_ACTIVE,
  MANAGE_CATEGORY,
  MANAGE_CATEGORY_ACTIVE,
  SUCCESSFULLY,
  INACTIVE,
  MANAGE_LIVIA_POINST,
  MANAGE_LIVIA_POINST_ACTIVE,
  MANAGE_ICD10_DIAGNOSIS,
  MANAGE_ICD10_DIAGNOSIS_ACTIVE,
  MANAGE_SCHEME,
  MANAGE_SCHEME_ACTIVE,
  ASSIGN_SCHEME,
  ASSIGN_SCHEME_ACTIVE,

  // *OLD
  BEll,
  SIDEBAR_ICON,
  SIDEBAR_WHITE_ICON,
  PROFILE_ICON,
  FILTER,
  UP_DOWN_FILTER,
  REPLACE_IMPORT,
  ADD_IMPORT
};

export default LOCAL_IMAGES;
