import { Box, TableContainer, Table, TableHead, TableBody, Typography } from '@mui/material';
import TableCellWrapper from '../../components/table/table.cell.wrapper';
import TableRowWrapper from '../../components/table/table.row.wrapper';
// import LOCAL_IMAGES from "../../utils/local-images";
import EditButton from '../../components/button/edit.button';
import DeleteButton from '../../components/button/delete.button';
import PaginateWrapper from '../../components/paginate-wrapper/paginate.wrapper';
import { formatDateAndTime } from '../../utils/common.function';
import { CategoriesType } from '../../types/manage.category.types';
import ConfirmButton from '../../components/button/confirm.button';

type CategoryTableProps = {
  data: CategoriesType[];
  handleOpenEditCategory: (type: string, item: CategoriesType) => void;
  handleOpenInactive: (item: CategoriesType) => void;
  pageCount: number;
  itemOffset: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  totalRecord: number;
  page: number;
};

function CategoryTable({
  data,
  handleOpenEditCategory,
  handleOpenInactive,
  pageCount,
  itemOffset,
  handlePageClick,
  totalRecord,
  page
}: CategoryTableProps) {
  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>Category</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Added on
                    </Typography>
                    {/* <Box sx={{ ml: 0.5, cursor: "pointer" }}>
                    <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                  </Box> */}
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: CategoriesType, index: number) => {
                  return (
                    <TableRowWrapper key={item?.CategoryId}>
                      <TableCellWrapper>{index + 1 + itemOffset}</TableCellWrapper>
                      <TableCellWrapper>{item?.Name || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item?.CreatedAt) || 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <EditButton onClick={() => handleOpenEditCategory('edit', item)} />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>
                          {item.IsActive === true ? (
                            <DeleteButton onClick={() => handleOpenInactive(item)} />
                          ) : (
                            <ConfirmButton onClick={() => handleOpenInactive(item)} />
                          )}
                        </Box>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={5}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ my: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={itemOffset + 1}
            rowpPerPage={totalRecord >= page + itemOffset ? page + itemOffset : (totalRecord % itemOffset) + itemOffset}
            totalRecord={totalRecord}
          />
        )}
      </Box>
    </>
  );
}

export default CategoryTable;
