import { Box, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import ReportDetailTable from '../../../components/report-detail-table/report.detail.table';
import { FaqReportList as FaqReportLists } from '../../../types/faq.types';
import { calculatePage, calculateRowPerPage } from '../../../utils/common.function';
import { reportPointList } from '../action';

type FaqReportListProps = {
  answerId: number;
  vlog?: boolean;
};

function FaqReportList({ answerId, vlog }: FaqReportListProps) {
  const [faqReportList, setReportList] = useState({
    TotalRecords: 0,
    Page: 1,
    Reports: [],
    ItemOffset: 5
  } as FaqReportLists);
  const { Page, ItemOffset, Reports, TotalRecords } = faqReportList;
  const [pageCount, setPageCount] = useState(Page);

  useEffect(() => {
    const getAnswerPointList = async () => {
      const res = await reportPointList(Page, ItemOffset, String(answerId), vlog);
      const answerList = res.data?.Data;
      setReportList((preValue) => {
        return {
          ...preValue,
          ...answerList
        };
      });
    };

    if (answerId) {
      getAnswerPointList();
    }
  }, [answerId, Page, ItemOffset]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    setReportList({
      ...faqReportList,
      Page: selectedPage + 1
    });
  };

  const handlePrevious = () => {
    setReportList({
      ...faqReportList,
      Page: 1
    });
  };
  const handleNext = () => {
    setReportList({
      ...faqReportList,
      Page: pageCount
    });
  };

  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    if (itemOffset) {
      setReportList({
        ...faqReportList,
        Page: 1,
        ItemOffset: Number(itemOffset)
      });
    }
  };

  return (
    <>
      {Reports?.length > 0 ? (
        <Box>
          <ReportDetailTable
            title="Report List"
            data={Reports}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            page={calculatePage(Page, ItemOffset)}
            totalRecord={TotalRecords}
            rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            activePage={Page}
            handleChangeShowSort={handleChangeShowSort}
            itemOffset={ItemOffset}
            type="answersReport"
          />
        </Box>
      ) : null}
    </>
  );
}

export default FaqReportList;
