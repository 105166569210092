import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import { startTransition, useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import ButtonWrapper from "../../../components/button/button.wrapper";
import ChipCus from "../../../components/chip/chip.cus";
import ImportDialog from "../../../components/modal-wrapper/import.dialog";
import { ReducersModal } from "../../../modal";
import { globalLoaderEnd, globalLoaderStart } from "../../../reducer/global.reducer";
import ACTION_NAME from "../../../utils/action.name";
import showAlert from "../../../utils/alert";
import { fixedEncodeURIComponent } from "../../../utils/common.function";
import LOCAL_IMAGES from "../../../utils/local.images";
import { addScheme, deleteScheme, getAssignFormularySchemeList, getAssignFormularytoSchemeList, importSchemeFromExcelApi } from "../action";
import AutocomScheme, { Scheme } from "../scheme-detail/autocom.scheme";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TIndex } from "../../manage-icd10-diagnosis/copy.block";
import { VIEW_NUMBER } from "../../../utils/static.data";
import SchemeViewAll from "../scheme.view.all";
import AutocomAssignFormularySearch from "../autocom.assign.formulary.search";
type AssignFormularySchemeDialogProp={
    open:boolean;
    handleClose:()=>void;
    
}

function AssignFormularySchemeDialog({open, handleClose}:AssignFormularySchemeDialogProp) {
  const dispatch = useDispatch();
  const importRef = useRef<HTMLButtonElement>(null);
  const { FormularyId, Scheme, isEdit, ImportFile, Type, InsuranceCompanyId, SchemeFromAPI } = useSelector((state: ReducersModal) => state.assignFormularytoSchemeReducer);
  const {
    Page,
    ItemOffset,
    } = useSelector((state: ReducersModal) => state.manageAssignFormularytoSchemeReducer);
  const [openImport, setOpenImport] = useState(false);
  const [value, setValue] = useState<TIndex>(0);
  const [schemeToShow, setSchemeToShow] = useState<Scheme>([])
  const [schemeInputValue, setSchemeInputValue] = useState('');
  const handleDeleteScheme = async(sch: { Name: string }) => {
    if(!isEdit){
      const filterSch = Scheme.filter((item) => item.Name !== sch.Name);
      // setScheme(filterSch);
      dispatch({
        type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
        payload: {
          Scheme:filterSch
        }
      });
    }
    else{
      try {
        dispatch(globalLoaderStart());
        await deleteScheme(Number(FormularyId), fixedEncodeURIComponent(sch.Name));
        batch(() => {
          dispatch(
            getAssignFormularySchemeList({
              page: Page,
              limit: ItemOffset,
              formularyId: Number(FormularyId),
              })
          );
        });
        dispatch(
          getAssignFormularytoSchemeList({
            page: Page,
            limit: ItemOffset,
            })
        );
        if (schemeInputValue) {
          const filterScheme10Arr = schemeToShow.filter(
            (item) =>
              !item.Name.toLowerCase().includes(sch.Name.toLowerCase().trim())
          );
          setSchemeToShow(filterScheme10Arr);
        }
        if(Scheme.length === 1){
          handleClose()
        }
      } catch (err) {
        showAlert(2);
        dispatch(globalLoaderEnd());
      }
      
    }
    
  };
  const validateSchemeFn = (value: Scheme): boolean => {
    if (value.length > 0) return false;
    else return true;
  };
  const handleAddScheme = async () => {
    if (!FormularyId) {
      showAlert(2, 'Please select formulary');
      return;
    }
    if(ImportFile){
      const data = new FormData();
      data.append('FormularyId', `${FormularyId}`);
      data.append('InsuranceCompanyId', `${InsuranceCompanyId}`)
      data.append('IsReplaceExisting', `${Type === 'replace' ? true : false}`);
      data.append('File', ImportFile);
      // eslint-disable-next-line no-console
      console.log(data)
      // const data = {
      //   FormularyId:FormularyId,
      //   IsReplaceExisting:Type === 'replace' ? true : false,
      //   File:ImportFile,
      // }
      try {
      dispatch(globalLoaderStart());
      const res = await importSchemeFromExcelApi(data, 'scheme');
      dispatch(
        getAssignFormularytoSchemeList({
          page: Page,
          limit: ItemOffset,
          })
      );
      showAlert(
        1,
        <>
          <Typography variant="h1">Import is progress</Typography>
          <Typography variant="subtitle2">
            Total records found in the file: {res.data?.Data?.TotalRecords} Total records processed:{' '}
            {res.data?.Data?.RecordProcessed}
          </Typography>
        </>
      );
      // handleCloseImport();
      handleClose()
      dispatch(globalLoaderEnd());
      } catch (_err) {
        // handleCloseImport();
        handleClose();
        dispatch(globalLoaderEnd());
        showAlert(2);
      }
     
    }
    else{
      const validateScheme = validateSchemeFn(Scheme);
      if (validateScheme) {
        showAlert(2, 'Please select minimum one Scheme');
        return;
      }
      const payload = {
        FormularyId,
        Schemes: Scheme.map((item) => item.Name)
      };
      try {
        dispatch(globalLoaderStart());
        await addScheme(payload);
        dispatch(
          getAssignFormularytoSchemeList({
            page: Page,
            limit: ItemOffset,
            })
        );
        // setScheme([])
        handleClose();
      } catch (_err) {
        showAlert(2);
        dispatch(globalLoaderEnd());
      }
      }
    
  };
  const handleOpenImport = () => {
    if(!FormularyId){
      showAlert(2,"Please select formulary")
      return false
    }
    setOpenImport(true);
    
    
  };
  const handleCloseImport = () => {
    setOpenImport(false);
  };
  
  
  useEffect(()=>{
    if(isEdit)dispatch(getAssignFormularySchemeList({page:Page, limit:10000, formularyId:Number(FormularyId), type:undefined}))
  },[isEdit])
  useEffect(()=>{
    if(isEdit){
      dispatch({
        type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
        payload: {
          Scheme:SchemeFromAPI.map((scheme)=>{return {Name:scheme.SchemeName}}),
          SchemeNameList:SchemeFromAPI.map((scheme)=> {return scheme.SchemeName})
        }
      });
    }
  },[SchemeFromAPI, isEdit])
  function importButton(heading: string, type?: 'replace') {
    return (
      <Button
        sx={{
          font: (theme) => `normal ${theme.typography.fontWeightRegular} ${theme.spacing(1.5)} Roboto Sans, sans-serif`,
          textTransform: 'inherit'
        }}
        component="label"
        startIcon={
          <img src={type === 'replace' ? LOCAL_IMAGES.REPLACE_IMPORT : LOCAL_IMAGES.ADD_IMPORT} alt="add import" />
        }>
        {heading}
        <input
          onChange={(e)=>{handleImportChange(e, type)}}
          type="file"
          hidden
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </Button>
    );
  }
  const handleImportChange = async (event: React.ChangeEvent<HTMLInputElement>, type?: 'replace') => {
    if (!event.target.files?.length || !FormularyId) return;
    const fileObj = event.target.files[0];
    // eslint-disable-next-line no-console
    console.log(event.target.files[0].name)
    if(isEdit){
      dispatch({
        type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
        payload: {
          ImportFile:event.target.files[0],
          Type:type === 'replace' ? 'replace' : 'Add',
          Scheme:SchemeFromAPI.map((scheme)=>{return {Name:scheme.SchemeName}})
          }
      });
          }
    else{
      dispatch({
        type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
        payload: {
          ImportFile:event.target.files[0],
          Scheme:[],
          SchemeNameList:[]
        }
      });
    }
    handleCloseImport()
    const data = new FormData();
    data.append('FormularyId', `${FormularyId}`);
    data.append('IsReplaceExisting', `${type === 'replace' ? true : false}`);
    data.append('File', fileObj);
    // try {
    //   dispatch(globalLoaderStart());
    //   const res = await importSchemeFromExcelApi(data, 'scheme');
    //   dispatch(
    //     getAssignFormularytoSchemeList({
    //       page: Page,
    //       limit: ItemOffset,
    //       })
    //   );
    //   showAlert(
    //     1,
    //     <>
    //       <Typography variant="h1">Import is progress</Typography>
    //       <Typography variant="subtitle2">
    //         Total records found in the file: {res.data?.Data?.TotalRecords} Total records processed:{' '}
    //         {res.data?.Data?.RecordProcessed}
    //       </Typography>
    //     </>
    //   );
    //   handleCloseImport();
    //   dispatch(globalLoaderEnd());
    // } catch (_err) {
    //   handleCloseImport();
    //   dispatch(globalLoaderEnd());
    //   showAlert(2);
    // }
  };
  const handleDeleteImportFile=()=>{
    dispatch({
      type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
      payload: {
        ImportFile:null
      }
    });
  }
  const handleChangeIndex = (index: TIndex) => {
    setValue(index);
  };
  const schemeFilter = (scheme: Scheme, keyword: string) => {
    return scheme.filter(
      (item) =>
        item.Name.toLowerCase().includes(keyword.toLowerCase().trim())
    );
  };
  useEffect(() => {
    if (schemeInputValue) {
      const filterScheme10Arr = schemeFilter(schemeToShow, schemeInputValue);
      setSchemeToShow(filterScheme10Arr);
    } else{
      setSchemeToShow(Scheme);
    } 
  }, [schemeToShow.length, schemeInputValue, Scheme]);
  
  const handleSearchChangeScheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setSchemeInputValue(keyword);
    const filterSchemeArr = schemeFilter(schemeToShow, keyword);
    startTransition(() => setSchemeToShow(filterSchemeArr));
  };

  const handleSearchSubmitScheme = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setSchemeInputValue(keyword);
    const filterSchemeArr = schemeFilter(schemeToShow, keyword);
    startTransition(() => setSchemeToShow(filterSchemeArr));
  };
  return (
    <Dialog
      open={open}
      onClose={()=>{handleClose();handleChangeIndex(0)}}
      // sx={{
      //   width: { xs: 300, sm: 650 },
      //   boxShadow: 'var(--link-box-shadow)'
      // }}
      fullWidth maxWidth="md"
      scroll={'paper'}>
        {value === 0 && <>
        <Box sx={{ bgcolor: 'primary.main', p: 1.5, textAlign: 'center' }}>
            <Typography variant="h1" sx={{ color: 'common.white' }}>
                Assign Formulary to Scheme(s)
            </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Box sx={{ mb: 2 }}>
              <AutocomAssignFormularySearch name="formulary" label="Select Formulary" disable={isEdit}/>
            </Box>
            <AutocomScheme label="Select Schemes" disable={ImportFile?true:false} />
            {Scheme?.length > 0 && (
              <Box>
                {Scheme.map((item, index) => {
                  if (index > VIEW_NUMBER) return null;
                  return (
                    <ChipCus
                      sx={{ mt: 0.8, mr: 0.5 }}
                      key={item.Name}
                      label={item.Name}
                      color="primary"
                      onDelete={ImportFile && isEdit ?undefined: () => handleDeleteScheme(item)}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
          {Scheme.length> VIEW_NUMBER && <ButtonWrapper
            onClick={() => handleChangeIndex(1)}
            endIcon={<ArrowForwardIosIcon />}
            variant="contained"
            color="primary"
            disabled={!!ImportFile}>
            View All
          </ButtonWrapper>}
          <Typography variant="h1" sx={{textAlign:"center", fontWeight:"bold", mb: 2}}>OR</Typography>
          {isEdit && <Box ref={importRef}>
            <ButtonWrapper
              onClick={handleOpenImport}
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none', mr: 1, width:"100%" }}>
              Import Scheme
            </ButtonWrapper>
          </Box>}
          {!isEdit && <Box>
            <Button component={FormularyId?"label":"text"} sx={{color: (theme) => theme.palette.common.white, 
              borderRadius: (theme)=>theme.spacing(0.6), 
              font:(theme)=> `normal  ${theme.spacing(2)} Roboto Sans, sans-serif`, 
              bgcolor:(theme)=> theme.palette.primary.main,
              padding: (theme)=>theme.spacing(0.8, 3),
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: (theme)=> theme.palette.primary.main,
              },
              width:"100%"
              }}
              onClick={()=>{if(!FormularyId)showAlert(2,"Please select formulary")}}>
              Import Scheme
              <input
              onChange={handleImportChange}
              type="file"
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            </Button>
          </Box>}
          {ImportFile && 
          <ChipCus
            sx={{ mt: 0.8, mr: 0.5}}
            key={ImportFile.name}
            label={ImportFile.name}
            color="primary"
            onDelete={handleDeleteImportFile}
          />}
        </Box>
        <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 2,
            py:"10px"
          }}>
        <ButtonWrapper
            variant="outlined"
            sx={{
            color: (theme) => theme.palette.error.light,
            px: { xs: 2, sm: 5 },
            mr: 1,
            py: 1,
            borderColor: (theme) => theme.palette.error.light,
            '&:hover': {
                borderColor: (theme) => theme.palette.error.light
            }
            }}
            onClick={handleClose}>
            Cancel
        </ButtonWrapper>
        <ButtonWrapper
            variant="contained"
            color="primary"
            sx={{ px: { xs: 2, sm: 5 }, ml: 1, py: 1 }}
            onClick={handleAddScheme}>
            {'Assign'}
        </ButtonWrapper>
        </Box></>}
        {value === 1 &&  (
          <>
            <SchemeViewAll
              type="scheme"
              index={value}
              heading="All Schemes"
              handleChangeIndex={handleChangeIndex}
              bradcrumb={{
                link: 'Medicines'
              }}
              placeholder="Search Scheme"
              inputValue={schemeInputValue}
              handleSearchChange={handleSearchChangeScheme}
              handleSearchSubmit={handleSearchSubmitScheme}
              handleDelete={handleDeleteScheme}
              schemes={schemeToShow}
            />
          </>
        )}
        <ImportDialog
        open={openImport}
        handleClose={handleCloseImport}
        filterButtonPosition={importRef.current?.getBoundingClientRect()}>
        <Box sx={{ mb: 0.5 }}>{importButton('Replace existing list', 'replace')}</Box>
        <Divider />
        <Box sx={{ mt: 0.5 }}>{importButton('Add/Update to existing list')}</Box>
      </ImportDialog>
              
        
    </Dialog>
  )
}

export default AssignFormularySchemeDialog