import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { ReducersModal } from '../../../modal';
import { Answer } from '../../../types/faq.types';
import { FAQ_BREADCRUM_META_DATA, TEXT_TYPE, VIDEO_TYPE } from '../../../utils/static.data';
import { questionDeatil } from '../action';
import AnswerCard from './answer.card';
import QuestionCard from './question.card';

function FaqDetail() {
  const { faqId } = useParams();
  const dispatch = useDispatch();
  const { Answers, Text } = useSelector((state: ReducersModal) => state.questionDetailReducer);

  useEffect(() => {
    if (faqId) {
      dispatch(questionDeatil(faqId));
    }
  }, [faqId, dispatch]);

  return (
    <Box>
      <Breadcrumb metaData={[...FAQ_BREADCRUM_META_DATA, { name: Text ? TEXT_TYPE : VIDEO_TYPE }]} />
      <Box sx={{ my: 2 }}>
        <QuestionCard />
      </Box>
      <Box>
        {Answers?.length > 0 &&
          Answers.map((item: Answer) => {
            return <AnswerCard item={item} key={item.AnswerId} />;
          })}
      </Box>
    </Box>
  );
}

export default FaqDetail;
