import { Box} from '@mui/material';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { schemeDetail } from '../action';
import IcdCode from './icd.code';
// import MedicineCard from './medicine.card';
// import Scheme from './scheme';
import SchemeBlock from './scheme.block';

function SchemDetail() {
  const { schemeId } = useParams<{ schemeId: string }>();
  const { state } = useLocation();
  const dispatch = useDispatch();
  // const schemeRef = useRef<HTMLDivElement>(null);
  const icdRef = useRef<HTMLDivElement>(null);
  // const medicineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (schemeId) dispatch(schemeDetail(schemeId));
    }, [schemeId]);

  useEffect(() => {
    // if (state === 'scheme') schemeRef.current?.scrollIntoView();
    if (state === 'icd') icdRef.current?.scrollIntoView();
    // else if (state === 'medicine') medicineRef.current?.scrollIntoView();
  }, [state]);

  return (
    <Box>
      {/* <Typography variant="h1">Scheme Specific Formulary Detail</Typography> */}
      <SchemeBlock />
      {/* <Box ref={schemeRef}>
        <Scheme />
      </Box> */}
      <Box ref={icdRef}>
        <IcdCode />
      </Box>
      {/* <Box ref={medicineRef}>
        <MedicineCard />
      </Box> */}
    </Box>
  );
}

export default SchemDetail;
