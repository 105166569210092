import { Box } from '@mui/system';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import Profile from '../../../components/profile/profile';
import ReportDetailTable from '../../../components/report-detail-table/report.detail.table';
import { DOCTORS_RECOMMENDATION_BREADCRUM_META_DATA } from '../../../utils/static.data';
import DetailCard from './detail.card';
import { useParams } from 'react-router-dom';
import { recommendationDeatil, recommendationReportList, recommendationReportListAction } from '../action';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModal } from '../../../modal';
import { calculatePage, calculateRowPerPage } from '../../../utils/common.function';
import { SelectChangeEvent } from '@mui/material';

function DoctorRecomDetail() {
  const { recommendationId } = useParams();
  const dispatch = useDispatch();
  const { DoctorImage, DoctorName, DoctorSpecializations, DoctorRating, DoctorPhoneCode, DoctorPhoneNumber } =
    useSelector((state: ReducersModal) => state.recommendationDeatilReducer);
  const { Page, ItemOffset, Reports, TotalRecords } = useSelector(
    (state: ReducersModal) => state.recommendationReportListReducer
  );
  const [pageCount, setPageCount] = useState(Page);

  useEffect(() => {
    if (recommendationId) dispatch(recommendationDeatil(recommendationId));
  }, [recommendationId, dispatch]);

  useEffect(() => {
    if (recommendationId) {
      dispatch(recommendationReportList(Page, ItemOffset, recommendationId));
    }
  }, [dispatch, recommendationId, Page, ItemOffset]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(recommendationReportListAction({ Page: selectedPage + 1 }));
  };

  const handlePrevious = () => {
    dispatch(recommendationReportListAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(recommendationReportListAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };

  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    dispatch(recommendationReportListAction({ Page: 1, ItemOffset: Number(itemOffset) }));
  };

  return (
    <Box>
      <Breadcrumb metaData={DOCTORS_RECOMMENDATION_BREADCRUM_META_DATA} />
      <Box sx={{ my: 2 }}>
        <Profile
          img={DoctorImage}
          name={DoctorName}
          specialist={DoctorSpecializations}
          rating={DoctorRating}
          phoneNumber={`+${DoctorPhoneCode ? DoctorPhoneCode : ''}${DoctorPhoneNumber ? DoctorPhoneNumber : ''}`}
        />
      </Box>
      <Box>
        <DetailCard />
      </Box>
      <Box my={3}>
        <ReportDetailTable
          title="Report Detail"
          data={Reports}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          page={calculatePage(Page, ItemOffset)}
          totalRecord={TotalRecords}
          rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          activePage={Page}
          handleChangeShowSort={handleChangeShowSort}
          itemOffset={ItemOffset}
          type="recommendationReport"
        />
      </Box>
    </Box>
  );
}

export default DoctorRecomDetail;
