import { Box, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import ConfirmButton from "../../../components/button/confirm.button";
import CopyButton from "../../../components/button/copy.button";
import DeleteButton from "../../../components/button/delete.button";
import EditButton from "../../../components/button/edit.button";
import PaginateWrapper from "../../../components/paginate-wrapper/paginate.wrapper";
import TableCellWrapper from "../../../components/table/table.cell.wrapper";
import TableRowWrapper from "../../../components/table/table.row.wrapper";
import useGlobalStyle from "../../../hooks/use.global.style";
import { ReducersModal } from "../../../modal";
import { TableSort, TSort } from "../../../types/global.types";
import { Formulary } from "../../../types/manage.scheme.specific.formulary";
import ACTION_NAME from "../../../utils/action.name";
import { formatDateAndTime } from "../../../utils/common.function";
import LOCAL_IMAGES from "../../../utils/local.images";
import { manageAssignFormularytoSchemeListAction } from "../action";

type AssignSchemeTableProps = {
  data: Formulary[];
  handleOpenInactive: (item: Formulary) => void;
  pageCount: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  rowPerPage: number;
  totalRecord: number;
  page: number;
  handlePrevious?: () => void;
  handleOpenEditBlock: () => void;
  handleCloseEditBlock: () => void;
  handleNext?: () => void;
  activePage?: number;
  hanldeOpenAllSchemeView: ()=> void;
  setSelectedFormulary: (id:number) => void;
  handleOpenCopyBlock: ()=> void;
  
};
function AssignFormularyTable({
  data,
  handleOpenInactive,
  pageCount,
  handlePageClick,
  rowPerPage,
  totalRecord,
  page,
  handlePrevious,
  handleNext,
  activePage,
  handleOpenEditBlock,
  hanldeOpenAllSchemeView,
  setSelectedFormulary,
  handleOpenCopyBlock}:AssignSchemeTableProps) {
    const { SortOrder } = useSelector((state: ReducersModal) => state.manageAssignFormularytoSchemeReducer);
    const dispatch = useDispatch();
    const globalClasses = useGlobalStyle();
    const handleAscAndDesc = (SortBy: TSort) => {
      dispatch(
        manageAssignFormularytoSchemeListAction({
          ...(SortOrder === 'ASC' ? { SortOrder: 'DESC' } : { SortOrder: 'ASC' }),
          ...{ SortBy: SortBy }
        })
      );
    };
  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          py: 1.5,
          borderRadius: (theme) => theme.spacing(0.8)
        }}>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRowWrapper>
                <TableCellWrapper>S.No.</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Formulary Name
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.BlockName);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Insurance Company</TableCellWrapper>
                <TableCellWrapper>ICD10 Code(s) Block</TableCellWrapper>
                <TableCellWrapper>No. of Scheme(s)</TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Created At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.CreatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Typography
                      sx={{
                        font: 'inherit'
                      }}>
                      Updated At
                    </Typography>
                    <Box
                      sx={{ ml: 0.5, cursor: 'pointer' }}
                      onClick={() => {
                        handleAscAndDesc(TableSort.UpdatedAt);
                      }}>
                      <img src={LOCAL_IMAGES.UP_DOWN_FILTER} alt="" />
                    </Box>
                  </Box>
                </TableCellWrapper>
                <TableCellWrapper>Status</TableCellWrapper>
                <TableCellWrapper>Action</TableCellWrapper>
              </TableRowWrapper>
            </TableHead>
            <TableBody>
              {data?.length > 0 ? (
                data.map((item: Formulary, index: number) => {
                  return (
                    <TableRowWrapper
                      sx={{ cursor: 'pointer' }}
                      hover
                      key={item?.FormularyId}
                      onClick={() => {return false}}>
                      <TableCellWrapper>{index + page}</TableCellWrapper>
                      <TableCellWrapper>{item.Name || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>{item.InsuranceCompanyName || 'N/A'}</TableCellWrapper>
                      <TableCellWrapper>
                        {item.BlockCount ? (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              
                            }}
                            className={globalClasses.span}>
                            {item.BlockCount}
                          </span>
                        ) : (
                          item.BlockCount
                        )}
                      </TableCellWrapper>
                      <TableCellWrapper><span className={globalClasses.span} onClick={()=>{setSelectedFormulary(item.FormularyId);hanldeOpenAllSchemeView()}}>{item?.SchemeCount}</span></TableCellWrapper>
                      <TableCellWrapper>
                        <>{formatDateAndTime(item.CreatedAt) || 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>
                        <>{item.ModifiedAt !== null ? formatDateAndTime(item.ModifiedAt) : 'N/A'}</>
                      </TableCellWrapper>
                      <TableCellWrapper>{item.IsActive === 1 ? 'Active' : 'In Active'}</TableCellWrapper>
                      <TableCellWrapper>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CopyButton
                            title='Copy'
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch({
                                type:ACTION_NAME.COPY_SCHEME_SPECIFIC_FORMULARY,
                                payload:{
                                  FromNewFormulary: false,
                                  SourceFormulary:item?.FormularyId,
                                  FormularyToRemove:[item?.FormularyId],
                                  FromAssignScheme:true

                                }
                              })
                              handleOpenCopyBlock();
                              }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>
                          <EditButton
                            title='Edit'
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch({
                                type: ACTION_NAME.ASSIGN_FORMULARY_TO_SCHEME,
                                payload: {
                                  isEdit:true,
                                  FormularyId:item?.FormularyId,
                                  InsuranceCompanyId:item?.InsuranceCompanyId
                                }
                              });
                              handleOpenEditBlock()
                              
                            }}
                          />
                          <Box
                            sx={{
                              width: (theme) => theme.spacing(0.1),
                              backgroundColor: 'var(--table-delete-edit-middle-line)',
                              alignSelf: 'stretch',
                              mx: 0.5
                            }}></Box>

                          {item.IsActive === 1 && (
                            <DeleteButton
                              title='Suspend'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                          {item.IsActive === 0 && (
                            <ConfirmButton
                              title='Activate'
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenInactive(item);
                              }}
                            />
                          )}
                        </Box>
                      </TableCellWrapper>
                    </TableRowWrapper>
                  );
                })
              ) : (
                <TableRowWrapper>
                  <TableCellWrapper colSpan={9}>No data found</TableCellWrapper>
                </TableRowWrapper>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data?.length > 0 && (
          <PaginateWrapper
            activePage={activePage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            page={page}
            rowpPerPage={rowPerPage}
            totalRecord={totalRecord}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        )}
      </Box>
    </>
  )
}

export default AssignFormularyTable