import { Box, FormLabel, MenuItem, TextField, Typography } from '@mui/material';
import ButtonWrapper from '../button/button.wrapper';
import ModalWrapper from './modal.wrapper';
import DatePic from '../date-pic/date.pic';
import { Form, Formik, FormikHelpers } from 'formik';
import FORM_FIELD from '../../validations/form-field';
import SCHEMA from '../../validations/schema';
import { useEffect, useState, useId } from 'react';
import { getCategory } from '../../global-action/global.action';
import { CategoriesType } from '../../types/manage.category.types';
import SelectWithChild from '../form-ui/select-wrapper/select.with.child';
import { getCurrentDate } from '../../utils/common.function';

type FilterModalProps = {
  open: boolean;
  handleClose: () => void;
  handleFilterSubmit: (
    values: typeof FORM_FIELD.filterListing,
    formikHelpers: FormikHelpers<typeof FORM_FIELD.filterListing>
  ) => void;
  initialValues: typeof FORM_FIELD.filterListing;
  handleFilterReset: () => void;
  filterButtonPosition?: DOMRect;
};

function FilterModal({
  open,
  handleClose,
  handleFilterSubmit,
  initialValues,
  handleFilterReset,
  filterButtonPosition
}: FilterModalProps) {
  const keyId = useId();
  const [category, setCategory] = useState([] as CategoriesType[]);
  const getCategoryList = async () => {
    const response = await getCategory();
    setCategory(response?.data?.Data?.Categories);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: { xs: 300, sm: 400 },
        top: {
          xs: filterButtonPosition?.y ? filterButtonPosition.y + 240 : 300,
          sm: filterButtonPosition?.y ? filterButtonPosition.y + 220 : 300
        },
        left: {
          xs: filterButtonPosition?.x ? filterButtonPosition.x - 100 : '81%',
          sm: filterButtonPosition?.x ? filterButtonPosition.x - 165 : '81%'
        },
        position: 'relative',
        py: 3,
        px: 2
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '-28px',
          right: '10px',
          width: 0,
          height: 0,
          borderLeft: (theme) => `${theme.spacing(1.2)} solid transparent`,
          borderRight: (theme) => `${theme.spacing(1.2)} solid transparent`,
          borderBottom: (theme) => `${theme.spacing(3)} solid var(--white)`
        }}></Box>
      <Box>
        <Formik
          validationSchema={SCHEMA.filterModal}
          initialValues={initialValues}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterReset}
          enableReinitialize>
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ my: 2 }}>
                  <Typography variant="h3" color="primary">
                    Added on
                  </Typography>
                  <Box sx={{ my: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ flexBasis: '47%' }}>
                      <DatePic
                        label="From"
                        value={values.fromDate}
                        maxDate={values.toDate || getCurrentDate()}
                        onChange={(newValue) => {
                          setFieldValue('fromDate', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              '& label': {
                                color: (theme) => theme.palette.primary.main
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: (theme) => theme.palette.primary.main
                                }
                              }
                            }}
                            {...params}
                            size="small"
                          />
                        )}
                      />
                      <FormLabel sx={{ color: (theme) => theme.palette.error.light }}>
                        {errors?.fromDate && errors.fromDate}
                      </FormLabel>
                    </Box>
                    <Box sx={{ flexBasis: '47%' }}>
                      <DatePic
                        label="To"
                        value={values.toDate}
                        minDate={values.fromDate}
                        maxDate={getCurrentDate()}
                        onChange={(newValue) => {
                          setFieldValue('toDate', newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              '& label': {
                                color: (theme) => theme.palette.primary.main
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: (theme) => theme.palette.primary.main
                                }
                              }
                            }}
                            {...params}
                            size="small"
                          />
                        )}
                      />
                      <FormLabel sx={{ color: (theme) => theme.palette.error.light }}>
                        {errors?.toDate && errors.toDate}
                      </FormLabel>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ my: 3 }}>
                  <Typography variant="h3" color="primary">
                    Category
                  </Typography>
                  <Box
                    sx={{
                      my: 1,
                      '& .MuiFormControl-root': {
                        width: '100%'
                      }
                    }}>
                    <SelectWithChild
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="category"
                      value={values.category}>
                      <MenuItem value={0} key={keyId}>
                        All
                      </MenuItem>
                      {category?.length > 0 &&
                        category.map((item, index: number) => {
                          return (
                            <MenuItem value={item.CategoryId} key={index}>
                              {item.Name}
                            </MenuItem>
                          );
                        })}
                    </SelectWithChild>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap-reverse',
                    gap: { xs: 1 }
                  }}>
                  <ButtonWrapper
                    disabled={isSubmitting}
                    type="reset"
                    variant="contained"
                    sx={{
                      color: 'var(--white)',
                      fontSize: (theme) => theme.spacing(1.4),
                      p: (theme) => theme.spacing(1.5, 2.5),
                      ml: { xs: 0, sm: 1 }
                    }}>
                    Reset
                  </ButtonWrapper>
                  <Box>
                    <ButtonWrapper
                      color="error"
                      variant="outlined"
                      sx={{
                        fontSize: (theme) => theme.spacing(1.4),
                        p: (theme) => theme.spacing(1.5, 2.5),
                        color: (theme) => theme.palette.error.main
                      }}
                      onClick={handleClose}>
                      Cancel
                    </ButtonWrapper>
                    <ButtonWrapper
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      sx={{
                        color: 'var(--white)',
                        fontSize: (theme) => theme.spacing(1.4),
                        p: (theme) => theme.spacing(1.5, 2.5),
                        ml: 1
                      }}>
                      Apply
                    </ButtonWrapper>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ModalWrapper>
  );
}

export default FilterModal;
