import { ManageCategoryModal } from '../../modal';
import { ActionType } from '../../types/global.types';
import ACTION_NAME from '../../utils/action.name';

export const manageCategoryReducer = (state: ManageCategoryModal = new ManageCategoryModal(), action: ActionType) => {
  switch (action.type) {
    case ACTION_NAME.GET_MANAGE_CATEGORY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
