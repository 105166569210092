import { Box, Typography } from '@mui/material';
import PointForm from './point.form';

function ManagePoint() {
  return (
    <Box>
      <Typography variant="h1">Manage Livia Points</Typography>
      <Box sx={{ my: 3 }}>
        <Typography variant="h1">How Livia Point System Works</Typography>
        <Typography variant="h3" color="primary" sx={{ mt: 1 }}>
          Livia point system helps doctors to earn points for their posted content.
        </Typography>
        <Typography variant="h3" color="primary" sx={{ mt: 1 }}>
          These credits and debits of points to the Md users can be managed using this section for each specific action
          on which the points are credited/debited.
        </Typography>
      </Box>
      <Box sx={{ bgcolor: (theme) => theme.palette.secondary.main, p: 1 }}>
        <Typography variant="h1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
          Points
        </Typography>
      </Box>
      <PointForm />
    </Box>
  );
}

export default ManagePoint;
