import { decodeJwtToken } from './common.function';
import CONSTANTS from './constants';

/**
 * set access token in axios instance header
 */
const setAuthorizationToken = (token: string) => {
  if (token) {
    CONSTANTS.AXIOS.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    CONSTANTS.AXIOS.defaults.headers.common['Authorization'] = '';
    delete CONSTANTS.AXIOS.defaults.headers.common['Authorization'];
  }
};

const removeSession = () => {
  localStorage.clear();
  setAuthorizationToken('');
};

const getAccessToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};

const setAccessToken = (accessToken: string) => {
  if (accessToken) {
    setAuthorizationToken(accessToken);
  }
  return accessToken;
};

const removeLocalStorageKeyData = (key: string) => {
  localStorage.removeItem(key);
};

const getLocalStorageKeyData = (key: string) => {
  if (!key) return;
  return JSON.parse(localStorage.getItem(key) || '');
};

const setLocalStorageKeyData = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const checkAccessAndRefresh = (token: string) => {
  const splitTokens = token.split('?AccessToken=')?.[1];
  const accessToken = splitTokens.split('&RefreshToken=')?.[0];
  const splitRefresh = splitTokens.split('&RefreshToken=')?.[1];
  const refreshToken = splitRefresh.split('&Email=')?.[0];
  const email = splitTokens.split('&Email=')?.[1];

  const decode = decodeJwtToken(accessToken);

  if (accessToken && refreshToken && email && decode?.userRoleId) {
    setLocalStorageKeyData('accessToken', accessToken);
    setLocalStorageKeyData('refreshToken', refreshToken);
    setLocalStorageKeyData('userEmail', email);
    setLocalStorageKeyData('userRoleId', decode.userRoleId);
    setAuthorizationToken(accessToken);
    return true;
  }
  return false;
};

const SESSION_MANAGEMENT = {
  setAuthorizationToken,
  removeSession,
  getAccessToken,
  setAccessToken,
  removeLocalStorageKeyData,
  getLocalStorageKeyData,
  setLocalStorageKeyData
};

export default SESSION_MANAGEMENT;
