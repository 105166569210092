import { Login, Logout } from '../../types/login';
import CONSTANTS from '../../utils/constants';
import END_POINTS from '../../utils/end.points';

export const login = (payload: Login) => {
  return CONSTANTS.AUTHENTICATION_AXIOS.post(END_POINTS.LOGIN, payload);
};

export const logout = (payload: Logout) => {
  return CONSTANTS.LOGOUT_AXIOS.post(END_POINTS.LOGOUT, payload);
};
