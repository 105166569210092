const ROUTES_NAME = {
  Login: '/',
  FAQ: '/faqs',
  FAQ_DETAIL: '/faqs/faqs-detail',
  DOCTORS_VLOG: '/doctors-vlog',
  DOCTORS_VLOG_DETAIL: '/doctors-vlog/doctor-vlog-detail',
  DOCTORS_RECOMMENDATION: '/doctors-recommendation',
  DOCTORS_RECOMMENDATION_DETAIL: '/doctors-recommendation/doctors-recommendation-detail',
  MANAGE_CATEGORY: '/manage-category',
  NOTIFICATION: '/notification',
  MANAGE_POINT: '/manage-point',
  MANAGE_ICD10_DIAGNOSIS: '/manage-icd10-diagnosis',
  MANAGE_ICD10_DIAGNOSIS_DETAIL: '/manage-icd10-diagnosis/manage-icd10-diagnosis-detail',
  MANAGE_SCHEME_SPECIFIC_FORMULARY: '/manage-scheme-specific-formulary',
  MANAGE_SCHEME_SPECIFIC_FORMULARY_DETAIL: '/manage-scheme-specific-formulary/detail',
  SCHEME_SPECIFIC_FORMULARY_DETAIL_MEDICINE:'medicine-detail',
  ASSIGN_SPECIFIC_TO_SCHEME:'/assign-specific-to-scheme',
};

export default ROUTES_NAME;
