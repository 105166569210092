import { Box, SelectChangeEvent, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import ButtonWrapper from '../../components/button/button.wrapper';
import FilterButton from '../../components/button/filter.button';
import SelectWrapper from '../../components/form-ui/select-wrapper/select.wrapper';
import FilterModal from '../../components/modal-wrapper/filter.modal';
import InactiveModal from '../../components/modal-wrapper/inactive.modal';
import SearchTextfield from '../../components/search-textfield/search.textfield';
import useDebounce from '../../hooks/use.debounce';
import { ReducersModal } from '../../modal';
import { RecommendationItem } from '../../types/doctor.recommendation.types';
import { CsvDoctorVlogData } from '../../types/doctor.volg.types';
import {
  calculatePage,
  calculateRowPerPage,
  formatDateAndTimeForfilter,
  getSearchPlaceHolderVlog,
  checkFilterApplyOrNot,
  convertVlogCsvData
} from '../../utils/common.function';
import { DOCTOR_VLOG_CSV_HEADER, VLOG_FILTER_BY_SELECT_MENU_ITEM, SELECT_MENU_ITEM } from '../../utils/static.data';
import FORM_FIELD from '../../validations/form-field';
import { csvVlogList, getVlogList, getVlogListAction, updateStatusVlog } from './action';
import DoctorVlogTable from './doctor.vlog.table';

const DoctorVlog = () => {
  const dispatch = useDispatch();
  const filterRef = useRef<HTMLElement>(null);
  const exportCsvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [openInactive, setOpenInactive] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [updateStatusRecom, setUpdateStatusRecom] = useState({} as RecommendationItem);
  const [csvVlogData, setCsvVlogData] = useState({
    isExportClick: false,
    isLoading: false,
    data: [] as CsvDoctorVlogData[]
  });
  const debouncedValue = useDebounce(inputValue.trim(), 1000);

  const {
    Recommendations,
    Page,
    TotalRecords,
    ItemOffset,
    FilterBy,
    SortBy,
    SortOrder,
    CategoryId,
    FromDate,
    ToDate,
    initialValueSortDate
  } = useSelector((state: ReducersModal) => state.doctorVlogReducer);

  const [pageCount, setPageCount] = useState(Page);

  useEffect(() => {
    dispatch(
      getVlogList({
        page: Page,
        limit: ItemOffset,
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(FilterBy && debouncedValue !== '' && { filterBy: FilterBy, filterValue: debouncedValue }),
        ...(CategoryId && { categoryId: CategoryId }),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      })
    );
  }, [dispatch, Page, ItemOffset, FilterBy, debouncedValue, SortBy, SortOrder, CategoryId, FromDate, ToDate]);

  useEffect(() => {
    setPageCount(Math.ceil(TotalRecords / ItemOffset));
  }, [ItemOffset, TotalRecords]);

  useEffect(() => {
    if (csvVlogData.data.length > 0 && csvVlogData.isExportClick) {
      exportCsvRef?.current?.link.click();
      setCsvVlogData({
        ...csvVlogData,
        isExportClick: false
      });
    }
  }, [csvVlogData.data, csvVlogData.isExportClick]);

  const handleExportCsv = async () => {
    setCsvVlogData({
      ...csvVlogData,
      isLoading: true
    });
    try {
      const response = await csvVlogList({
        page: Page,
        limit: TotalRecords,
        ...(SortBy && SortOrder && { sortBy: SortBy, sortOrder: SortOrder }),
        ...(FilterBy && debouncedValue !== '' && { filterBy: FilterBy, filterValue: debouncedValue }),
        ...(CategoryId && { categoryId: CategoryId }),
        ...(FormData && ToDate && { fromDate: FromDate, toDate: ToDate })
      });
      const convertCsvData = convertVlogCsvData(response.data.Data.Recommendations);
      setCsvVlogData({
        isExportClick: true,
        isLoading: false,
        data: convertCsvData
      });
    } catch (_error) {
      setCsvVlogData({
        ...csvVlogData,
        isLoading: false
      });
    }
  };

  const handlePageClick = (event: { selected: number }) => {
    const selectedPage = event.selected;
    dispatch(getVlogListAction({ Page: selectedPage + 1 }));
  };

  const handleChangeShowSort = (event: SelectChangeEvent<unknown>) => {
    const itemOffset = event.target.value;
    dispatch(getVlogListAction({ Page: 1, ItemOffset: Number(itemOffset) }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };

  const handleChangeFilter = (event: SelectChangeEvent<unknown>) => {
    const filterBy = event.target.value;
    if (filterBy === '1' || filterBy === '2' || filterBy === '3')
      dispatch(getVlogListAction({ Page: 1, FilterBy: filterBy }));
  };

  const handleCloseFilter = useCallback(() => {
    setOpenFilter(false);
  }, []);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseInactive = useCallback(() => {
    setOpenInactive(false);
  }, []);

  const handleOpenInactive = (item: RecommendationItem) => {
    setOpenInactive(true);
    setUpdateStatusRecom(item);
  };

  const handleConfirmInactive = useCallback(() => {
    const payload = {
      RecommendationId: updateStatusRecom.RecommendationId,
      IsActive: updateStatusRecom.IsActive === 0 ? true : false
    };
    dispatch(updateStatusVlog(payload, handleCloseInactive, 'list'));
  }, [updateStatusRecom]);

  const handleFilterSubmit = (
    { fromDate, toDate, category }: typeof FORM_FIELD.filterListing,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.filterListing>
  ) => {
    if (fromDate && toDate) {
      dispatch(
        getVlogListAction({
          FromDate: formatDateAndTimeForfilter(fromDate),
          ToDate: formatDateAndTimeForfilter(toDate),
          initialValueSortDate: {
            fromDate,
            toDate,
            category
          }
        })
      );
    }
    dispatch(
      getVlogListAction({
        CategoryId: category,
        initialValueSortDate: {
          fromDate,
          toDate,
          category
        }
      })
    );
    handleCloseFilter();
    setSubmitting(false);
  };

  const handlePrevious = () => {
    dispatch(getVlogListAction({ Page: 1, ItemOffset: ItemOffset }));
  };
  const handleNext = () => {
    dispatch(getVlogListAction({ Page: pageCount, ItemOffset: ItemOffset }));
  };

  const handleFilterReset = () => {
    dispatch(
      getVlogListAction({
        CategoryId: 0,
        FromDate: null,
        ToDate: null,
        initialValueSortDate: {
          fromDate: null,
          toDate: null,
          category: 0
        }
      })
    );
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography variant="h1">Doctor’s Video</Typography>
          <Box ref={filterRef}>
            <FilterButton onClick={handleOpenFilter} isFilterApply={checkFilterApplyOrNot(initialValueSortDate)} />
          </Box>
        </Box>
        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: { xs: 'initial', sm: 'center' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1 }
          }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'unset' } }}>
              <SearchTextfield
                placeholder={getSearchPlaceHolderVlog(FilterBy)}
                inputValue={inputValue}
                handleSearchChange={handleSearchChange}
                handleSearchSubmit={handleSearchSubmit}
                name="searchInput"
              />
              <SelectWrapper
                sx={{ ml: 1 }}
                value={FilterBy}
                onChange={handleChangeFilter}
                menuItem={VLOG_FILTER_BY_SELECT_MENU_ITEM}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <SelectWrapper
              sx={{ mr: 2 }}
              value={ItemOffset}
              onChange={handleChangeShowSort}
              menuItem={SELECT_MENU_ITEM}
            />
            <ButtonWrapper
              variant="contained"
              color="primary"
              onClick={handleExportCsv}
              disabled={csvVlogData.isLoading}>
              {csvVlogData.isLoading ? 'Loading...' : 'Export'}
            </ButtonWrapper>
            <CSVLink
              data={csvVlogData.data}
              ref={exportCsvRef}
              filename={'doctor-vlog.csv'}
              headers={DOCTOR_VLOG_CSV_HEADER}></CSVLink>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <DoctorVlogTable
            data={Recommendations}
            handleOpenInactive={handleOpenInactive}
            pageCount={pageCount}
            handlePageClick={handlePageClick}
            page={calculatePage(Page, ItemOffset)}
            totalRecord={TotalRecords}
            rowPerPage={calculateRowPerPage(Page, ItemOffset, TotalRecords)}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            activePage={Page}
          />
        </Box>
      </Box>
      <FilterModal
        open={openFilter}
        handleClose={handleCloseFilter}
        handleFilterSubmit={handleFilterSubmit}
        initialValues={initialValueSortDate}
        handleFilterReset={handleFilterReset}
        filterButtonPosition={filterRef.current?.getBoundingClientRect()}
      />
      <InactiveModal
        open={openInactive}
        handleClose={handleCloseInactive}
        heading={updateStatusRecom.IsActive === 0 ? 'Active?' : 'Inactive?'}
        paragraph={
          <>
            Are you sure you want to {updateStatusRecom.IsActive === 0 ? 'activate' : 'inactivate'}
            <br /> the selected Video?
          </>
        }
        handleConfirm={handleConfirmInactive}
      />
    </>
  );
};

export default DoctorVlog;
